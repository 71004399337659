<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" borderless header-only>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item" v-rq-tooltip.hover.top="toolTip">
                        <b-button
                            :automation_id="elementName('btn_add')"
                            :ref="elementName('btn_add')"
                            variant="theme"
                            @click="onAddItem"
                            :disabled="!localSecurity.FileScanAllowCategoryManagement"
                            v-rq-tooltip.hover.top="{ title: `Add ${this.itemTypeName}` }"
                            v-focus>
                            Add
                        </b-button>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            @editUsers="onEditItem"
            @delete="onDeleteItem"
            hide-show-column-chooser
            integrated-search
            rq-editable
            rq-filters
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { FileScanCategoryDto }  from "../models";
    import { UserGroupDto }  from "../../enterprise/models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import FileScanCategorySecurityList  from "./FileScanCategorySecurityList";

    export default {
        name:"FileScanCategoryList",
        data () {
            return {
                items: [],
                selectedItem: {},
                userGroups: [],
                validationErrors: [],
                addEventName: ""
            };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            localSecurity(){
                return this.securitySettings.findValues(["FileScanAllowCategoryManagement"]);
            },
            toolTip() { return this.localSecurity.FileScanAllowCategoryManagement ? "" : "Insufficient Privileges."; },
        },

        created(){
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.fetchData();
        },

        watch: {
            validationErrors: function () {
                const self = this;
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });
            }
        },
        methods: {

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = _.get(self.$route.meta, "itemTypeName");
                self.itemTypeNamePlural = _.get(self.$route.meta, "label");
                self.itemTypeDescription = _.get(self.$route.meta, "itemTypeDescription");
                self.itemKey = "fileScanCategoryID";
                self.selectionActions = [
                    {
                        name: "edit",
                        text: "Edit User Security",
                        eventName: "editUsers",
                        requireSelection: true,
                        tooltip: "Edit User Security",
                        disabled: function(e) {
                            if (!self.localSecurity.FileScanAllowCategoryManagement) {
                                return "Insufficient Privileges.";
                            } else {
                                return false;
                            }
                        }
                    },
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        allowMultiSelection: true,
                        tooltip: `Delete ${self.itemTypeName}`,
                        disabled: function(e) {
                            if (!self.localSecurity.FileScanAllowCategoryManagement) {
                                return "Insufficient Privileges.";
                            } else {
                                return false;
                            }
                        }
                    }];
            },

            initGridConfig(){
                const self = this;

                self.gridConfig = {
                    columns: [
                        { dataField: "fileScanCategoryID", visible: false, allowEditing: false, showInColumnChooser: false, },
                        {
                            dataField: "regionID", caption: "Region",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            editorOptions: {
                                readOnly: !self.localSecurity.FileScanAllowCategoryManagement
                            },
                            lookup: {
                                dataSource: self.lookupHelpers.getRegions(),
                                displayExpr: "displayName",
                                valueExpr: "regionID"
                            },
                            validationRules: [
                                { type: "required" }
                            ]
                        },
                        {
                            dataField: "categoryName",
                            caption: "Category",
                            editorOptions: {
                                maxLength: 256,
                                readOnly: !self.localSecurity.FileScanAllowCategoryManagement
                            },
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isNotDuplicateDescription,
                                    message: "Category for Region already exists",
                                    reevaluate: true
                                }
                            ]
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],
                    onRowUpdating(e) {
                        e.cancel = new Promise((resolve, reject) => {
                            if (!_.isNil(e.oldData.fileScanCategoryID) && e.oldData.fileScanCategoryID === 1) {
                                self.$dialog.messageBox(`Update Error`, `This Uncategorized Category cannot be updated.`);
                                self.gridInstance.refresh();
                                return;
                            }
                            self.$dialog.confirm(
                                "Confirm Edit",
                                `Updating ${self.itemTypeNamePlural} will change existing files. Do you want to continue?`,
                                () => resolve(false), //continue with edit
                                () => resolve(true), //cancel edit
                                { cancelTitle: 'No', okTitle: 'Yes'});
                        });
                    }
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            fetchData() {
                const self = this;
                let apiPromises = [
                    self.$api.FileScanCategoriesApi.getFileScanCategories(),
                    self.$api.UsersApi.getGroups()
                ];
                return self.$rqBusy.wait(Promise.all(apiPromises))
                    .then(result => {
                        self.items = _.map(result[0], i => new FileScanCategoryDto(i));
                        self.userGroups = _.map(_.sortBy(result[1], 'login'), i => new UserGroupDto(i));
                        self.refresh();
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow();
            },

            onEditItem(e) {
                const self = this;
                self.loadItem(new FileScanCategoryDto(e.data));
            },

            onGridInsert(values) {
                const self = this;
                let newItem = new FileScanCategoryDto(values);
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.save(newItem, changes)
                    .then(result => {
                        self.items.push(new FileScanCategoryDto(result));
                    });
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => _.parseNumber(_.get(item, self.itemKey), 0)  === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new FileScanCategoryDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.save(updatedItem, changes)
                    .then(result => {
                        self.items[itemIndex] = updatedItem;
                        self.refresh();
                    });
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                if (_.toUpper(e.data[0].categoryName) === "UNCATEGORIZED"){
                    self.$dialog.messageBox(`Delete Error`, `Uncategorized Category cannot be deleted.`);
                    return;
                }
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.delete(keys);
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    `Are you sure you want to delete the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.refresh();
            },

            loadItem(item) {
                const self = this;
                const onOk = e => {
                    return e.component.save()
                        .then(() => {
                            self.refresh();
                            return true;
                        });
                };

                self.$dialog.open({
                    title: "Edit User Security",
                    height: "700",
                    width: "850",
                    resizable: true,
                    component: FileScanCategorySecurityList,
                    props: {
                        fileScanCategoryId: item.fileScanCategoryID,
                        fileScanCategoryRegionId: item.regionID,
                        userGroups: self.userGroups
                    },
                    okTitle: "Save",
                    onOk
                });
            },

            save(item, changes){
                const self = this;
                if (changes.length === 0) {
                    return Promise.resolve(item);
                }
                let apiPromise = self.$api.FileScanCategoriesApi.saveFileScanCategories(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success(`${self.itemTypeName} ${item.categoryName} was saved.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },

            delete(keys) {
                const self = this;
                let exists = false;
                let apiPromise = self.$api.FileScanCategoriesApi.hasExistingDocuments(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        exists = result;
                        if(exists)
                        {
                            self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            return false;
                        }
                        else
                        {
                            apiPromise = self.$api.FileScanCategoriesApi.deleteFileScanCategories(keys);
                            return self.$rqBusy.wait(apiPromise)
                                .then(() => {
                                    self.removeItems(keys);
                                    let message = keys.length > 1
                                        ? `${keys.length} ${self.itemTypeNamePlural} were deleted.`
                                        : `${self.itemTypeName} was deleted.`
                                    self.$toast.success(message);
                                    return true;
                                })
                                .catch(error => {
                                    if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                        self.$dialog.messageBox(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                                    } else {
                                        self.$toast.error(`Error deleting ${self.itemTypeName}.`);
                                    }
                                    console.error(error);
                                    return error;
                                });
                        }
                    })
                    .catch(error => {
                        self.$toast.error(`Error: ${error.errorMessage}.`);
                    })                
            },

            isNotDuplicateDescription(item) {
                return !_.some(this.items, (i) => {
                    return _.toLower(_.trim(i.categoryName)) === _.toLower(_.trim(item.data.categoryName))
                            && _.parseNumber(i.regionID, -1) === _.parseNumber(item.data.regionID, -1)
                            && _.parseNumber(_.get(i, this.itemKey, -1), -1) != _.parseNumber(_.get(item.data, this.itemKey, -1), -1);
                });
            },

            removeItems(keys) {
                const self = this;
                _.forEach(keys, key => {
                    let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
                    if(itemIndex >= 0) self.items.splice(itemIndex, 1);
                });
                self.refresh();
            }
        }
    }
</script>

﻿<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" borderless header-only>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_add_item"
                            variant="theme"
                            v-rq-tooltip.hover.top
                            :title="`Add ${itemTypeName}`"
                            @click="onAddItem"
                            :disabled="!isSysAdmin">Add
                        </b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
        </rq-page-section>
        <div class="rq-master-detail-container">
            <section>
                <rqdx-action-data-grid
                    ref="dataGrid"
                    :automation_id="elementName('tbl')"
                    :actions="selectionActions"
                    :config="gridConfig"
                    :data-source="gridDataSource"
                    :export-file-name="elementName('', 'data')"
                    :strikethrough-if-true="['inactiveYN']"
                    target-inactive-column="inactiveYN"
                    @activate="onActivateItem($event, 'Activate')"
                    @inactivate="onActivateItem($event, 'Inactivate')"
                    @edit="onEditItem"
                    @delete="onDeleteItem"
                    @reset="onReset"
                    @rowDoubleClick="onEditItem"
                    hide-show-column-chooser
                    integrated-search
                    show-include-inactive
                    rq-filters
                />
            </section>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { WorkflowDepartmentDto, WorkflowDepartmentUserDto }  from "../models";
    import WorkflowDepartmentsForm  from "./WorkflowDepartmentsForm.vue";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import RqSelectionListBox from "@/shared/components/rq/list-box/RqSelectionListBox";
    import { UserSecuritySettings } from "@/shared/models/models";

    export default {
        name:"WorkflowDepartmentsList",
        props: { },
        data () {
            return {
                items: [],
                itemKey: "workflowDepartmentID",
                selectedItem: {},
                allAvailableUsers: [],
            };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                authSettings: state => state.authentication.session.settings,
            }),
            ...mapGetters(["lookupHelpers"]),
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null) || {}; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            selectionActions() {
                const self = this;
                return [
                    { name: "edit", text: "Edit", eventName: "edit", requireSelection: true, allowMultiSelection: false, tooltip: `Edit ${this.itemTypeName}` },
                    { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}`, disabled: function(e) { return !self.isSysAdmin;} },
                    { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return _.some(e.data, ['inactiveYN', false]) || !self.isSysAdmin; } },
                    { name: "inactivate", text: "Inactivate", eventName: "inactivate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return _.some(e.data, ['inactiveYN', true]) || !self.isSysAdmin; } },
                ];
            },
            isSysAdmin(){
                const self = this;
                let settings = new UserSecuritySettings(self.authSettings);
                let value = settings.findValue("IsSysAdmin");
                return value;
            }
        },

        watch: {},

        created(){
            GlobalEventManager.onSave(this, this.onSave);
            this.fetchUserData();
            this.fetchData();
            this.initGridConfig();
        },

        beforeUnmount() {
            GlobalEventManager.unregister(this);
        },

        methods: {

            activate(keys, verb) {
                const self = this;
                let apiPromise = self.$api.WorkflowDepartmentsApi.activate(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.toggleInactiveFlag(keys);
                        self.refresh();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },
            addNewItem() {
                const self = this;
                self.gridInstance.deselectAll();
                self.openModal(new WorkflowDepartmentDto({regionID: self.user.regionID}));
            },
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
            onActivateItem(e, verb) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.activate(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
                    self.gridInstance.deselectAll();
            },
            onAddItem() {
                this.addNewItem();
            },
            onEditItem(e) {
                let selectedItem = new WorkflowDepartmentDto(e.data);
                this.openModal(selectedItem);
            },
            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let apiPromises = _.map(items, item => self.$api.WorkflowDepartmentsApi.deleteWorkflowDepartment(item[self.itemKey]));
                    return self.$rqBusy.wait(Promise.all(apiPromises))
                        .then(keys => {
                            let message = keys.length > 1 ? `${keys.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success(message);
                            self.fetchData();
                            return true;}
                        )
                        .catch(error => {
                            if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                self.$events.emit(
                                    "update-config-error",
                                    `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`
                                );
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null,
                    { cancelTitle: "No", okTitle: "Yes"});
            },
            onReset(e) {
                this.fetchData();
            },
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "departmentName",
                            caption: "Department Name",
                        },
                        {
                            dataField: "description",
                        },
                        {
                            dataField: "inactiveYN",
                            caption: "Inactive",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        }
                    ]
                };

                self.gridDataSource = {
                    loadMode: "raw",
                    key: self.itemKey,
                    load () {
                        return Promise.resolve(self.items);
                    }
                };
            },
            fetchData() {
                const self = this;
                let apiPromise = self.$api.WorkflowDepartmentsApi.getWorkflowDepartments();
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new WorkflowDepartmentDto(i));
                        self.refresh();
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                    });
            },
            fetchUserData() {
                const self = this;
                if(!_.isEmpty(self.allAvailableUsers)) return Promise.resolve(self.allAvailableUsers);
                let apiPromise = self.$api.UsersApi.getUsers();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.allAvailableUsers = _.filter(result, u => u.isInactive === false);
                        return result;
                    })
                    .catch(error => {
                        self.$toast.error("Error getting Associated Users.");
                        return error;
                    });
            },
            refresh() {
                this.gridInstance.refresh();
            },
            updateItem(item) {
                let editIem = _.find(this.items, (i) => {
                    return _.parseNumber(_.get(i, this.itemKey, -1), -1) == _.parseNumber(_.get(item, this.itemKey, -1), -1);
                });
                _.assign(editIem, item);
            },
            toggleInactiveFlag(keys) {
                _.each(keys, k => {
                    let e = _.find(this.items, [this.itemKey, k]);
                    e.inactiveYN = !e.inactiveYN;
                });
            },
            openModal(item) {
                const self = this;
                let dialogId = null;
                let isNew = item.isNew;
                let onOk = (e, addAnother=false) => {
                    return e.component.save()
                        .then(result => {
                            if(!result.success) return false;
                            if(isNew)
                                self.items.push(new WorkflowDepartmentDto(result.data));
                            else
                                self.updateItem(result.data);

                            self.refresh();

                            if (addAnother) {
                                self.$dialog.reloadComponent({ dialogId, props: { item: new WorkflowDepartmentDto({regionID: self.user.regionID}) } });
                                return false;
                            } else {
                                self.gridInstance.deselectAll();
                                self.$dialog.close();
                            }
                        });
                };

                dialogId = self.$dialog.open({
                    title: `${item.isNew ? "Add": "Edit"} ${self.itemTypeName}${(item.isNew ? "" : `: ${item.description}`)}`,
                    width: 1200,
                    adaptive: true,
                    component: WorkflowDepartmentsForm,
                    props: {
                        item,
                        uniqueValidator: (item,field) => !self.isDuplicate(item, field),
                        users: self.allAvailableUsers.slice()
                    },
                    buttons: [
                        { title: "Cancel", automationId: "btn_dm_modal_cancel", cssClass: "btn btn-secondary", onClick: () => true },
                        { title: "Save and Add Another", automationId: "btn_dm_modal_save_and_another", cssClass: "btn btn-primary", isVisible: item.isNew, onClick: (e) => { return onOk(e, true); } },
                        { title: "Save", automationId: "btn_dm_modal_save", cssClass: "btn btn-primary", onClick: (e) => { return onOk(e, false); } }
                    ]
                });
            },
            isDuplicate(item, fieldName) {
                const self = this;
                let trimLower = val => _.toLower(_.trim(val));
                return _.some(self.items, item2 =>
                    trimLower(item2[fieldName]) === trimLower(item[fieldName])
                    && _.parseNumber(item2[self.itemKey]) !== _.parseNumber(item[self.itemKey])
                );
            }
        }
    }
</script>

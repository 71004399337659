<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" header-size="lg" borderless header-only>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            @lock="onLockItem"
            @unlock="onUnlockItem"
            @rowDoubleClick="onLockItem"
            hide-show-column-chooser
            integrated-search
            rq-filters
        />
    </div>
</template>

<script>

    import { mapState } from "vuex";
    import { UnderwriterRemitLockDateDto }  from "../models";
    import LockRemitForm  from "./LockRemitForm.vue";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"LockRemitList",
        data () {
            return {
                items: [],
                selectedItem: {}
            };
        },
        created(){
            this.initGridConfig();
        },

        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
            }),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            itemKey() { return "underwriterCompanyID"; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            localSecurity(){
                return this.securitySettings.findValues(["CanUnlockPolicyRemitDates"]);
            },
            selectionActions() {
                const self = this;
                return [{
                            name: "lock",
                            text: "Lock",
                            eventName: "lock",
                            requireSelection: true,
                            disabled(e) {
                                return e.data.hasRemitDate || !self.localSecurity.CanUnlockPolicyRemitDates || self.readOnly;
                            }
                        },
                        {
                            name: "unlock",
                            text: "Unlock",
                            eventName: "unlock",
                            requireSelection: true,
                            allowMultiSelection: true,
                            disabled(e) {
                                return !_.every(e.data, ['hasRemitDate', true]) || !self.localSecurity.CanUnlockPolicyRemitDates || self.readOnly;
                            }
                        }];
            },
        },

        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },


            onLockItem(e) {
                const self = this;
                if (e.data.hasRemitDate || !self.localSecurity.CanUnlockPolicyRemitDates) return;
                self.selectedItem = new UnderwriterRemitLockDateDto(e.data);
                self.loadItem(new UnderwriterRemitLockDateDto(e.data));
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        { dataField: "underwriter", sortIndex: 0, sortOrder: "asc" },
                        {
                            dataField: 'remitLockDate',
                            caption: 'Lock Date',
                            dataType: 'date'
                        },
                    ]
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load: self.fetchData,
                };
            },

            fetchData (loadOptions) {
                const self = this;
                let apiPromise = self.$api.CompaniesApi.getUnderwriterRemitLockDates();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let items = _.map(result, i => new UnderwriterRemitLockDateDto(i));
                        self.items = items;
                        return { data: items, totalCount: items.length };
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            loadItem(item) {
                const self = this;

                self.$dialog.open({
                    title: "Lock Remittance",
                    width: "500",
                    adaptive: true,
                    component: LockRemitForm,
                    props: {
                        item: item,
                        itemTypeName: self.itemTypeName,
                    },
                    okTitle: "Save",
                    onOk: e => self.save(e.component.item)
                });

            },

            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            onUnlockItem(e) {
                if(!e || !e.data) return;
                const self = this;
                if (!self.localSecurity.CanUnlockPolicyRemitDates) return;
                let items = e.data;
                let ok = function (args) {
                    let newItems = [];
                    _.forEach(items, (item) => {
                        item.remitLockDate = null;
                        newItems.push(item.toDataObject());
                    });
                    let apiPromise = self.$api.CompaniesApi.saveUnderwriterRemitLockDates(newItems);
                    return self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            self.$toast.success({ message: "Remittance was Unlocked." });
                            return true;}
                        )
                        .catch(error => {
                            self.$dialog.confirm("Unlock Error", `There was an error unlocking this ${self.itemTypeName}: ${error.errorMessage}`);
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Unlock", `Are you sure you want to Unlock Remittance for the selected Underwriters?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            save(item){
                const self = this;
                let changes = self.getAuditChanges(self.selectedItem.toDataObject(), item.toDataObject());
                if (changes.length == 0) return Promise.resolve(true);
                let apiPromise = self.$api.CompaniesApi.saveUnderwriterRemitLockDates([item.toDataObject()]);
                return self.$rqBusy.wait(apiPromise)
                    .then(item => {
                        self.refresh();
                        self.$toast.success({ message: `Remittance for ${item[0].underwriter} was ${item[0].remitLockDate == null ? "Unlocked" : "Locked"}.` });
                        return true;
                    }).catch(e => {
                        self.$toast.error("An error prevented saving lock/unlock remit date.")
                        return false;
                    });
            },
        }
    }
</script>
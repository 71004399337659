<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        :automation_id="elementName('tbl')"
        :actions="selectionActions"
        :config="gridConfig"
        :data-source="gridDataSource"
        :export-file-name="elementName('', 'data')"
        :strikethrough-if-true="['isHidden']"
        target-readonly-column="readOnly"
        v-model:validation-errors="validationErrors"
        v-model:search-value="searchText"
        focus-after-insert="none"
        @export-documents="onExportAction"
        @edit-users="onEditUsers"
        @edit="onEditAction"
        @watermarks="onManageWatermarks"
        @delete="onDeleteAction"
        force-floating-header
        rq-editable
        rq-filters
    />
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { DateTime } from "luxon";
    import { DocumentSecurityUserDto }  from "../models";
    import { UserGroupDto }  from "../../enterprise/models";
    import { DefaultUserSecurity, DocumentSource } from '../../enums';
    import { DocumentTemplateDto } from "@documents/models";
    import { SearchRequest } from "@/shared/models/models";

    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import GridInvokerMixin from "@/shared/mixins/GridInvokerMixin";
    import DocumentTemplateUserSecurityForm from './DocumentTemplateUserSecurityForm.vue';
    import DocumentTemplateEditor from "./DocumentTemplateEditor";
    import { WatermarkForm } from "@documents/components/editor-dialogs";

    export default {
        name:"DocumentTemplateList",
        mixins: [ GridInvokerMixin({ grid:"dataGrid" }) ],
        props: {
            isValid: { type: Boolean, default: true },
            validationList: { type: Array, default: () => []},
            userGroups: { type: Array, default: () => [] }
        },
        data () {
            return {
                selectedType:  "xml",
                itemKey: "documentTemplateID",
                itemTypeNamePlural: "Document Templates",
                itemTypeName: "Document Template",
                selectedItem: {},
                validationErrors: [],
                originalItemData: null,
                searchText: "",
                lastValidation: {},
                initialLoad: true,
                highlightImport: false
            };
        },

        computed: {
            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
                user: state => state.authentication.session.user,
                regions: state => state.system.lookups.regions,
                userRegions: state => state.authentication.session.regions
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            selectionActions() {
                const self = this;
                return [
                    { name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: "Edit with Word" },
                    { name: "export-documents", text: "Export Documents", allowMultiSelection: true,  eventName: "export-documents" },
                    {
                        name: "edit-users",
                        text: "Edit User Security",
                        eventName: "edit-users",
                        requireSelection: true,
                        tooltip: `Edit User Security for  ${this.itemTypeName}`,
                        disabled: e => e.data.readOnly ? "Access Restricted" : false
                    },
                    {
                        name: "manage-watermarks",
                        text: "Manage Watermarks",
                        eventName: "watermarks",
                        requireSelection: true,
                        tooltip: `Manage watermarks for  ${this.itemTypeName}`,
                        disabled: e => e.data.readOnly ? "Access Restricted" : false
                    },
                    {
                        name: "delete",
                        text: "Delete Template",
                        eventName: "delete",
                        allowMultiSelection: true,
                        tooltip: `Delete ${this.itemTypeName}`,
                        disabled(e) {
                            let selectedItems = _.get(e, "data", null) || [];
                            if(_.some(selectedItems, "readOnly")) {
                                return "Access Restricted";
                            }
                            if(_.some(selectedItems, item => item.documentSource === DocumentSource.RamQuest)) {
                                return "Only templates with the source, \"User\" or \"ARC\", can be deleted";
                            }
                            return false;
                        }
                    }
                ];
            },
            fileScanCategories() {
                let items = this.lookupHelpers.getLookupItems(this.lookupItems.FILE_SCAN_CATEGORIES);
                items.unshift({ id:0, name:"None" });
                return items;
            },
            documentSources() { return this.lookupHelpers.getLookupItems(this.lookupItems.DOCUMENT_SOURCES); },
        },

        watch: {
            validationErrors(newValue) {
                this.$emit("update:isValid", _.isEmpty(newValue));
                this.$emit("update:validationList", this.validationErrors);
            },
            searchText(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.gridSearch(newValue);
            }
        },

        created(){
            const self = this;
            self.highlightImport = _.toLower(self.$route?.hash) === "#arc-import";
            this.initStaticVariables();
            self.fetchData();
            self.initGridConfig();
        },

        methods: {
            initStaticVariables() {
                const self = this;
                self.documentTags = self.lookupHelpers.getLookupItems(self.lookupItems.DOCUMENT_TAGS);
            },
            onEditUsers(e){
                const self = this;
                self.launchUserSecurityDialog(new DocumentSecurityUserDto({documentTemplateID: e.data.documentTemplateID}));
            },

            onManageWatermarks(e) {
                this.launchWatermarkDialog(e.data);
            },

            initGridConfig(){
                const self = this;
                let duplicateValidationRule = fieldName => ({
                    type: "async",
                    reevaluate: true,
                    validationCallback: e => self.validateDuplicate(e, fieldName)
                });
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "regionID",
                            caption: "Region",
                            calculateSortValue: "regID",
                            lookup: {
                                dataSource: self.regions,
                                displayExpr: "name",
                                valueExpr: "regionID"
                            },
                            validationRules: [{ type: "required" }]
                        },
                        {
                            dataField: "name",
                            caption: "Document Name",
                            validationRules: [
                                { type: "required" },
                                duplicateValidationRule("name")
                            ],
                            editorOptions: {
                                maxLength: 100
                            },
                        },
                        {
                            dataField: "description",
                            validationRules: [
                                { type: "required" },
                                duplicateValidationRule("description")
                            ],
                            editorOptions: {
                                maxLength: 100
                            },
                        },
                        {
                            dataField: "fileScanCategoryID",
                            caption: "Document Management Category",
                            calculateSortValue: "fileScanCategoryText",
                            lookup: {
                                dataSource: self.fileScanCategories,
                                valueExpr: "id",
                                displayExpr: "name"
                            }
                        },
                        {
                            dataField: "documentTags",
                            datatype: "object",
                            caption: "Tags",
                            allowFiltering: false,
                            calculateDisplayValue: "documentTagDisplay",
                            calculateSortValue: "documentTagDisplay",
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxTagBox({
                                    items: self.documentTags,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: cellInfo.value,
                                    showSelectionControls: true,
                                    showDropDownButton: true,
                                    maxDisplayedTags: 1,
                                    onValueChanged: function(e) {
                                        cellInfo.setValue(e.value);
                                    }
                                }).appendTo(cellElement);
                            },
                        },
                        {
                            dataField: "documentSource",
                            caption: "Source",
                            lookup: {
                                dataSource: self.documentSources,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            allowEditing: false
                        },
                        {
                            dataField: "isHidden",
                            caption: "Inactive",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        },
                        {
                            dataField: "defaultUserSecurityAccess",
                            caption: "Default User Sec",
                            calculateSortValue: "defaultUserSecurityAccessText",
                            lookup: {
                                dataSource: DefaultUserSecurity.lookupItems,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                        },
                        {
                            dataField: "userSecurityStatus",
                            caption: "User Sec Exceptions",
                            alignment: "center",
                            calculateFilterExpression: (filterValue, operator) => (["userSecurityStatus", '=', filterValue ? 1 : 0]),
                            calculateCellValue(data) { return data.userSecurityStatus===1; },
                            cellTemplate(cellElement, cellInfo) {
                                cellElement.text(cellInfo.value ? "Yes" : "No" );
                            },
                            allowEditing: false
                        },
                        {
                            dataField: "dateCreated",
                            dataType: "datetime",
                            caption: "Date Created",
                            visible: false,
                            allowEditing: false,
                        },
                        {
                            dataField: "dateModified",
                            dataType: "datetime",
                            caption: "Date Modified",
                            visible: true,
                            allowEditing: false,
                        },
                        {
                            dataField: "lastModifiedBy",
                            caption: "Last modified by",
                            minWidth: 135,
                            allowSearch: false,
                            allowEditing: false,
                            allowSorting: true,
                            cellTemplate: DxGridUtils.truncateCellTemplate
                        },
                        DxGridUtils.dateColumn({
                            dataField: 'lastPrintedDate',
                            cellTemplate: DxGridUtils.dateOnlyTimeZoneDependentCellTemplate,
                            allowEditing: false
                        }),
                    ],
                    remoteOperations: { sorting: true, paging: true, filtering: true },
                    onInitialized(e){
                        if(!self.highlightImport) return;
                        e.component.clearSorting();
                    },
                    onInitNewRow(e) {
                        e.data.documentSource = DocumentSource.User;
                        e.data.isHidden = false;
                        e.data.fileScanCategoryID = 0;
                        e.data.defaultUserSecurityAccess = DefaultUserSecurity.FullAccess;
                    },
                    onEditingStart(e) {
                        self.gridInstance.clearSelection();
                        self.originalItemData = e.data.toDataObject();
                    },
                    onRowPrepared: self.onRowPrepared

                };
                self.gridDataSource = {
                    key: self.itemKey,
                    load: self.fetchData,
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            getCurrentSearchRequest (loadOptions) {
                const self = this;
                if(!self.initialLoad && self.highlightImport)
                    self.highlightImport = false;
                let request = new SearchRequest({
                    pageNumber: 1,
                    pageSize: 50,
                    pagingEnabled: true,
                    searchTerm: self.searchText
                });
                request.parseLoadOptions(loadOptions);
                _.remove(request.sortBy, sb => sb.field === "documentTemplateID");
                return request;
            },

            gridSearch: _.debounce(function(searchTextValue) {
                this.gridInstance.refresh();
            }, 300),

            fetchData(loadOptions) {
                const self = this;
                if(_.isEmpty(loadOptions) || DxGridUtils.hasFieldFilter(loadOptions, self.itemKey)) return;
                let request = self.getCurrentSearchRequest(loadOptions);
                let apiPromise = self.$api.DocumentTemplatesApi.search(request);
                return self.$rqBusy.wait(apiPromise)
                    .then(response => {
                        if(self.initialLoad) {
                            _.delay(() => self.updateDimensions(), 200);
                            self.initialLoad = false;
                        }
                        return {
                            data: _.map(response.results, item => new DocumentTemplateDto(item, self.userRegions, self.globalRegionId)),
                            totalCount: response.totalRecords
                        };
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error(`Error loading ${self.itemTypeNamePlural}.`);
                        return error;
                    });
            },

            onAddItem() {
                const self = this;
                if(!self.gridInstance) return;
                self.gridInstance.addRow();
            },

            onOpenAction(e) {
                const self = this;
                let data = _.get(e, "data", null);
                if(_.isNil(data)) return;
                self.launchEditorDialog(data);
            },

            onGridUpdate(key, values) {
                const self = this;
                let updatedItem = new DocumentTemplateDto(_.assign({}, self.originalItemData, values));
                let changes = self.getAuditChanges(self.originalItemData, updatedItem.toDataObject());
                return self.save(updatedItem, changes)
                    .then(result => {
                        self.originalItemData = null;
                        return updatedItem;
                    });
            },

            onGridInsert(values) {
                const self = this;
                let newItem = new DocumentTemplateDto(values);
                return self.save(newItem).then(result => new DocumentTemplateDto(result));
            },

            onExportAction(e) {
                const self = this;

                let firstTemplateName = _.trim(_.get(e, "data[0].name", null));
                let fileName = e.data.length > 1 || _.isEmpty(firstTemplateName) ? "DocumentTemplates" : firstTemplateName;
                let passedData = _.map(e.data, "documentTemplateID");

                let apiPromise = self.$api.ExportApi.fetchDocumentTemplate(passedData);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.xml = result;
                        self.downloadExport(`${fileName}.${self.selectedType}`);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error creating XML for Export.` });
                        return error;
                    });
            },
            onEditAction(e) {
                const self = this;
                let data = _.get(e, "data", null);
                if(_.isNil(data)) return;

                if(data.documentTemplateID <= 0) {
                    self.documentTemplate = new DocumentTemplateDto({ name: "Untitled Document", description: "Untitled Document" });
                    return;
                }

                if(data.readOnly) {
                    self.launchEditorDialog(data, true);
                    return;
                }

                let apiPromise = self.$api.DocumentTemplatesApi.getDocumentTemplate(data.documentTemplateID)
                    .then(result => {
                        self.documentTemplate = new DocumentTemplateDto(result);
                        self.documentName = self.documentTemplate.name || self.documentTemplate.description;
                        self.documentFileType = self.documentTemplate.defaultFileType;
                        self.documentContent = self.documentTemplate.getDefaultContent(true);
                        self.fileId = self.documentTemplate.fileId;
                        self.webDavProxySubDir = self.documentTemplate.webDavProxySubDir;
                        return;
                    }).then(() => {
                        let loc = window.location.origin + '/api' + '/webdav/' + self.webDavProxySubDir;
                        window.open('ms-word:ofe|u|' + loc);
                    });

                self.$rqBusy.wait(apiPromise);
                    // .then(result => {
                    //     self.documentTemplate = new DocumentTemplateDto(result);
                    //     self.documentName = self.documentTemplate.name || self.documentTemplate.description;
                    //     self.documentFileType = self.documentTemplate.defaultFileType;
                    //     self.documentContent = self.documentTemplate.getDefaultContent(true);
                    //     self.fileId = self.documentTemplate.fileId;
                    //     return _.wait(5000);
                    // }).then(result2 => {
                    //     debugger;
                    //     window.open('ms-word:ofe|u|http://localhost:81/' + self.documentTemplateID + '_' + self.fileId);
                    // });
            },
            onDeleteAction(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;

                let ok = function (args) {
                    let documentTemplateIDs = _.map(items, r => r.documentTemplateID);
                    let apiPromise = self.$api.DocumentTemplatesApi.delete(documentTemplateIDs);
                    return self.$rqBusy.wait(apiPromise)
                        .then(results => {
                            if(results.deletedIds.length === items.length) {
                                let message = items.length > 1 ? `${items.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                                self.$toast.success(message);
                                self.reload();
                            }
                            else{
                                let message = "";
                                if(results.deletedIds.length > 0){
                                    message = results.deletedIds.length > 1 ? `${results.deletedIds.length} templates were deleted.  ` : `${results.deletedIds.length} template was deleted.  `
                                }
                                let fileLabel = results.fileUsageCount > 1 ? "files" : "file";
                                let inactiveInstructions = results.failedIds.length > 1 ? "these templates to remove them" : "this template to remove it";
                                let singleItemStart = message.length === 0 ? "This template" : "1 template";
                                message += results.failedIds.length > 1 ? `${items.length} templates are used on ${results.fileUsageCount} ${fileLabel} and cannot be deleted. Select the Inactive option for ${inactiveInstructions} from displaying in the Generate Documents page.` :
                                    `${singleItemStart} is used on ${results.fileUsageCount} ${fileLabel} and cannot be deleted. Select the Inactive option for ${inactiveInstructions} from displaying in the Generate Documents page.`;
                                self.$dialog.messageBox("Delete Results", message);
                                if(results.errors.length > 0){
                                    _.forEach(results.errors, err => self.$toast.error(err));
                                }
                                self.reload();
                            }
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${self.itemTypeName}: ${error.errorMessage}` });
                            return error;
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            save(item, changes){
                const self = this;
                self.assignRegId(item);
                if (_.isEmpty(changes) && item.documentTemplateID > 0) {
                    return Promise.resolve(item);
                }
                self.highlightImport = false;
                let apiPromise = self.$api.DocumentTemplatesApi.save(item.toDataObject());
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.fetchData();
                        self.$toast.success(`The ${self.itemTypeName}, ${item.name}, was saved.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },

            downloadExport(filename) {
                const self = this;
                var file = new Blob([this.xml], {type: self.selectedType});
                if (window.navigator.msSaveOrOpenBlob) // IE10+
                    window.navigator.msSaveOrOpenBlob(file, filename);
                else { // Others
                    var a = document.createElement("a"),
                            url = URL.createObjectURL(file);
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function() {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 0);
                }
            },

            launchUserSecurityDialog(item) {
                const self = this;
                let onOk = e => {
                    let items = e.component.changedItems;
                    if (!_.isArray(items) || items.length === 0) return true;
                    self.saveUsers(items);
                    return true;
                };
                self.$dialog.open({
                    title: "Edit Document User Security",
                    height: 700,
                    width: 850,
                    closeOnEsc: true,
                    component: DocumentTemplateUserSecurityForm,
                    props: {
                        documentTemplateID: item.documentTemplateID,
                        userGroups: self.userGroups
                    },
                    okTitle: "Save",
                    onOk
                });
            },

            launchEditorDialog(data, readOnly=false) {
                const self = this;

                const okHandler = e => {
                    if(readOnly) return Promise.resolve(true);
                    return e.component.save()
                        .then(result => {
                            self.fetchData();
                            return result;
                        });
                };

                self.$dialog.open({
                    title: `Document Template: ${data.name}`,
                    component: DocumentTemplateEditor,
                    height: "95%",
                    width: "95%",
                    scrollable: false,
                    resizable: false,
                    props: { itemId: data.documentTemplateID, readOnly },
                    onOk: okHandler
                });
            },

            launchWatermarkDialog(data) {
                const self = this;
                const okHandler = function(e) {
                    return e.component.save();
                }
                self.$dialog.open({
                    title: "Printed Watermark",
                    component: WatermarkForm,
                    height: 700,
                    props: { documentTemplateId: data.documentTemplateID },
                    onOk: okHandler
                });
            },

            saveUsers(items, changes){
                const self = this;
                let apiPromise = self.$api.DocumentSecurityUsersApi.saveDocumentSecurityUsers(items, changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$dialog.close();
                        self.fetchData();
                        self.refresh();
                        self.$toast.success(`${self.itemTypeName} was saved.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },

            validate(data) {
                const self = this;
                if(data.readOnly || _.isEmpty(data) || _.parseNumber(data.regionID, 0) <= 0) return Promise.resolve(null);
                let validationInfo = { data };
                return self.$api.DocumentTemplatesApi.validate(data)
                    .then(result => {
                        validationInfo.result = result;
                        self.lastValidation = validationInfo;
                        return result;
                    })
                    .catch(error => {
                        self.$toast.error(`Error validating ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },

            // isDuplicate(data, fieldName){
            //     const self = this;
            //     let trimLower = val => _.toLower(_.trim(val));
            //     return _.some(self.items, item =>
            //         trimLower(item[fieldName]) === trimLower(data[fieldName])
            //         && _.parseNumber(item.regionID, -1) === _.parseNumber(data.regionID, -1)
            //         && _.parseNumber(item[self.itemKey], -1) !== _.parseNumber(data[self.itemKey], -1)
            //     );
            // },

            hasValidateResult(data, fieldName) {
                const self = this;
                if(_.isEmpty(self.lastValidation) || _.isEmpty(data)) return false;
                let validatedRegionId = _.get(self, "lastValidation.data.regionID", null);
                let validatedFieldValue = _.get(self, `lastValidation.data.${fieldName}`, null);
                return data.regionID === validatedRegionId && data[fieldName] === validatedFieldValue;
            },

            validateDuplicate(e, fieldName){
                const self = this;
                const validateComplete = result => new Promise((resolve, reject) => {
                    if(_.isNil(result)) resolve();
                    let msg = _.get(result, `feedbackMessageCollection.${fieldName}`, null);
                    _.isEmpty(msg) ? resolve() : reject(msg);
                });
                return self.hasValidateResult(e.data, fieldName)
                    ? validateComplete(self.lastValidation.result)
                    : self.validate(e.data).then(validateComplete);
            },

            refresh() {
                this.invokeGridMethod("option", "focusedRowIndex", -1);
                this.invokeGridMethod("clearSelection");
                this.invokeGridMethod("refresh");
            },

            reload() {
                const self= this;
                self.searchText = "";
                self.refresh();
            },

            assignRegId(item) {
                item.regID = _.filter(this.regions, r => r.regionID == item.regionID)[0].regID;
            },

            updateDimensions() {
                this.invokeGridMethod("updateDimensions");
                this.$nextTick(() => {
                    this.invokeGridMethod("repaint");
                });
            },
            onRowPrepared (e) {
                const self = this;
                let dtRecent = DateTime.now().minus({minutes: 3});
                let dtModified = DateTime.fromISO(e.data?.dateModified);
                if (e.rowType !== "data" || !self.highlightImport || !dtModified.isValid || dtRecent > dtModified) return;
                e.rowElement.addClass("rq-strong");
            },
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); }
        }
    }
</script>

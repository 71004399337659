<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" borderless header-only>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item" v-rq-tooltip.hover.top="toolTip">
                        <b-button
                            :automation_id="elementName('btn_add')"
                            :ref="elementName('btn_add')"
                            variant="theme"
                            :disabled="!localSecurity.FileScanAllowDescriptionManagement"
                            @click="onAddItem"
                            v-rq-tooltip.hover.top="{ title: `Add ${this.itemTypeName}` }"
                            v-focus>
                            Add
                        </b-button>
                    </li>
                    <li v-if="multipleItems" class="nav-item" v-rq-tooltip.hover.top="toolTip">
                        <b-button
                            :automation_id="elementName('btn_reorder')"
                            variant="theme"
                            v-rq-tooltip.hover.top="{ title: `Reorder ${this.itemTypeNamePlural}` }"
                            :disabled="!localSecurity.FileScanAllowDescriptionManagement"
                            @click="onReorderItems">
                            Reorder
                        </b-button>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
        </rq-page-section>
        <!-- eslint-disable -->
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            focus-after-insert="new-row"
            :strikethrough-if-true="['inactive']"
            @delete="onDeleteItem"
            integrated-search
            hide-show-column-chooser
            rq-editable
            rq-filters
        />
        <!-- eslint-enable -->
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { FileScanDescriptionDto }  from "../models";
    import { SortableList } from "@/shared/components/rq";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"FileScanDescriptionList",
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: []
            };
        },
        created(){
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.fetchData();
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            localSecurity(){
                return this.securitySettings.findValues(["FileScanAllowDescriptionManagement"]);
            },
            multipleItems() { return this.items.length > 1; },
            toolTip() { return this.localSecurity.FileScanAllowDescriptionManagement ? "" : "Insufficient Privileges."; },
        },
        watch: {
            validationErrors: function () {
                const self = this;
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });
            }
        },
        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = _.get(self.$route.meta, "itemTypeName");
                self.itemTypeNamePlural = _.get(self.$route.meta, "label");
                self.itemTypeDescription = _.get(self.$route.meta, "itemTypeDescription");
                self.itemKey = "fileScanDescriptionID";
                self.selectionActions = [
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${self.itemTypeName}`,
                        disabled: function(e) {
                            if (!self.localSecurity.FileScanAllowDescriptionManagement) {
                                return "Insufficient Privileges.";
                            } else {
                                return false;
                            }
                        }
                    }
                ];
            },
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            allowEditing: false,
                            showInColumnChooser: false,
                        },
                        {
                            dataField: "regionID",  caption: "Region",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            editorOptions: {
                                readOnly: !self.localSecurity.FileScanAllowDescriptionManagement
                            },
                            lookup: {
                                dataSource: self.lookupHelpers.getRegions(),
                                displayExpr: "displayName",
                                valueExpr: "regionID"
                            },
                            validationRules: [{ type: "required" }]
                        },
                        {
                            dataField: "description",
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isNotDuplicateDescription,
                                    message: "Description already exists"
                                }
                            ],
                            editorOptions: {
                                maxLength: 256,
                                readOnly: !self.localSecurity.FileScanAllowDescriptionManagement
                            },
                        },
                        {
                            dataField: "sequence",
                            dataType: "number",
                            allowEditing: false,
                            sortIndex: 0,
                            sortOrder: "asc"
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],
                    onRowUpdating(e) {
                        e.cancel = new Promise((resolve, reject) => {
                            self.$dialog.confirm(
                                "Confirm Edit",
                                `Updating ${self.itemTypeNamePlural} will change existing files. Do you want to continue?`,
                                () => resolve(false), //continue with edit
                                () => resolve(true), //cancel edit
                                { cancelTitle: 'No', okTitle: 'Yes'});
                        });
                    }
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },
            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow()
            },
            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;

                let ok = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.delete(keys);
                    return true;
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            delete(keys) {
                const self = this;
                let exists = false;
                let apiPromise = self.$api.FileScanDescriptionsApi.hasExistingDocuments(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        exists = result;
                        if(exists)
                        {
                            self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            return false;
                        }
                        else
                        {
                            apiPromise = self.$api.FileScanDescriptionsApi.deleteFileScanDescriptions(keys);
                            return self.$rqBusy.wait(apiPromise)
                                .then(key => {
                                    let message = key.length > 1 ? `${key.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                                    self.$toast.success({ message: message });
                                    return true;
                                })
                                .catch(error => {
                                    if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                        self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                                    } else {
                                        self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                                    }
                                    return error;
                                })
                                .finally(() => {
                                    self.fetchData();
                                });
                        }
                    })
                    .catch(error => {
                        self.$toast.error(`Error: ${error.errorMessage}.`);
                    })                
            },
            fetchData() {
                const self = this;
                let apiPromise = self.$api.FileScanDescriptionsApi.getFileScanDescriptions();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new FileScanDescriptionDto(i));
                        self.refresh();
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },
            save(item, changes){
                const self = this;

                if(changes.length === 0) {
                    return Promise.resolve(item);
                }

                let apiPromise = self.$api.FileScanDescriptionsApi.saveFileScanDescription(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success({ message: `${self.itemTypeName} ${item.description} was saved.` });
                        return result;
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.itemTypeName}.`);
                        return error;
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },
            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.refresh();
            },
            deleteItem(keys) {
                _.each(keys, k => {
                    _.remove(this.items, (i) => {return _.parseNumber(_.get(i, this.itemKey, -1), -1) == k;});
                });
            },
            isNotDuplicateDescription(item) {
                const self = this;
                let dup = {};
                dup = _.find(self.items, (i) => {
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(item.data.description))
                            && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(item.data, self.itemKey, -1), -1);
                });

                return dup ? false : true;
            },
            onGridInsert(values) {
                const self = this;
                let newItem = new FileScanDescriptionDto(values);
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.save(newItem, changes)
                    .then(result => {
                        self.items.push(new FileScanDescriptionDto(result));
                    });
            },
            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => _.parseNumber(_.get(item, self.itemKey), 0) === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new FileScanDescriptionDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.save(updatedItem, changes)
                    .then(result => {
                        self.items[itemIndex] = updatedItem;
                    });
            },
            onReorderItems() {
                const self = this;

                let items = _.map(self.items, i => {
                    return {
                        id: i.fileScanDescriptionID,
                        description: i.description,
                        ordinal: i.sequence
                    };
                });

                self.$dialog.open({
                    title: `Reorder ${self.itemTypeNamePlural}`,
                    height:"auto",
                    minHeight:450,
                    width:600,
                    addaptive: true,
                    component: SortableList,
                    props: { orderId: 0, items },
                    onOk(e) {
                        let changes = e.component.getChanges();
                        if(_.isEmpty(changes)) return true;
                        let request = { orderId: 0, changes };
                        let apiPromise = self.$api.FileScanDescriptionsApi.reorderFileScanDescriptions(request);
                        self.$rqBusy.wait(apiPromise)
                            .then((result) => {
                                self.items = _.map(result, i => new FileScanDescriptionDto(i));
                                self.refresh();
                                self.$toast.success(`${self.itemTypeNamePlural} order updated.`);
                            });
                        return true;
                    }
                });
            },
        }
    }
</script>

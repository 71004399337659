<template>
    <div class="content-wrapper">        
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            target-inactive-column="inactive"
            :strikethrough-if-true="['inactive']"

            @activate="onActivateItem"
            show-include-inactive
            focus-after-insert="first-row"
            integrated-search
            hide-show-column-chooser
            rq-editable
            rq-filters
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { RecordingType }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"recordingTypeList",
        
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                addEventName: ""
            };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            selectionActions() {
                return [
                    //{ name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}`, disabled: function(e) {return _.some(e.data, ["isSystem", true]) ? "One or more items is a system record and cannot be deleted." : false; } },
                    { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['inactive', true]); } },
                    { name: "inactivate", text: "Inactivate", eventName: "activate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['inactive', false]); } }                
                ];
            },
            errorMsg(){ return _.get(this, "validationErrors.length", 0) > 0 ?  "Please correct the highlighted errors on screen to continue." : _.get(this, "validationErrors")[0]; }  
        },

        watch: {
            validationErrors: function () {
                const self = this;  
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });                 
            }
        },

        created(){
            const self = this;
            self.initGridConfig();
            self.initListeners();
            self.fetchData();
        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        methods: {

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            initGridConfig(){
                const self = this;
                let validateExistingValue = (dataField, e) => {
                    let keyValue = _.getNumber(e, `data.${self.itemKey}`, 0);
                    return _.gt(keyValue, 0)
                        ? !self.itemExists(dataField, e.value, self.itemKey, keyValue)
                        : !self.itemExists(dataField, e.value);
                };
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "name",
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    message: "An item with that name already exists.",
                                    validationCallback(e){ return validateExistingValue("name", e); }
                                }
                            ],
                            editorOptions: {
                                maxLength: 50
                            }
                        },
                        {
                            dataField: "description",
                            validationRules: [
                                { type: "required" },
                            ],
                            editorOptions: {
                                maxLength: 150
                            },
                        },
                        { dataField: "isSystem", visible: false, dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate },
                        { dataField: "inactive", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate },
                    ],
                    onRowUpdating(e) {
                        if (!_.hasIn(e.newData, "name")) return;
                        if (_.isEqual(_.get(e.newData, "name"), _.get(e.oldData, "name"))) return;
                        e.cancel = new Promise((resolve, reject) => {
                            self.$dialog.confirm(
                                "Confirm Edit",
                                `Updating ${self.itemTypeNamePlural} will change existing files. Do you want to continue?`,
                                () => resolve(false), //continue with edit
                                () => resolve(true), //cancel edit
                                { cancelTitle: 'No', okTitle: 'Yes'});
                        });
                    },
                    onInitNewRow: e => {
                        e.data.inactive = false;
                        e.data.isSystem = false;
                    },
                    onEditorPreparing: e => {
                        if (e.parentType !== "dataRow" || e.dataField !== "name") return;
                        if (e.dataField === "name") e.editorOptions.disabled = e.row.data.isSystem;
                    }
                };
                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };

            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.RecordingTypeApi.getRecordingTypes();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new RecordingType(i));
                        self.gridInstance.refresh();
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow();
            },

            onGridInsert(values) {
                const self = this;
                let newItem = new RecordingType(values);
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.save(newItem, changes)
                    .then(result => {

                        self.items.push(new RecordingType(result));
                    });
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.recordingTypeID === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new RecordingType(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.save(updatedItem, changes)
                    .then(result => {
                        self.items[itemIndex] = updatedItem;
                    });
            },

            // onDeleteItem(e) {
            //     if(!e || !e.data) return;
            //     const self = this;
            //     let items = e.data;
            //     let itemLabel = items.length > 1
            //         ? self.itemTypeNamePlural
            //         : self.itemTypeName;

            //     let okHandler = function (args) {
            //         let keys = _.map(items, self.itemKey);
            //         self.delete(keys);
            //         return true;
            //     }

            //     self.$dialog.confirm(
            //         "Confirm Delete",
            //         `Are you sure you want to delete the selected ${itemLabel}?`,
            //         okHandler,
            //         null, { cancelTitle: 'No', okTitle: 'Yes'});
            // },

            save(item, changes){
                const self = this;
                if (changes.length === 0) {
                    return Promise.resolve(item);
                }
                let apiPromise = self.$api.RecordingTypeApi.saveRecordingType(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success(`${self.itemTypeName} ${item.description} was saved.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },

            // delete(keys) {
            //     const self = this;
            //     let apiPromise = self.$api.RecordingTypeApi.deleteRecordingTypes(keys);
            //     return self.$rqBusy.wait(apiPromise)
            //         .then(() => {
            //             self.removeItems(keys);
            //             let message = keys.length > 1
            //                 ? `${keys.length} ${self.itemTypeNamePlural} were deleted.`
            //                 : `${self.itemTypeName} was deleted.`
            //             self.$toast.success(message);
            //             return true;
            //         })
            //         .catch(error => {
            //             if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
            //                 self.$dialog.messageBox(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
            //             } else {
            //                 self.$toast.error(`Error deleting ${self.itemTypeName}.`);
            //             }
            //             console.error(error);
            //             return error;
            //         });
            // },

            itemExists(matchProp, matchValue, excludeProp=null, excludeValue=null) {
                const self = this;
                return _.some(self.items, item => {
                    if(_.isNil(excludeProp)) return item[matchProp] === matchValue;
                    return item[excludeProp] !== excludeValue && item[matchProp] === matchValue;
                });
            },

            removeItems(keys) {
                const self = this;
                _.forEach(keys, key => {
                    let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
                    if(itemIndex >= 0) self.items.splice(itemIndex, 1);
                });
                self.gridInstance.refresh();
            },
            
            onActivateItem(e){
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                let verb = _.every(items, ['inactive', true]) ? "Activate" : "Inactivate";

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.toggleIsInactive(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            toggleIsInactive(keys, verb) {
                const self = this;
                let apiPromise = self.$api.RecordingTypeApi.toggleIsInactive(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.fetchData();
                        self.gridInstance.clearSelection();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);                       
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },
        }
      
    }
</script>

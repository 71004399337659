<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
             :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            focus-after-insert="none"
            v-model:search-value="searchText"
            @delete="onDeleteItem"
            integrated-search
            rq-editable
            rq-filters
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { Ready2CloseSystemDto, Ready2CloseConfigurationDto }  from "../models";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { h } from "vue";
import { SystemLookupItem } from "@/shared/models/models";

    export default {
        name:"Ready2CloseSystemList",
        mixins: [GridSystemLookupMixin],
        data () {
            return {
                items: [],
                configuration: [],
                selectedItem: {},
                validationErrors: [],
                addEventName: "",
                validationContext: {},
                searchText: ""
            };
        },

        watch: {
            validationErrors(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$events.emit("update-config-error", { message: _.isEmpty(newValue) ? "" : "Please correct the highlighted errors on screen to continue.", hasError: !_.isEmpty(newValue) });
            }
        },

        created(){
            const self = this;
            self.fetchData();
            self.initGridConfig();
            self.initListeners();
        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                allBranches: state => _.get(state, "system.lookups.branches", [])
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            regions() { return this.lookupHelpers.getRegions(); },
            titleCompanies() { return this.lookupHelpers.getLookupItems(this.lookupItems.TITLE_COMPANIES);},
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null); },
            selectionActions() {
                return [
                    { name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: false, tooltip: `Delete ${this.itemTypeName}`, disabled: function(e) { return e.data.length > 1; } },
                ];
            },
        },

        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            fetchData() {
                const self = this;
                //let apiPromise = self.$api.Ready2CloseSystemsApi.getAll();
                let apiPromises = [ 
                    self.$api.Ready2CloseSystemsApi.getAll(), 
                    self.$api.Ready2CloseConfigurationApi.getConfiguration(),
                ];

                return self.$rqBusy.wait(Promise.all(apiPromises))
                    .then(result => {
                        self.items = _.map(result[0], i => new Ready2CloseSystemDto(i));
                        self.configuration = new Ready2CloseConfigurationDto(_.clone(result[1]));
                        if(!self.configuration.active) {
                                self.$events.emit("update-config-alerts", {
                                    alerts: [{
                                        icon: "fas fa-exclamation-triangle",
                                        variant: "error",
                                        message: "Enable ready2close must be checked in ready2close Configuration Setting page.",
                                    }]
                                });
                            }
                    })
                    .catch(error => {
                        self.items = [];
                        self.configuration = [];
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            initGridConfig(){
                const self = this;
                let branchDataSource = _.map(self.allBranches, b => {
                    let branchItem = _.clone(b);
                    let branchRegion = self.lookupHelpers.getRegion(b.regionID);
                    branchItem.regionDisplay = _.isNil(branchRegion)
                        ? "Region not found"
                        : branchRegion.description;
                    return branchItem;
                });
                let companyDataSource = _.map(self.titleCompanies, b => {
                    let companyItem = _.clone(b);
                    let companyRegion = self.lookupHelpers.getRegion(b.regionID);
                    companyItem.regionDisplay = _.isNil(companyRegion)
                        ? "Region not found"
                        : companyRegion.description;
                    return companyItem;
                });
                let allCompaniesOption = new SystemLookupItem({
                    id: 0,
                    name: "All Title Companies",
                    inactive: false,
                    regionID: 0
                });
                let uniqueSystemRule = {
                    type: "custom",
                    message: "The selected region/branch/title company combination already exists.",
                    validationCallback: params => !self.systemExists(params.data)
                };
                let uniqueNameRule = {
                    type: "custom",
                    message: "The selected name already exists.",
                    validationCallback: params => !self.nameExists(params.data)
                };
                let companyHasTenantRule = {
                    type: "custom",
                    message: "The selected title company can only be in one tenant.",
                    validationCallback: params => !self.companyHasTenant(params.data)
                }
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "regions",
                            dataType: "object",
                            caption: "Region",
                            width: 200,
                            minWidth: 150,
                            calculateSortValue: rowData => {
                                let regionNames = _.sortBy(_.map(_.filter(self.regions, r => _.includes(rowData.regions, r.regionID)), "description"));
                                return _.join(regionNames, "");
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                let regions = self.regions;
                                if(_.isEmpty(cellInfo.value) || _.isEmpty(regions)) return;
                                const searchHighlightTemplate = '<span class="dx-datagrid-search-text">$&</span>';

                                let displayText = self.getRegionBranchCompanyDisplayText(cellInfo.data, "Regions");

                                let searchHighlighted = _.isEmpty(_.trim(self.searchText)) ? displayText : _.replace(displayText, new RegExp(self.searchText, "ig"), searchHighlightTemplate);
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title", displayText)
                                    .append(searchHighlighted)
                                    .appendTo(cellElement);
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxTagBox({
                                    dataSource: {
                                        loadMode: "raw",
                                        load() {
                                            return self.regions;
                                        }
                                    },
                                    displayExpr: "description",
                                    valueExpr: "regionID",
                                    value: cellInfo.value,
                                    showSelectionControls: true,
                                    showDropDownButton: true,
                                    applyValueMode: "useButtons",
                                    maxDisplayedTags: 1,
                                    onValueChanged: function(e) {
                                        cellInfo.setValue({
                                            regions: e.value,
                                            allRegions: e.value.length === self.regions.length
                                        });
                                    }
                                }).appendTo(cellElement);
                            },
                            setCellValue(rowData, value) {
                                rowData.allRegions = value.allRegions;
                                rowData.regions = value.regions;
                                rowData.branches = [];
                                rowData.allBranches = false;
                            },
                            // calculateFilterExpression: (filterValue, operator) => {
                            //     if (_.isString(filterValue)) {
                            //         return rowData => {
                            //             let regionNames = _.sortBy(_.map(_.filter(self.regions, r => _.includes(rowData.regions, r.regionID)), "description"));
                            //             return _.includes(_.toLower(regionNames), _.toLower(filterValue))
                            //         }
                            //     }

                            //     if(operator !== "contains") return () => false;
                            
                            //     return rowData => {
                            //             let ids = [rowData.regionID, ...rowData.regions];
                            //             return _.includes(ids, filterValue);
                            //     }
                            // },
                            rqFilter: {
                                valueExpr: "regionID",
                                displayExpr: "description",
                                dataSource: _.sortBy(self.regions, "description"),
                                listOperator: "and",
                                valueOperator: "contains",
                            },
                            validationRules: [{ type: "required" }, uniqueSystemRule],
                        },
                        {
                            dataField: "branches",
                            dataType: "object",
                            caption: "Branch",
                            width: 200,
                            minWidth: 150,
                            cellTemplate: function(cellElement, cellInfo) {
                                if((_.isEmpty(cellInfo.value) && !cellInfo.data.allBranches) || _.isNil(cellInfo.data.regions)) return;
                                const searchHighlightTemplate = '<span class="dx-datagrid-search-text">$&</span>';

                                let displayText = self.getRegionBranchCompanyDisplayText(cellInfo.data, "Branches");

                                let searchHighlighted = _.isEmpty(_.trim(self.searchText)) ? displayText : _.replace(displayText, new RegExp(self.searchText, "ig"), searchHighlightTemplate);
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title", displayText)
                                    .append(searchHighlighted)
                                    .appendTo(cellElement);
                            },
                            setCellValue(rowData, value) {
                                rowData.allBranches = value.allBranches;
                                rowData.branches = value.branches;
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                if(_.isNil(cellInfo.data.regions) && !cellInfo.data.allRegions) {
                                    $("<div/>")
                                        .addClass("d-flex align-items-center text-muted ps-1")
                                        .height(cellElement[0].clientHeight)
                                        .text("Select a region first...")
                                        .appendTo(cellElement);
                                    return;
                                }
                                let branchItems = _.filter(branchDataSource, b => _.includes(cellInfo.data.regions, b.regionID));
                                let initialValue = (cellInfo.data.allBranches) ? _.map(branchItems, "branchID") : cellInfo.value;
                                $("<div />").dxTagBox({
                                    dataSource: _.sortBy(branchItems, 'regionID'),
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: initialValue,
                                    showSelectionControls: true,
                                    showDropDownButton: true,
                                    applyValueMode: "useButtons",
                                    maxDisplayedTags: 1,
                                    itemTemplate: itemData => {
                                        if (!itemData) return;
                                        var display = itemData.name;
                                            return $('<span>').addClass('d-flex justify-content-between')
                                            .append($('<span>').append(display))
                                            .append($('<span>').addClass('text-muted font-italic font-xs font-weight-light ms-auto').append(itemData.regionDisplay));
                                    },
                                    onValueChanged(e) {
                                        cellInfo.setValue({
                                            branches: e.value,
                                            allBranches: e.value.length === branchItems.length
                                        });
                                    }
                                }).appendTo(cellElement);
                            },
                            // calculateFilterExpression: (filterValue, operator) => {
                            //     if(_.isNil(operator)) return _.isString(filterValue)
                            //         ? rowData => {
                            //             let displayText = self.getBranchDisplayText(rowData);
                            //             return _.includes(_.toLower(displayText), _.toLower(filterValue))
                            //         }
                            //         : () => true;
                            //     return rowData => {
                            //         let ids = rowData.allBranches
                            //             ? _.map(_.filter(self.allBranches, b => _.includes(rowData.regions, b.regionID)), "id")
                            //             : rowData.branches;
                            //         return _.includes(ids, filterValue);
                            //     };
                            // },
                            rqFilter: {
                                valueExpr: "branchID",
                                displayExpr: "description",
                                dataSource: _.sortBy(branchDataSource, 'regionID'),
                                listOperator: "or",
                                valueOperator: "contains",
                                itemTemplate: {
                                    name: "BranchItemTemplate",
                                    props: { item: Object, index: Number },
                                    setup(props) {
                                        return () => h("div", { class: "d-inline-flex align-items-baseline" }, [
                                            h("span", props.item.name),
                                            h("span", { class: "text-muted font-italic font-xs font-weight-light ms-auto" }, props.item.regionDisplay)
                                        ]);
                                    }
                                }
                            },
                            validationRules: [{ type: "required" }, uniqueSystemRule],
                        },
                        {
                            dataField: "titleCompanyID",
                            dataType: "number",
                            caption: "Title Company",
                            alignment: "left",
                            width: 200,
                            minWidth: 150,
                            editCellTemplate: function(cellElement, cellInfo) {
                                if(_.isNil(cellInfo.data.regions) && !cellInfo.data.allRegions) {
                                    $("<div/>")
                                        .addClass("d-flex align-items-center text-muted ps-1")
                                        .height(cellElement[0].clientHeight)
                                        .text("Select a region first...")
                                        .appendTo(cellElement);
                                    return;
                                }
                                let companyItems = _.filter(companyDataSource, c => cellInfo.data.allRegions ?
                                    true :
                                    _.includes(cellInfo.data.regions, c.regionID));
                                companyItems.unshift(allCompaniesOption);
                                $("<div />").dxSelectBox({
                                    dataSource: companyItems,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: cellInfo.value,
                                    showDropDownButton: true,
                                    onValueChanged: function(e) {
                                        cellInfo.setValue(e.value);
                                    }
                                }).appendTo(cellElement);
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                let displayName = self.getRegionBranchCompanyDisplayText(cellInfo.data, "Companies");
                                let displayTitle = displayName;
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title", displayTitle)
                                    .append(displayName)
                                    .appendTo(cellElement);
                            },
                            setCellValue: function(rowData, value) {
                                rowData.titleCompanyID = value;
                            },
                            rqFilter: {
                                valueExpr: "titleCompanyID",
                                displayExpr: "name",
                                dataSource: _.sortBy(companyDataSource, "regionID"),
                                listOperator: "or",
                                valueOperator: "contains",
                                itemTemplate: {
                                    name: "CompanyItemTemplate",
                                    props: { item: Object, index: Number },
                                    setup(props) {
                                        return () => h("div", { class: "d-inline-flex align-items-baseline" }, [
                                            h("span", props.item.name),
                                            h("span", { class: "text-muted font-italic font-xs font-weight-light ms-auto" }, props.item.regionDisplay)
                                        ]);
                                    }
                                }
                            },
                            validationRules: [{ type: "required" }, uniqueSystemRule, companyHasTenantRule],
                            ...DxGridUtils.lookupSortDisplayExpr,
                        },
                        {
                            dataField: "name",
                            caption: "Name",
                            validationRules: [{ type: "required" }, uniqueNameRule,
                                {
                                    type: "pattern",
                                    pattern: /^[A-Za-z0-9_]+$/,
                                    message: "Tenant name can only contain letters, numbers, and underscores."
                                }
                        ]
                        },
                        // {
                        //     dataField: "ready2CloseUrl",
                        //     caption: "Ready2Close URL",
                        //     validationRules: [{ type: "required" }]
                        // },
                    ],
                    onInitNewRow: e => { },
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow();
            },

            onGridInsert(values) {
                const self = this;
                let originalItem = new Ready2CloseSystemDto();
                let newItem = new Ready2CloseSystemDto(values);
                let changes = self.getAuditChanges(originalItem.toDataObject(), newItem.toDataObject());
                return self.save(newItem, changes)
                    .then(result => {
                        self.fetchData();
                    });
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.id === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new Ready2CloseSystemDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                let saveChanges = function() {
                    self.save(updatedItem, changes)
                        .then(result => {
                            self.fetchData();
                        });
                }

                self.$dialog.confirm(
                    "Confirm Changes",
                    `Are you sure you want to change the selected ${self.itemTypeName}? These changes will impact Horizon's ability to send data to ready2close.`,
                    saveChanges,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            save(item, changes){
                const self = this;
                if (changes.length == 0) {
                    return Promise.resolve(item);
                }
                let apiPromise = self.$api.Ready2CloseSystemsApi.saveR2CSystem(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success(`${self.itemTypeName} was saved.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error({ message: `Error saving ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            getSystemLists(item) {
                const self = this;
                let selectedRegions = item.allRegions
                    ? self.regions
                    : _.filter(self.regions, r => _.includes(item.regions, r.regionID));
                let selectedBranches = item.allBranches
                    ? _.filter(self.allBranches, b => _.some(selectedRegions, ['regionID', b.regionID]))
                    : _.filter(self.allBranches, b => _.includes(item.branches, b.id));
                let selectedCompanies = item.titleCompanyID == 0
                    ? _.filter(self.titleCompanies, c => _.some(selectedRegions, ['regionID', c.regionID]))
                    : _.filter(self.titleCompanies, ['id', item.titleCompanyID]);

                return {
                    regions: selectedRegions,
                    branches: selectedBranches,
                    companies: selectedCompanies
                }
            },

            systemExists(targetItem) {
                const self = this;

                return _.some(self.items, item =>
                    item.id !== targetItem.id
                    && (_.intersection(self.getSystemLists(targetItem).branches, self.getSystemLists(item).branches).length > 0)
                    && (_.intersection(self.getSystemLists(targetItem).companies, self.getSystemLists(item).companies).length > 0)
                )
            },
            nameExists(item) {
                return _.some( this.items, i => (item.id !== i.id) && (i.name == item.name) );
            },
            companyHasTenant(targetItem) {
                const self = this;

                return _.some(self.items, item =>
                    item.id !== targetItem.id
                    && (_.intersection(self.getSystemLists(targetItem).companies, self.getSystemLists(item).companies).length > 0)
                )
            },

            getRegionBranchCompanyDisplayText(item, itemType) {
                if((_.isEmpty(item.branches) && !item.allBranches) || (_.isEmpty(item.regions) && !item.allRegions)) return;

                const self = this;
                let itemLists = self.getSystemLists(item);
                let selectedItems;
                let allSelected;
                let field = "name";
                
                switch(itemType) {
                    case "Regions":
                        selectedItems = itemLists.regions;
                        allSelected = item.allRegions;
                        field = "description";
                        break;
                    case "Branches":
                        selectedItems = itemLists.branches;
                        allSelected = item.allBranches;
                        break;
                    case "Companies":
                        selectedItems = itemLists.companies;
                        allSelected = item.titleCompanyID == 0;
                        break;
                }
                
                if(allSelected) return `All ${itemType} Selected`;
                return selectedItems.length == 1
                    ? _.joinParts(_.map(selectedItems, field), ", ")
                    : `${selectedItems.length} ${itemType} Selected`;
            },

            refresh() {
                if(_.isNull(this.gridInstance)) return;
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.delete(keys);
                    return true;
                }

                self.$dialog.confirm(
                    "Confirm Delete",
                    `Are you sure you want to delete the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            delete(keys) {
                const self = this;
                let apiPromise = self.$api.Ready2CloseSystemsApi.deleteR2CSystem(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.removeItems(keys);
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were deleted.`
                            : `${self.itemTypeName} was deleted.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                            self.$dialog.messageBox(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                        } else {
                            self.$toast.error(`Error deleting ${self.itemTypeName}.`);
                        }
                        console.error(error);
                        return error;
                    });
            },

            removeItems(keys) {
                const self = this;
                _.forEach(keys, key => {
                    let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
                    if(itemIndex >= 0) self.items.splice(itemIndex, 1);
                });
                self.refresh();
            }
        }
    }
</script>

<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validationErrors="validationErrors"
            @delete="onDeleteItem"
            @reset="onReset"
            integrated-search
            :rq-editable="!readOnly"
            rq-filters
        />
    </div>
</template>

<script>
    import { mapGetters, mapState  } from "vuex";
    import { AutoReconMappingsDto }  from "../models";
    import { AutoReconFileType } from '../../enums';
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name: "AutoReconMappingsList",
        mixins: [GridSystemLookupMixin],
        props: { },
        data () {
            return {
                items: [],
                validationErrors: [],
                addEventName: ""
            };
        },
        created(){
            this.fetchData();
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.initListeners();
        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                readOnly: state => state.isPageReadOnly,
            }),
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null) || {}; },
        },
        watch: {
            validationErrors: function () {
                const self = this;
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });
            }
        },
        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            fetchData () {
                const self = this;
                let apiPromise = self.$api.AutoReconMappingsApi.get();
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new AutoReconMappingsDto(i));
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    focusedRowEnabled: false,
                    cacheEnabled: false,
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            showInColumnChooser: false,
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "bankCompanyID",
                                dataType: "number",
                                caption: "Escrow Account",
                                validationRules: [{ type: "required" }],
                            },
                            lookupKey: self.lookupItems.ESCROW_ACCOUNTS
                        }),
                        {
                            dataField: "altAccountNumber", dataType: "string", editorOptions: { maxLength: 40 }
                        },
                        // {
                        //     dataField: "checkCodes", dataType: "string", editorOptions: { maxLength: 40 }, visible: false
                        // },
                        // {
                        //     dataField: "wireOutCodes", caption: "Wire Out", dataType: "string", editorOptions: { maxLength: 40 }, visible: false
                        // },
                        // {
                        //     dataField: "wireInCodes", caption: "Wire In", dataType: "string", editorOptions: { maxLength: 40 }, visible: false
                        // },
                        // {
                        //     dataField: "depositCodes", caption: "Deposit", dataType: "string", editorOptions: { maxLength: 40 }, visible: false
                        // },
                        {
                            dataField: "lookBackDays",
                            datatype: "number",
                            validationRules: [
                                {
                                    type: "range",
                                    min: 0,
                                    message: "Must be greater than 0."
                                }
                            ]
                        },
                        {
                            dataField: "forwardDays",
                            datatype: "number",
                            validationRules: [
                                {
                                    type: "range",
                                    min: 0,
                                    message: "Must be greater than 0."
                                }
                            ]
                        },
                        {
                            dataField: "ignoreDepSlip",
                            caption: "Ignore Deposit Slip",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            visible: true
                        },
                        {
                            dataField: "depositNumbers",
                            caption: "Use Deposit Numbers",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        },
                        {
                            dataField: "dailyRecons",
                            caption: "Reconcile One Account Per Day",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        },
                        {
                            dataField: "autoReconFileTypeID",
                            datatype: "number",
                            caption: "File Format",
                            lookup: {
                                dataSource: self.autoReconFileTypes,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            validationRules: [
                                { type: "required" },
                            ]
                        }
                    ],
                    onInitNewRow: function(e) {
                        _.set(e.data, self.itemKey, 0);
                        e.data.lookBackDays = 0;
                        e.data.forwardDays = 0;
                        e.data.ignoreDepSlip = false;
                        e.data.depositNumbers = false;
                        e.data.dailyRecons = false;
                    }
                };

                self.gridDataSource = {
                    loadMode: "raw",
                    key: self.itemKey,
                    load () {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            initNonReactiveVariables() {
                const self = this;
                self.autoReconFileTypes = AutoReconFileType.lookupItems;
                self.itemTypeName = _.get(self.$route.meta, "itemTypeName");
                self.itemTypeNamePlural = _.get(self.$route.meta, "label");
                self.itemKey = _.get(self.$route.meta, "itemKey");
                self.selectionActions = [
                        { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}`, disabled: function(e) { return self.readOnly; }}
                    ];
            },

            isUniqueItem(row){
                const self = this;
                let dup = {};
                return _.isEmpty(dup);
            },

            onAddItem() {
                this.gridInstance.clearSelection();
                this.gridInstance.addRow();
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, self.itemKey);

                    let apiPromise = self.$api.AutoReconMappingsApi.delete(toBeDeletedKeys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(keys => {
                            _.pullAllBy(self.items, items, self.itemKey);
                            let message = keys.length > 1 ? `${keys.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onGridInsert(values) {
                const self = this;
                let newItem = new AutoReconMappingsDto(values);
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                self.save(newItem, changes)
                    .then(item => {
                        self.items.push(new AutoReconMappingsDto(item));
                        self.$toast.success({ message: `${self.itemTypeName} ${item.description} was saved.` });
                    })
                    .catch(err => {
                        self.validationErrors = [err.errorMessage];
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => _.parseNumber(_.get(item, self.itemKey), -1) === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new AutoReconMappingsDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());
                self.save(updatedItem, changes)
                    .then(item => {
                        _.assign(self.items[itemIndex], item);
                        self.$toast.success({ message: `${self.itemTypeName} ${item.description} was saved.` });
                    })
                    .catch(err => {
                        self.validationErrors = [err.errorMessage];
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onReset(e) {
                this.fetchData();
            },

            refresh() {
                if (!_.isEmpty(this.gridInstance)) {
                    this.gridInstance.option("focusedRowIndex", -1);
                    this.gridInstance.clearSelection();
                    this.gridInstance.refresh();
                }
            },

            save(item, changes){
                const self = this;
                self.validationErrors = [];
                if (changes.length == 0) {
                    self.$toast.info({ message: "No changes detected" });
                    return Promise.resolve(item);
                }
                let apiPromise = self.$api.AutoReconMappingsApi.save(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
            },
        }
    }
</script>

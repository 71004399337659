<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            @edit="onEditItem"
            @delete="onDeleteItem"
            @rowDoubleClick="onEditItem"
            integrated-search
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { PropertyLayoutDto }  from "../models";
    import PropertyLayoutForm  from "./PropertyLayoutForm.vue";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"PropertyLayoutList",
        props: { },
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                verifiedItem: {},
                addEventName: ""
            };
        },
        created(){
            this.initGridConfig();
            this.initListeners();
        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            selectionActions() {
                return [{ name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: `Edit ${this.itemTypeName}` },
                        { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` }];
            },
        },
        methods: {
            addNewItem() {
                const self = this;
                self.gridInstance.deselectAll();
                self.selectedItem = new PropertyLayoutDto({regionID: self.user.regionID});
                self.loadItem(new PropertyLayoutDto({regionID: self.user.regionID}));
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            onAddItem() {
                this.addNewItem();
            },

            onEditItem(e) {
                const self = this;
                self.selectedItem = new PropertyLayoutDto(e.data);
                self.loadItem(new PropertyLayoutDto(e.data));
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let apiPromises = [];
                    _.forEach(items, (item) => {
                        let key = _.get(item, self.itemKey);
                        apiPromises.push(self.$api.PropertyLayoutsApi.deletePropertyLayout(key));
                    });
                    return self.$rqBusy.wait(Promise.all(apiPromises))
                        .then(key => {
                            self.deleteItem([...key]);
                            let message = key.length > 1 ? `${key.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;}
                        )
                        .catch(error => {
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {dataField: "propertyLayoutID", visible: false, showInColumnChooser: false,},
                        {dataField: "regionDisplay", caption: "Region", calculateSortValue: DxGridUtils.regionDisplaySortValue},
                        {dataField: "description", sortIndex: 0, sortOrder: "asc"},
                        {dataField: "briefLegal"},
                        {dataField: "hideAcreage", dataType: "boolean", visible: false},
                        {dataField: "hideARBNum", dataType: "boolean", visible: false},
                        {dataField: "hideBlock", dataType: "boolean", visible: false},
                        {dataField: "hideBook", dataType: "boolean", visible: false},
                        {dataField: "hideBuilding", dataType: "boolean", visible: false},
                        {dataField: "hideDistrict", dataType: "boolean", visible: false},
                        {dataField: "hideInstrNum", dataType: "boolean", visible: false},
                        {dataField: "hideLot", dataType: "boolean", visible: false},
                        {dataField: "hideMunicipality", dataType: "boolean", visible: false},
                        {dataField: "hideNumber", dataType: "boolean", visible: false},
                        {dataField: "hideOutLot", dataType: "boolean", visible: false},
                        {dataField: "hidePage", dataType: "boolean", visible: false},
                        {dataField: "hideParcel", dataType: "boolean", visible: false},
                        {dataField: "hidePart", dataType: "boolean", visible: false},
                        {dataField: "hidePhase", dataType: "boolean", visible: false},
                        {dataField: "hidePlatname", dataType: "boolean", visible: false},
                        {dataField: "hideQtr1", dataType: "boolean", visible: false},
                        {dataField: "hideQtr2", dataType: "boolean", visible: false},
                        {dataField: "hideQtr3", dataType: "boolean", visible: false},
                        {dataField: "hideRange", dataType: "boolean", visible: false},
                        {dataField: "hideSection", dataType: "boolean", visible: false},
                        {dataField: "hideSectionGroup", dataType: "boolean", visible: false},
                        {dataField: "hideSubDivCode", dataType: "boolean", visible: false},
                        {dataField: "hideTownship", dataType: "boolean", visible: false},
                        {dataField: "hideTract", dataType: "boolean", visible: false},
                        {dataField: "hideUnit", dataType: "boolean", visible: false},
                        {dataField: "hideGarage", dataType: "boolean", visible: false},
                        {dataField: "hideCarport", dataType: "boolean", visible: false},
                        {dataField: "hideBoatSlip", dataType: "boolean", visible: false},
                        {dataField: "hideStorage", dataType: "boolean", visible: false},
                        {dataField: "hideRecordOfSurvey", dataType: "boolean", visible: false},
                        {dataField: "hideMaps", dataType: "boolean", visible: false},
                        {dataField: "hideIncorporated", dataType: "boolean", visible: false},
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load: self.fetchData,
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.PropertyLayoutsApi.getPropertyLayouts();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let items = _.map(result, i => new PropertyLayoutDto(i));
                        self.items = items;
                        return { data: items, totalCount: items.length };
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            loadItem(item) {
                const self = this;
                let onOk = (e, addAnother=false) => {
                    let form = e.component;
                    let item = form.item;
                    form.v$.$touch();
                    self.validationErrors = [];
                    self.verifiedItem = {};
                    if (!item.isValid || form.v$.$error) {
                        form.validationErrors = _.concat(item.validationErrors, self.validationErrors);
                        form.verifiedItem = _.merge(item.verifiedItem, self.verifiedItem);
                        return false;
                    }
                    self.save(item, form, addAnother);
                };
                let onCancel = e => {
                    self.refresh();
                };
                self.$dialog.open({
                    title: `${(item.isNew ? "Add": "Edit")} ${self.itemTypeName}${(item.isNew ? "" : `: ${item.description}`)}`,
                    width: "900",
                    height: "85%",
                    resizable: false,
                    scrollable: true,
                    adaptive: false,
                    component: PropertyLayoutForm,
                    closeOnEsc: true,
                    props: {
                        item: item,
                        itemTypeName: self.itemTypeName,
                        uniqueValidator: (item) => !self.isDuplicate(item),
                    },
                    buttons: [
                        { title: "Cancel", automationId: "btn_dm_modal_cancel", cssClass: "btn btn-secondary", closeOnComplete: true, onClick: onCancel },
                        { title: "Save & Add Another", automationId: "btn_dm_modal_save_and_another", cssClass: "btn btn-primary", isVisible: item.isNew , closeOnComplete: false, onClick: (e) => { onOk(e, true); } },
                        { title: "Save", automationId: "btn_dm_modal_save", cssClass: "btn btn-primary", closeOnComplete: false, onClick:  (e) => { onOk(e, false); } }
                    ],
                });

            },

            save(item, form, addAnother){
                const self = this;
                let original = self.selectedItem;
                let changes = self.getAuditChanges(original.toDataObject(), item.toDataObject());
                if (changes.length == 0) {
                    self.$toast.info({ message: "No changes detected" });
                    return;
                }
                let isNew = item.isNew ? true : false;
                let apiPromise = self.$api.PropertyLayoutsApi.savePropertyLayout(item.toDataObject(), changes);
                self.$rqBusy.wait(apiPromise)
                            .then(item => {
                                if (isNew) {
                                    self.addItem(item);
                                } else {
                                    self.updateItem(item);
                                }
                                self.$toast.success({ message: `${self.itemTypeName} ${item.description} was saved.` });
                                self.$dialog.close();
                                if (addAnother) self.addNewItem();
                            })
                            .catch(err => {
                                form.validationErrors = [err.errorMessage];
                            })
                            .finally(() => {
                                self.refresh();
                            });
            },

            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            addItem(item) {
                this.items.push(new PropertyLayoutDto(item));
            },

            deleteItem(keys) {
                _.each(keys, k => {
                    _.remove(this.items, (i) => {return _.parseNumber(_.get(i, this.itemKey, -1), -1) == k;});
                });
            },

            updateItem(item) {
                let editIem = _.find(this.items, (i) => {
                    return _.parseNumber(_.get(i, this.itemKey, -1), -1) == _.parseNumber(_.get(item, this.itemKey, -1), -1);
                });
                _.assign(editIem, item);
            },

            isDuplicate(item){
                const self = this;
                let dup = {};
                dup = _.find(self.items, (i) => {
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(item.description)) && _.parseNumber(item.regionID, -1) === _.parseNumber(i.regionID, -1) && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(item, self.itemKey, -1), -1);
                });
                if (dup) {
                    self.validationErrors.push(`${self.itemTypeName} Description [${dup.description}] for Region [${dup.regionDisplay}] already exists.`);
                    return true;
                }
                return false;
            }
        }
    }
</script>

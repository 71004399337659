<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" header-only borderless>
             <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-button
                            :automation_id="elementName('btn_add', this.itemTypeName)"
                            :ref="elementName('btn_add', this.itemTypeName)"
                            variant="theme"
                            @click="onAddItem"
                            v-rq-tooltip.hover.top="{ title: `Add ${this.itemTypeName}` }"
                            >
                            Add
                        </b-button>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            :strikethrough-if-true="['isInactiveYN']"
            target-inactive-column="isInactiveYN"
            @rowDoubleClick="onEditItem"
            @activate="onActivateItem($event, 'Activate')"
            @inactivate="onActivateItem($event, 'Inactivate')"
            @edit="onEditItem"
            @copy="onCopyItem"
            @delete="onDeleteItem"
            @contentReady="onGridContentReady"
            rq-filters
            integrated-search
            show-include-inactive
        />
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { EndorsementShortDto, EndorsementDataDto }  from "../models";
    import { EndorsementDto } from "@settlement/models";
    import EndorsementFormAdd from "./EndorsementFormAdd.vue";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { SearchRequest, SortOption, LookupItem } from '@/shared/models/models';
    import { BreakoutEndorsementOption, EndorsementPolicy } from "@settlement/models/enums";
    import { ClosingParties }  from "../../enums";

    export default {
        name:"EndorsementsList",
        props: { },
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                addEventName: "",
                gridReady: false,

            };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null) || {}; },
            itemKey() { return "endorsementID";},
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            selectionActions() {
                return [{ name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: `Edit ${this.itemTypeName}`, disabled: function(e) { return _.some(e.data, ['canEdit', false]);} },
                        { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}`, disabled: function(e) { return _.some(e.data, ['canDelete', false]);} },
                        { name: "copy", text: "Copy", eventName: "copy", requireSelection: true, tooltip: `Copy ${this.itemTypeName}`, disabled: function(e) { return _.some(e.data, ['canEdit', false]);} },
                        { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return _.some(e.data, ['isInactiveYN', false]); } },
                        { name: "inactivate", text: "Inactivate", eventName: "inactivate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return _.some(e.data, ['isInactiveYN', true]); } }];
            },
            endorsementPolicyTypes() { return _.map(EndorsementPolicy.lookupItems,"name")},
            closingParties() { return _.filter( ClosingParties.lookupItems, i => {return i.id === 1 || i.id === 2}); },
            accountingCodes() { return this.lookupHelpers.getLookupItems(this.lookupItems.ACCOUNTING_CODES); },
        },
        created(){
            this.fetchData();
            this.initGridConfig();
            this.initListeners();
        },
        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        methods: {
            activate(keys, verb) {
                const self = this;
                let apiPromise = self.$api.EndorsementsApi.activate(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.toggleInactiveFlag(keys);
                        self.refreshGrid();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            onActivateItem(e, verb) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.activate(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onAddItem() {
                this.clear();
                this.showAddItem();
            },

            onCopyItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let item = e.data;
                let ok = function (args) {
                    self.copyItem(new EndorsementShortDto(item));
                }

                self.$dialog.confirm(`Confirm Copy`, `Are you sure you want to copy the selected ${self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onEditItem(e) {
                this.clear();
                this.$router.push({ name: 'cfg:sscalc:endorsementsandfee', params: { endorsementID: e.data.endorsementID }});
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = items.map(item => _.get(item, self.itemKey));

                    let apiPromise = self.$api.EndorsementsApi.delete(toBeDeletedKeys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(keys => {
                            self.deleteItem(keys);
                            let message = keys.length > 1 ? `${keys.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.clear();
                            self.refreshGrid();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onGridContentReady (e) {
                const self = this;
                self.gridReady = true;
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            showInColumnChooser: false
                        },
                        {
                            dataField: "code",
                            sortIndex: 0,
                            sortOrder: "asc"
                        },
                        {
                            dataField: "rateRuleReference",
                            caption: "Rate Rule Ref"
                        },
                        { dataField: "description" },
                        {
                            dataField: "endorsementPolicyTypeDisplay",
                            caption: "Type",
                            rqFilter: {
                                filterType: "tags",
                                dataSource: {
                                    loadMode: "raw",
                                    load() {
                                        return self.endorsementPolicyTypes;
                                    }
                                }
                            }
                        },
                        {
                            dataField: "state",
                            lookup: {
                                valueExpr: "id",
                                displayExpr: "id",
                                dataSource: self.lookupHelpers.getStates()
                            }
                        },
                        {
                            dataField: "isInactiveYN",
                            caption: "Inactive",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        },
                        {
                            dataField: "lastModifiedBy"
                        },
                        {
                            dataField: "policyCode",
                            visible: false
                        },
                        {
                            dataField: "filingCode",
                            visible: false
                        },
                        {
                            dataField: "endorsementHasDocumentTemplates",
                            caption: "Docs",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            visible: false
                        },
                        {
                            dataField: "accountingCodeID",
                            caption: "Acct Code",
                            visible: false,
                            lookup: {
                                dataSource: self.accountingCodes,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                        },
                        {
                            dataField: "whoPays",
                            caption: "Who Pays",
                            visible: false,
                            lookup: {
                                dataSource: self.closingParties,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                        },
                        {
                            dataField: "payPercent",
                            dataType: "number",
                            caption: "Buyer %",
                            format: {
                                type: "fixedPoint",
                                precision: 3
                            },
                            visible: false
                        },
                        {
                            dataField: "breakoutOverride",
                            caption: "Break Out O/R",
                            visible: false,
                            lookup: {
                                dataSource: BreakoutEndorsementOption.lookupItems,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                        },
                        {
                            dataField: 'lastModified',
                            dataType: 'datetime',
                            caption: 'Modified Date',
                            visible: false,
                        },
                    ],
                    paging: { enabled: true, pageIndex: 0, pageSize: 50 },
                };

                self.gridDataSource = {
                    loadMode: "raw",
                    key: self.itemKey,
                    load () {
                        return Promise.resolve(self.items);
                    }
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            copyItem(item) {
                const self = this;
                let apiPromise = self.$api.EndorsementsApi.copy(item);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$router.push({ name: 'cfg:sscalc:endorsementsandfee', params: { endorsementID: result.endorsementID }});
                        return true;
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error Copying ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },


            fetchData(loadOptions){
                const self = this;
                let promise = self.$api.EndorsementsApi.getAll();
                self.$rqBusy.wait(promise)
                    .then(response => {
                        self.items = response;
                        self.refreshGrid();
                    })
                    .catch(error => {
                        self.$toast.error("Search Failed.");
                        console.error("Search Failed.", error);
                    });
            },

            clear() {
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            refreshGrid() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            showAddItem() {
                const self = this;
                let dialogResult = null;
                self.$dialog.open({
                    title: `Add ${self.itemTypeName}`,
                    width: 650,
                    adaptive: true,
                    component: EndorsementFormAdd,
                    okTitle: "Save & Edit",
                    onOk: e => e.component.save()
                        .then(result => {
                            if(!result.isValid) return false;
                            dialogResult = result;
                            return true;
                        }),
                    onClosed: e => {
                        if(_.isNil(dialogResult) || e.cancelled) return;
                        self.onEditItem(dialogResult);
                    }
                });
            },

            toggleInactiveFlag(keys) {
                _.each(keys, k => {
                    let e = _.find(this.items, [this.itemKey, k]);
                    e.isInactiveYN = !e.isInactiveYN;
                });
            },

            deleteItem(keys) {
                _.each(keys, k => {
                    _.remove(this.items, (i) => {return _.parseNumber(_.get(i, this.itemKey, -1), -1) == k;});
                });
            },

            updateItem(item) {
                let editIem = _.find(this.items, (i) => {
                    return _.parseNumber(_.get(i, this.itemKey, -1), -1) == _.parseNumber(_.get(item, this.itemKey, -1), -1);
                });
                _.assign(editIem, item);
            },
        }
    }
</script>

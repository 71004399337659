<template>
    <div class="content-wrapper">
    <rq-page-section borderless>
        <template #header-actions>
            <ul class="nav ms-auto">
                <li class="nav-item">
                    <b-btn
                        automation_id="btn_search_clear"
                        variant="theme"
                        @click="onReset">Clear
                    </b-btn>
                </li>
                <li class="nav-item">
                    <b-btn
                        automation_id="btn_search_checks"
                        variant="theme"
                        @click="onLoadGrid">Search
                    </b-btn>
                </li>
            </ul>
        </template>
        <div class="row">
            <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group">
                <label for="drp_region">Region</label>
                <dx-tag-box
                    :element-attr="{ automation_id: 'tag_region', id: 'tag_region' }"
                    class="form-control"
                    :data-source="regions"
                    display-expr="displayName"
                    value-expr="regionID"
                    :show-selection-controls="true"
                    :show-clear-button="true"
                    :max-displayed-tags="2"
                    :show-multi-tag-only="false"
                    :show-drop-down-button="true"
                    apply-value-mode="useButtons"
                    v-model:value="selectedRegions"
                    :search-enabled="true"
                />
            </div>
            <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group">
                <label for="drp_region">State</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'dro_state', id: 'drp_state', ref: 'drp_state' }"
                    :items="usStates"
                    :showClearValueIcon="true"
                    v-model="selectedStateId"
                    value-expr="id"
                    display-expr="id"
                    :search-enabled="true"
                />
            </div>
            <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group">
                <label for="drp_county">County</label>
                <dx-tag-box
                    :element-attr="{ automation_id: 'tag_county', id: 'tag_county' }"
                    class="form-control"
                    :data-source="counties"
                    display-expr="name"
                    value-expr="id"
                    :show-selection-controls="true"
                    :show-clear-button="true"
                    :max-displayed-tags="2"
                    :show-multi-tag-only="false"
                    :show-drop-down-button="true"
                    apply-value-mode="useButtons"
                    v-model:value="selectedCounties"
                    :disabled="!shouldDisable"
                    ref="cmb_county"
                    :search-enabled="true"/>
            </div>
            <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group">
                <label for="drp_Underwriter">Underwriter</label>
                <dx-tag-box
                    :element-attr="{ automation_id: 'tag_underwriter', id: 'tag_underwriter' }"
                    class="form-control"
                    :data-source="underwriters"
                    display-expr="name"
                    value-expr="id"
                    :show-selection-controls="true"
                    :show-clear-button="true"
                    :max-displayed-tags="2"
                    :show-multi-tag-only="false"
                    :show-drop-down-button="true"
                    apply-value-mode="useButtons"
                    v-model:value="selectedUnderwriters"
                    ref="cmb_Underwriter"
                    :search-enabled="true"/>

            </div>
        </div>
        <div class="row">

            <div class="col col-3 col-sm-6 col-md-3 col-lg-3 form-group">
                <label for="drp_Code">Premium Code</label>
                <dx-tag-box
                    :element-attr="{ automation_id: 'tag_premiumcode', id: 'tag_premiumcode' }"
                    class="form-control"
                    :data-source="codes"
                    display-expr="name"
                    value-expr="id"
                    :show-selection-controls="true"
                    :show-clear-button="true"
                    :max-displayed-tags="2"
                    :show-multi-tag-only="false"
                    :show-drop-down-button="true"
                    apply-value-mode="useButtons"
                    v-model:value="selectedPremiumCodes"
                    ref="cmb_Code"
                    :search-enabled="true"/>
            </div>
        </div>
    </rq-page-section>
    <rqdx-action-data-grid
        ref="dataGrid"
        :automation_id="elementName('tbl')"
        :actions="selectionActions"
        :config="gridConfig"
        :data-source="gridDataSource"
        :export-file-name="elementName('', 'data')"
        @export="onExportItems"
        hide-search
    >
    </rqdx-action-data-grid>

    </div>

</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { RateExportDto }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import DxTagBox from "devextreme-vue/tag-box";


    export default {
        name:"ExportRateList",
        components: {
            DxTagBox,
        },
        props: { },
        data () {
            return {
                validationErrors: [],
                selectedType:  "rqr",
                items: [],
                originalData: {},
                originalItems: [],
                selectedItem: {},

                selectedTypeDisplay: "Rates",
                selectedRegions: [],
                selectedState: "",
                selectedCounties: [],
                selectedUnderwriters: [],
                selectedPremiumCodes: [],
                selectedStateId: 0,

                shouldDisable: false,
                codes: [],
                policyCodes:[],

                gridDataSource: {},
                gridConfig: {},
                xml:{},
            };
        },
        created(){
            const self = this;
            self.fetchCodes();
            self.fetchData();
            self.initGridConfig();
        },
        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            regions() { return this.lookupHelpers.getRegions(); },
            itemKey() { return "rateID"; },
            underwriters() {return this.lookupHelpers.getUnderwriters(_.get(this.$store.state, 'system.globalRegionId', 0)); },
            usStates() { return this.lookupHelpers.getStates(); },
            counties(){ return this.lookupHelpers.getCountiesByState(this.selectedState);},
            comboboxUSState(){ return _.get(this, "$refs.cmb_state", null) || {}; },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            selectionActions() {
                return [{
                    name: "export",
                    text: `Create Export`,
                    eventName: "export",
                    requireSelection: true,
                    tooltip: `Create Export`,
                    allowMultiSelection: true }];
            },
        },
        watch:{

            "selectedStateId":
            {
                handler: function(newVal, oldValue) {
                    const self = this;
                    self.shouldDisable = (newVal !== oldValue) ;
                    if(self.shouldDisable)
                        self.selectedState = newVal;
                }
            }
        },
        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            onLoadGrid()
            {
                const self = this;
                //going to requery incase rates were added
                let apiPromise = self.$api.ExportApi.getRatetDisplay();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let items = _.map(result, i => new RateExportDto(i));
                        let filteredItems = [];
                        if(self.selectedRegions.length>0)
                        {
                            filteredItems = [];
                            for(let y = 0; y< items.length; y++)
                            {
                                let regionIds = _.split(items[y].regionIDs,",");
                                if(regionIds.length> 0)
                                {
                                    for(let x= 0; x < self.selectedRegions.length; x++)
                                    {
                                        let findMatch = _.find(regionIds, i => i===`${self.selectedRegions[x]}`);

                                        if(findMatch != null)
                                        {
                                            filteredItems.push(items[y]);
                                            break;
                                        }
                                    }
                                }
                            }
                            items = filteredItems;
                        }
                        if(self.selectedState != "")
                        {
                            filteredItems = _.filter(items, i => i.state === self.selectedState);
                            items = filteredItems;
                        }
                        if(self.selectedCounties.length>0)
                        {
                            filteredItems = [];
                            for(let y = 0; y< items.length; y++)
                            {
                                let countyIds = _.split(items[y].countyIDs,",");
                                if(countyIds.length> 0)
                                {
                                    for(let x= 0; x < self.selectedCounties.length; x++)
                                    {
                                        let findMatch = _.find(countyIds, i => i===`${self.selectedCounties[x]}`);

                                        if(findMatch != null)
                                        {
                                            filteredItems.push(items[y]);
                                            break;
                                        }
                                    }
                                }
                            }
                            items = filteredItems;
                        }
                        if(self.selectedUnderwriters.length>0)
                        {
                            filteredItems = [];
                            for(let y = 0; y< items.length; y++)
                            {
                                let underwriterIds = _.split(items[y].underwriterIDs,",");
                                if(underwriterIds.length> 0)
                                {
                                    for(let x= 0; x < self.selectedUnderwriters.length; x++)
                                    {
                                        let findMatch = _.find(underwriterIds, i => i===`${self.selectedUnderwriters[x]}`);

                                        if(findMatch != null)
                                        {
                                            filteredItems.push(items[y]);
                                            break;
                                        }
                                    }
                                }
                            }
                            items = filteredItems;
                        }
                        if(self.selectedPremiumCodes.length>0)
                        {
                            filteredItems = [];
                            for(let y = 0; y< items.length; y++)
                            {
                                for(let x= 0; x < self.selectedPremiumCodes.length; x++)
                                {
                                    let findMatch = items[y].premiumCode === self.codes[self.selectedPremiumCodes[x]].name;

                                    if(findMatch)
                                    {
                                        filteredItems.push(items[y]);
                                        break;
                                    }
                                }
                            }
                            items = filteredItems;
                        }

                        self.items = items;
                        self.gridInstance.refresh();
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Rates for Export.` });
                        return error;
                    });
            },
            onReset()
            {
                const self = this;
                self.selectedState = "";
                self.selectedStateId = 0;
                self.selectedRegions =[];
                self.selectedCounties =[];
                self.selectedUnderwriters =[];
                self.selectedPremiumCodes =[];
                self.selectedState = "";
                this.fetchData();
            },
            onExportItems(e) {
                const self = this;
                let items = e.data; //selected
                let passedData = "";
                for(let x = 0; x < items.length; x++)
                {
                    passedData += items[x].rateID + ",";
                }
                let apiPromise = self.$api.ExportApi.fetchRate(passedData);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.xml = result;
                        self.download(`RateExport.${self.selectedType}`);
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error creating XML for Export.` });
                        return error;
                    });
            },
            download( filename) {
                const self = this;
                var file = new Blob([this.xml], {type: self.selectedType});
                if (window.navigator.msSaveOrOpenBlob) // IE10+
                    window.navigator.msSaveOrOpenBlob(file, filename);
                else { // Others
                    var a = document.createElement("a"),
                            url = URL.createObjectURL(file);
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function() {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 0);
                }
            },
            fetchCodes()
            {
                const self = this;
                let apiPromise = self.$api.ExportApi.getPremium();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        for(let i = 0; i < result.length; i++)
                        {
                            self.codes.push({ id: i, name: result[i] });
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Premium Codes.` });
                        return error;
                    });

            },

            fetchData () {
                const self = this;

                let apiPromise = self.$api.ExportApi.getRatetDisplay();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let items = _.map(result, i => new RateExportDto(i));
                        self.items = items;
                        self.gridInstance.refresh();
                        //return { data: self.items, totalCount: items.length };
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Rates for Export.` });
                        return error;
                    });
            },
            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            showInColumnChooser: false,
                        },
                        {
                            dataField: "premiumCode", sortIndex: 0, sortOrder: "asc"
                        },
                        {
                            dataField: "description"
                        },
                        {
                            dataField: "regions",
                            caption: "Region(s)" ,
                            cellTemplate: function(cellElement, cellInfo) {
                                let regiondisplay = cellInfo.data.regions;
                                let regionCounterdisplay = cellInfo.data.regionsSelected;
                                if(_.isEmpty(cellInfo.value) || _.isNil(regiondisplay)) return;
                                let displayText = `${regionCounterdisplay} Regions Selected`;
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title",displayText)
                                    .append(regiondisplay)
                                    .appendTo(cellElement);
                            },
                        },
                        {
                            dataField: "underwriters",
                            caption: "Underwriter(s)",
                            cellTemplate: function(cellElement, cellInfo) {
                                let underWriterdisplay = cellInfo.data.underwriters;
                                let underWriterCounterdisplay = cellInfo.data.underwritersSelected;
                                if(_.isEmpty(cellInfo.value) || _.isNil(underWriterdisplay)) return;
                                let displayText = `${underWriterCounterdisplay} Underwriters Selected`;
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title",displayText)
                                    .append(underWriterdisplay)
                                    .appendTo(cellElement);
                            },
                        },
                        {
                            dataField: "state"
                        },
                        {
                            dataField: "counties",
                            caption: "County\\Counties",
                            cellTemplate: function(cellElement, cellInfo) {
                                let countydisplay = cellInfo.data.counties;
                                let countyCounterdisplay = cellInfo.data.countiesSelected;
                                if(_.isEmpty(cellInfo.value) || _.isNil(countydisplay)) return;
                                let displayText = `${countyCounterdisplay} Counties Selected`;
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title",displayText)
                                    .append(countydisplay)
                                    .appendTo(cellElement);
                            },
                        },
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },

                };
            },
        }

    };
</script>

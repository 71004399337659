<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" borderless header-only>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
        </rq-page-section>
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            :strikethrough-if-false="['isEnabled']"
            @activate="onActivateItem"
            @edit="onEditItem"
            @rowDoubleClick="onEditItem"
            rq-filters
            integrated-search
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { EmailNotificationDto }  from "../models";
    import EmailNotificationForm  from "./EmailNotificationForm.vue";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"EmailNotificationList",
        props: { },
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                regionID: -1,
                mergeFields: []
            };
        },
        created(){
            this.initGridConfig();
            this.fetchMergeFields();
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "regionsLookup"
            ]),
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null) || {}; },
            itemKey() { return "notificationEventID" },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            selectionActions() {
                return [{ name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: `Edit ${this.itemTypeName}` },
                        { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['isEnabled', false]); } },
                        { name: "inactivate", text: "Inactivate", eventName: "activate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['isEnabled', true]); }  }
                ];
            },
        },
        methods: {
            activate(keys, verb) {
                const self = this;
                let apiPromise = self.$api.NotificationApi.activateNotifications(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.refresh();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            onActivateItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                let verb = _.every(items, ['isEnabled', false]) ? "Activate" : "Inactivate";

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.activate(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onEditItem(e) {
                const self = this;
                self.selectedItem = new EmailNotificationDto(e.data);
                self.loadItem(new EmailNotificationDto(e.data));
            },

            onRegionChange(e) {
                if (e.selectedValue != null && e.selectedValue > 0) {
                    this.gridInstance.filter("regionID", e.selectedValue)
                }
                else {
                    this.gridInstance.clearFilter();
                }
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "notificationEventID",
                            visible: false,
                            showInColumnChooser: false,
                        },
                        {
                            // dataField: "regionDisplay",
                            dataField: "regionID",
                            caption: "Region",
                            calculateSortValue: "regionDisplay",
                            lookup: {
                                dataSource: self.regionsLookup,
                                displayExpr: "displayName",
                                valueExpr: "regionID",
                            },
                            groupIndex: -1
                        },
                        {
                            dataField: "name"
                        },
                        {
                            dataField: "subjectLine",
                            caption: "Subject"
                        },

                        {
                            dataField: "templateTypeDisplay",
                            caption: "Template Type"
                        },
                        {
                            dataField: "isEnabled",
                            dataType: "boolean",
                            caption: "Enabled?", cellTemplate: DxGridUtils.boolCellTemplate
                        },
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load: self.fetchData,
                };
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.NotificationApi.getNotifications();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let items = _.map(result, i => new EmailNotificationDto(i));
                        self.items = items;
                        return { data: items, totalCount: items.length };
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            fetchMergeFields(){
                const self = this;
                let apiPromise = self.$api.NotificationApi.getMergeFields();
                return self.$rqBusy.wait(apiPromise)
                    .then(results => {
                        let fields = _.map(results, f => { return { "key": f.fieldName, "label": f.displayName, "value": `[MF:${f.fieldName}]` };});
                        self.mergeFields = fields;
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            loadItem(item) {
                const self = this;
                let onOk = e => {
                    let form = e.component;
                    let item = form.item;
                    item.template = item.templateValue;
                    self.validationErrors = [];
                    if (!item.isValid || self.isDuplicate(item)) {
                        form.validationErrors = _.concat(item.validationErrors, self.validationErrors);
                        return false;
                    }
                    self.save(item, form);
                };
                let onCancel = e => {
                    self.refresh();
                };
                self.$dialog.open({
                    title: `Edit ${self.itemTypeName}: ${(item.name)}`,
                    width: "1000",
                    height: "auto",
                    resizable: false,
                    scrollable: false,
                    adaptive: true,
                    component: EmailNotificationForm,
                    closeOnEsc: true,
                    props: {
                        item: item,
                        itemTypeName: self.itemTypeName,
                        mergeFields: self.mergeFields
                    },
                    okTitle: "Save",
                    onOk,
                    onCancel
                });
            },

            save(item, form){
                const self = this;
                let original = self.selectedItem;
                let changes = self.getAuditChanges(original.toDataObject(), item.toDataObject());
                if (changes.length == 0) {
                    self.$toast.info({ message: "No changes detected" });
                    return;
                }
                let isNew = item.isNew ? true : false;
                let apiPromise = self.$api.NotificationApi.saveNotification(item.toDataObject(), changes);
                self.$rqBusy.wait(apiPromise)
                            .then(item => {
                                self.updateItem(item);
                                self.$toast.success({ message: `${self.itemTypeName} ${item.description} was saved.` });
                                self.$dialog.close();
                            })
                            .catch(err => {
                                form.validationErrors = [err.errorMessage];
                            })
                            .finally(() => {
                                self.refresh();
                            });
            },

            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            updateItem(item) {
                let editIem = _.find(this.items, (i) => {
                    return _.parseNumber(_.get(i, this.itemKey, -1), -1) == _.parseNumber(_.get(item, this.itemKey, -1), -1);
                });
                _.assign(editIem, item);
            },

            isDuplicate(item){
                const self = this;
                let dup = {};
                dup = _.find(self.items, (i) => {
                    return _.toLower(_.trim(i.name)) === _.toLower(_.trim(item.name)) && _.parseNumber(item.regionID, -1) === _.parseNumber(i.regionID, -1) && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(item, self.itemKey, -1), -1);
                });
                if (dup) {
                    self.validationErrors.push(`${self.itemTypeName} Name [${dup.name}] for Region [${dup.regionDisplay}] already exists.`);
                    return true;
                }
                return false;
            }
        }
    }
</script>
<template>
    <rqdx-action-data-grid
        ref="packageDataGrid"
        :automation_id="elementName('tbl')"
        :actions="selectionActions"
        :config="gridConfig"
        :data-source="gridDataSource"
        :export-file-name="elementName('', 'data')"
        v-model:validation-errors="validationErrors"
        @delete="onDeleteItem"
        @edit="onEditItem"
        @reset="onReset"
        @edit-users="onEditUsers"        
        hide-search
        rq-filters
    />
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { DocumentPackageDto, DocumentTemplateDto, DocumentPackageSecurityUserDto }  from "../models";
    import { DefaultUserSecurity } from '../../enums';
    import DocumentPackageUserSecurityForm from './DocumentPackageUserSecurityForm.vue';
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import DocumentPackageForm  from "./DocumentPackageForm.vue";


    export default {
        name:"DocumentPackageList",
        components: {  },
        props: {
            documentTemplates: {},
            isValid: { type: Boolean, default: true },
            userGroups: { type: Array, default: () => [] }
        },
        data () {
            return {                
                itemKey: "documentPackageID",
                itemTypeName: "Document Package",
                itemTypeNamePlural: "Document Packages",
                items: [],
                selectedItem: {},
                errors: [],
                validationErrors: [],
                verifiedItem:{},
                addEventName: "",
                availableList: [],
                selectedList: [],
                listOptions: {}
            };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.packageDataGrid.gridInstance", null) || {}; },
            selectionActions() {
                return [{ name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: "Edit Document Template" },
                        { name: "edit-users", text: "Edit User Security", eventName: "edit-users", requireSelection: true, tooltip: `Edit User Security for  ${this.itemTypeName}` },
                        { name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` }];
            },
        },

        watch: {
            validationErrors(newValue) {
                this.$emit("update:isValid", _.isEmpty(newValue));
            }
        },

        created(){
            const self = this;
            self.initGridConfig();
            self.initListeners();
            self.fetchData();
            self.listOptions = {
                showSequenceAction: false,
                showExtraAction: false,
                availableListName: 'Available Document Templates',
                selectedListName: 'Selected Document Templates',
            };

        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        methods: {         

            onEditUsers(e){
                const self = this;
                self.loadItemUserSecurity(e.data); 
            },

            onTabActivated(e) {
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            initGridConfig(){
                const self = this;

                self.gridConfig = {
                    columns: [
                        { dataField: "DocumentPackageID", visible: false, allowEditing: false, showInColumnChooser: false, },
                        {
                            dataField: "regionID", caption: "Region",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            lookup: {
                            dataSource: self.lookupHelpers.getRegions(),
                            displayExpr: "displayName",
                            valueExpr: "regionID"
                            }, validationRules: [{ type: "required" }]
                        },
                        {
                            dataField: "description",
                            caption: "Name",
                             validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isNotDuplicateName,
                                    message: "Name for Region already exists"
                                }
                            ]
                        },
                        {
                            dataField: "defaultUserSecurityAccess",
                            caption: "Default User Security",
                            calculateSortValue: "defaultUserSecurityAccessText",
                            lookup: {
                                dataSource: DefaultUserSecurity.lookupItems,
                                valueExpr: "id",
                                displayExpr: "name"
                            }
                        },
                        {
                            dataField: "userSecurityStatusText",
                            caption: "User Sec Exceptions",
                            allowEditing: false,
                            calculateCellValue: function(data) {return data.userSecurityStatus===1;},
                            cellTemplate: function(cellElement, cellInfo) {
                                cellElement.text(cellInfo.value ? "Yes" : "No" );
                            }
                        },



                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],
                    onRowUpdating(e) {
                        e.cancel = new Promise((resolve, reject) => {
                            self.$dialog.confirm(
                                "Confirm Edit",
                                `Updating ${self.itemTypeNamePlural} will change existing files. Do you want to continue?`,
                                () => resolve(false), //continue with edit
                                () => resolve(true), //cancel edit
                                { cancelTitle: 'No', okTitle: 'Yes'});
                        });
                    }
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.DocumentPackagesApi.getList();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new DocumentPackageDto(i));
                        self.refresh();
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            onAddItem() {
                const self = this;
                self.loadItem(new DocumentPackageDto());
            },

            onEditItem(e) {
                const self = this;
                self.loadItem(new DocumentPackageDto(e.data));
            },

            onReset(e) {
                this.fetchData();
            },


            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                     let itemsToDelete = _.map(items, r => ({ ...r, isDeleted: true }));
                     let apiPromise = self.$api.DocumentPackagesApi.save(itemsToDelete);
                     return self.$rqBusy.wait(apiPromise)
                        .then(results => {
                            self.items = _.map(results, i => new DocumentPackageDto(i));
                            let message = items.length > 1 ? `${items.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });

                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            loadItem(item) {
                const self = this;
                let dialogId = null;
                let onOk = (e, addAnother=false) => {
                    return e.component.save()
                        .then(success => {
                            if(!success) return false;
                            if (addAnother) {
                                self.fetchData();
                                self.$dialog.reloadComponent({ dialogId, props: { item: new DocumentPackageDto() } });
                                return false;
                            } else {
                                self.$dialog.close();
                            }

                            self.fetchData();
                            return success;
                        });
                };
                let onCancel = e => {
                    self.refresh();
                };
                dialogId = self.$dialog.open({
                    title: `${item.isNew ? "Add": "Edit"} ${self.itemTypeName}${(item.isNew ? "" : `: ${item.description}`)}`,
                    height: "85%",
                    width: "1200",
                    resizable: false,
                    scrollable: true,
                    adaptive: false,
                    closeOnEsc: true,
                    component: DocumentPackageForm,
                    props: {
                        item: item,
                        itemTypeName: self.itemTypeName,
                        documentTemplates: self.documentTemplates,
                        validationErrors: self.errors,
                        uniqueValidator: (item,field) => !self.isDuplicate(item, field),
                    },
                    buttons: [
                        { title: "Cancel", automationId: "btn_dm_modal_cancel", cssClass: "btn btn-secondary", closeOnComplete: true, onClick: onCancel },
                        { title: "Save & Add Another", automationId: "btn_dm_modal_save_and_another", cssClass: "btn btn-primary", isVisible: item.isNew , closeOnComplete: false, onClick: (e) => { onOk(e, true); } },
                        { title: "Save", automationId: "btn_dm_modal_save", cssClass: "btn btn-primary", closeOnComplete: false, onClick:  (e) => { onOk(e, false); } }
                    ]
                });

            },

            save(item, form, addAnother){
                const self = this;
                let passedItem = item;

                self.updateDocumentTemplateList(form, form.selectedTemplateIDs);
                let apiPromise = self.$api.DocumentPackagesApi.save([passedItem.toDataObject()]);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new DocumentPackageDto(i));
                        self.$toast.success(`${self.itemTypeName} was saved.`);
                        self.$dialog.close();
                        self.fetchData();
                        self.refresh();
                        if (addAnother) {
                            self.onAddItem(0);
                        }

                        return result;
                    })
                    .catch(error => {
                        self.$toast.error(`Error saving ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });

            },
            updateDocumentTemplateList(form, e){
                const self = form;
                self.item.documentTemplates = [];
                for (var i = 0; i < e.length; i++){
                        self.item.documentTemplates.push(new DocumentTemplateDto({ documentTemplateID: e[i], sequence: (i + 1) * 10}));
                }
                return self.item.documentTemplates;
            },

            isNotDuplicateName(item) {
                return !_.some(this.items, (i) => {
                    return _.toLower(_.trim(i.name)) === _.toLower(_.trim(item.data.name))
                            && _.parseNumber(i.regionID, -1) === _.parseNumber(item.data.regionID, -1)
                            && _.parseNumber(_.get(i, this.itemKey, -1), -1) != _.parseNumber(_.get(item.data, this.itemKey, -1), -1);
                });
            },

            loadItemUserSecurity(item) {
                const self = this;                
                let onOk = e => {
                    let form = e.component;
                    let items = form.selectedItems;
                   if (!_.isInteger(items.length) || items.length < 1){
                        self.$toast.info("No changes detected");
                        return false;
                    }
                    self.validationErrors = [];
                    self.saveUsers(items);     
                    return true;
                };
                let onCancel = e => {};
                self.$dialog.open({
                    title: `Edit Document Package User Security: ${item.description}`,
                    height: "auto",
                    width: "850",
                    resizable: true,
                    scrollable: true,
                    adaptive: false,
                    closeOnEsc: true,
                    component: DocumentPackageUserSecurityForm,
                    props: {
                        item: new DocumentPackageSecurityUserDto({ documentPackageID: item.documentPackageID }),
                        userGroups: self.userGroups,
                    },
                    okTitle: "Save",
                    onOk,
                    onCancel
                });

            },

            saveUsers(items, changes){
                const self = this;
                let apiPromise = self.$api.DocumentPackageSecurityUsersApi.saveDocumentPackageSecurityUsers(items, changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$dialog.close();
                        self.$toast.success(`${self.itemTypeName} was saved.`);
                        self.onReset();
                        return result;
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },
            updateDimensions() {
                this.gridInstance.updateDimensions();
                this.$nextTick(() => {
                    this.gridInstance.repaint();
                });
            },
            isDuplicate(item, fieldName){
                const self = this;
                let trimLower = val => _.toLower(_.trim(val));
                return _.some(self.items, (i) =>
                    trimLower(i[fieldName]) === trimLower(item[fieldName])
                    && _.parseNumber(item.regionID, -1) === _.parseNumber(i.regionID, -1)
                    && _.parseNumber(i[self.itemKey], -1) !== _.parseNumber(item[self.itemKey], -1)
                );
            }

        }
    }
</script>

<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
             :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            :strikethrough-if-true="['inactive']"
            target-inactive-column="inactive"
            focus-after-insert="none"
            v-model:search-value="searchText"
            @activate="onActivateItem"
            integrated-search
            rq-filters
            rq-editable
            show-include-inactive
            hide-show-column-chooser
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { LossAdvanceReasonDto }  from "../models";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { MoneyMovementType } from '@config/enums';
    import { h } from "vue";

    export default {
        name:"LossAdvanceReasonList",
        mixins: [GridSystemLookupMixin],
        data () {
            return {
                items: [],
                selectedItem: {},
                users: [],
                validationErrors: [],
                addEventName: "",
                validationContext: {},
                searchText: "",
                categories: []
            };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                allBranches: state => _.get(state, "system.lookups.branches", []),
                accountingCodes: state => state.system.lookups.accountingCodes
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            moneyMovementType(){ return _.filter(MoneyMovementType.lookupItems, m => m.id >= 3) },
            itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            regions() { return this.lookupHelpers.getRegions(); },
            branches() { return this.allBranches; },
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null); },
            selectionActions() {
                return [
                    //{ name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` },
                    { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, {'inactive': true, 'inactiveCategory': false}); } },
                    { name: "inactivate", text: "Inactivate", eventName: "activate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['inactive', false]); }  }
                ];
            },
        },

        watch: {
            validationErrors(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$events.emit("update-config-error", { message: _.isEmpty(newValue) ? "" : "Please correct the highlighted errors on screen to continue.", hasError: !_.isEmpty(newValue) });
            }
        },

        created(){
            const self = this;
            self.fetchData();
            self.initGridConfig();
            self.initListeners();
        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        methods: {
            onActivateItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                let verb = _.every(items, ['inactive', true]) ? "Activate" : "Inactivate";

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.activate(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            activate(keys, verb) {
                const self = this;
                let apiPromise = self.$api.LossAdvanceReasonsApi.activateLossAdvanceReasons(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.fetchData();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
            initGridConfig(){
                const self = this;
                let branchDataSource = _.map(self.allBranches, b => {
                    let branchItem = _.clone(b);
                    let branchRegion = self.lookupHelpers.getRegion(b.regionID);
                    branchItem.regionDisplay = _.isNil(branchRegion)
                        ? "Region not found"
                        : branchRegion.description;
                    return branchItem;
                });
                let uniqueReasonRule = {
                    type: "custom",
                    message: "The selected branch/category/reason combination already exists.",
                    validationCallback: params => !self.reasonExists(params.data)
                };
                self.gridConfig = {
                    sorting: { enabled: true },
                    columns: [
                    {
                            dataField: "regionID",
                            dataType: "number",
                            caption: "Region",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            setCellValue(rowData, value) {
                                rowData.regionID = value;
                                rowData.branchID = null;
                            },
                            lookup: {
                                dataSource: self.regions,
                                displayExpr: "displayName",
                                valueExpr: "regionID",
                            },
                            validationRules: [{ type: "required" }]
                        },
                        {
                            dataField: "branchID",
                            dataType: "number",
                            caption: "Branch",
                            alignment: "left",
                            rqFilter: {
                                valueExpr: "branchID",
                                displayExpr: "description",
                                dataSource: branchDataSource,
                                listOperator: "or",
                                valueOperator: "contains",
                                itemTemplate: {
                                    name: "BranchItemTemplate",
                                    props: { item: Object, index: Number },
                                    setup(props) {
                                        return () => h("div", { class: "d-inline-flex align-items-baseline" }, [
                                            h("span", props.item.name),
                                            h("span", { class: "text-muted font-italic font-xs font-weight-light ms-auto" }, props.item.regionDisplay)
                                        ]);
                                    }
                                }
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                let displayName = _.find(self.branches, u => cellInfo.value == u.id).name;
                                let displayTitle = displayName;
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title", displayTitle)
                                    .append(displayName)
                                    .appendTo(cellElement);
                            },
                            setCellValue(rowData, value) {
                                rowData.branchID = value.branchID;
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                if(_.isNil(cellInfo.data.regionID)) {
                                    $("<div/>")
                                        .addClass("d-flex align-items-center text-muted ps-1")
                                        .height(cellElement[0].clientHeight)
                                        .text("Select a region first...")
                                        .appendTo(cellElement);
                                    return;
                                }
                                let branchItems = _.filter(branchDataSource, b => b.regionID === cellInfo.data.regionID);
                                $("<div />").dxSelectBox({
                                    dataSource: branchItems,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: cellInfo.value,
                                    showDropDownButton: true,
                                    onValueChanged(e) {
                                        cellInfo.setValue({
                                            branchID: e.value,
                                        });
                                    }
                                }).appendTo(cellElement);
                            },
                            validationRules: [{ type: "required" }, uniqueReasonRule],
                        },
                        {
                            dataField: "lossAdvanceReasonCategoryID",
                            dataType: "number",
                            caption: "Category",
                            alignment: "left",
                            lookup: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: (e) => _.isEmpty(e?.data)
                                    ? self.categories
                                    : _.sortBy(_.filter(self.categories, c => _.some(c.regions, r => r.regionID === e?.data.regionID)), "name")
                            },
                            setCellValue: function(rowData, value) {
                                rowData.lossAdvanceReasonCategoryID = value;
                                rowData.moneyMovementTypeID = _.find(self.categories, u => value == u.id).additionalIdentity;
                            },
                            validationRules: [{ type: "required" }, uniqueReasonRule],
                            ...DxGridUtils.lookupSortDisplayExpr,
                        },
                        {
                            dataField: "reason",
                            caption: "Reason",
                            validationRules: [{ type: "required" }, uniqueReasonRule]
                        },
                        {
                            dataField: "moneyMovementTypeID",
                            dataType: "number",
                            caption: "Type",
                            visible: false,
                            allowEditing: false,
                            lookup: {
                                dataSource: self.moneyMovementType,
                                displayExpr: "name",
                                valueExpr: "id",
                            },
                            showDropDownButton: false,
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "accountingCodeID",
                                dataType: "number",
                                caption: "Account Code",
                                editorOptions: { showClearButton: false },
                                validationRules: [{ type: "required" }],
                            },
                            lookupKey: self.lookupItems.ACCOUNTING_CODES,
                            customSort: function(i) { return _.parseNumber(_.get(i, "data")); },
                        }),
                        {
                            // dataField: "documentTemplateID",
                            // dataType: "number",
                            dataField: "documentTemplateName",
                            caption: "Template",
                            visible: false,
                            editorOptions: { showClearButton: true },
                            setCellValue(rowData, value) {
                                // rowData.documentTemplateID = value;
                                rowData.documentTemplateName = value;
                            },
                            // lookup: {
                            //     dataSource: self.documentTemplates,
                            //     displayExpr: "name",
                            //     valueExpr: "id",
                            // },
                        },
                        {
                            dataField: "inactive",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        }
                    ],
                    onInitNewRow: e => {
                        e.data.inactive = false;
                    },
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            fetchData() {
                const self = this;

                let apiPromises = [
                    self.$api.LossAdvanceReasonCategoriesApi.getCategoryLookups(),
                    self.$api.LossAdvanceReasonsApi.getLossAdvanceReasons(),
                ];
                return self.$rqBusy.wait(Promise.all(apiPromises))
                    .then(result => {
                        self.categories = _.sortBy(result[0], "name");
                        self.items = _.map(result[1], i => new LossAdvanceReasonDto(i));
                    })
                    .catch(error => {
                        self.items = [];
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow();
            },

            onGridInsert(values) {
                const self = this;
                let originalItem = new LossAdvanceReasonDto();
                let newItem = new LossAdvanceReasonDto(values);
                let changes = self.getAuditChanges(originalItem.toDataObject(), newItem.toDataObject());
                return self.save(newItem, changes)
                    .then(result => {
                        self.fetchData();
                    });
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.lossAdvanceReasonID === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new LossAdvanceReasonDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                let saveChanges = function() {
                    self.save(updatedItem, changes)
                        .then(result => {
                            self.fetchData();
                        });
                }

                saveChanges();
            },

            save(item, changes){
                const self = this;
                if (changes.length == 0) {
                    return Promise.resolve(item);
                }
                let apiPromise = self.$api.LossAdvanceReasonsApi.saveLossAdvanceReason(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success(`${self.itemTypeName} was saved.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error({ message: `Error saving ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            reasonExists(targetItem) {
                const self = this;
                return _.some(self.items, item => 
                    item.lossAdvanceReasonID !== targetItem.lossAdvanceReasonID
                    && item.branchID === targetItem.branchID
                    && item.lossAdvanceReasonCategoryID === targetItem.lossAdvanceReasonCategoryID
                    && item.reason === targetItem.reason
                    );
            },

            getBranches(regionID, currentBranchID) {
                let regionBranches = _.filter(this.branches, { regionID });
                let selectedBranchIDs = _.map(this.userData.userRegionBranches, "branchID") || [];
                _.pull(selectedBranchIDs, currentBranchID);
                return _.filter(regionBranches, b => !_.includes(selectedBranchIDs, _.getNumber(b, "id", 0)));
            },

            refresh() {
                if(_.isNull(this.gridInstance)) return;
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            // onDeleteItem(e) {
            //     if(!e || !e.data) return;
            //     const self = this;
            //     let items = e.data;
            //     let itemLabel = items.length > 1
            //         ? self.itemTypeNamePlural
            //         : self.itemTypeName;

            //     let okHandler = function (args) {
            //         let keys = _.map(items, self.itemKey);
            //         self.delete(keys);
            //         return true;
            //     }

            //     self.$dialog.confirm(
            //         "Confirm Delete",
            //         `Are you sure you want to delete the selected ${itemLabel}?`,
            //         okHandler,
            //         null, { cancelTitle: 'No', okTitle: 'Yes'});
            // },

            // delete(keys) {
            //     const self = this;
            //     let apiPromise = self.$api.LossAdvanceReasonsApi.deleteLossAdvanceReasons(keys);
            //     return self.$rqBusy.wait(apiPromise)
            //         .then(() => {
            //             self.removeItems(keys);
            //             let message = keys.length > 1
            //                 ? `${keys.length} ${self.itemTypeNamePlural} were deleted.`
            //                 : `${self.itemTypeName} was deleted.`
            //             self.$toast.success(message);
            //             return true;
            //         })
            //         .catch(error => {
            //             if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
            //                 self.$dialog.messageBox(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
            //             } else {
            //                 self.$toast.error(`Error deleting ${self.itemTypeName}.`);
            //             }
            //             console.error(error);
            //             return error;
            //         });
            // },

            // removeItems(keys) {
            //     const self = this;
            //     _.forEach(keys, key => {
            //         let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
            //         if(itemIndex >= 0) self.items.splice(itemIndex, 1);
            //     });
            //     self.refresh();
            // }
        }
    }
</script>

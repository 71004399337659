<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        id="dg-standard-language-list"
        :automation_id="elementName('tbl')"
        :actions="selectionActions"
        :config="gridConfig"
        :data-source="gridDataSource"
        :export-file-name="elementName('', 'data')"
        v-model:validation-errors="validationErrors"
        focus-after-insert="none"
        @clear-search="onClearSearch"
        @edit="onEditItemContent"
        @edit-note="onEditNote"
        @export="onExportItem($event, 0)"
        @export-zipped="onExportItem($event, 1)"
        @delete="onDeleteItem"
        @reset="onReset"
        rq-filters
        rq-editable
        integrated-search
    />
    <notes-popover
        container="#dg-standard-language-list"
        :popover="notesPopover"
        v-model:visible="notesPopover.visible"
    />
</template>

<script>
    import { mapGetters } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import NotesPopover from "@/shared/components/rq/NotesPopover";
    import StandardLanguageEditor from "./StandardLanguageEditor";
    import { StandardLanguageShortDto }  from "../../models";
    import { OverrideBulletOption } from "@config/enums";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";

    export default {
        name:"StandardLanguageList",
        components: { NotesPopover },
        mixins: [GridSystemLookupMixin],
        props: {
            categories: { Type: Array, default: () => [] },
            validationList: { type: Array, default: () => []}
        },

        data () {
            return {
                items: [],
                errorMessage: "",
                addEventName: "",
                notesPopover: {
                    visible: false,
                    target: null,
                    notes: null,
                    description: null
                },
                validationErrors: [],
            };
        },

        watch: {
            validationErrors: function () {
                const self = this;
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });
                this.$emit("update:validationList", this.validationErrors);
            },
            searchText(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.gridSearch(newValue);
            }
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.initListeners();
            this.fetchData();
        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            overrideBulletOptions() { return  OverrideBulletOption.lookupItems; },
        },

        methods: {

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            exportClauses(zip, ids) {
                const self = this;
                let apiPromise = self.$api.StandardLanguageApi.exportClauses(zip, ids);
                return self.$rqBusy.wait(apiPromise);
            },

            fetchData () {
                const self = this;
                let apiPromise = self.$api.StandardLanguageApi.get();
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new StandardLanguageShortDto(i));
                    }).catch(error => {
                        self.items = [];
                        self.$toast.error({ message: `Error Loading ${self.itemTypeNamePlural}.` });
                        return error;
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            fetchContent(standardLanguageID) {
                const self = this;
                let apiPromise = self.$api.StandardLanguageApi.getContent(standardLanguageID);
                return self.$rqBusy.wait(apiPromise)
            },

            initNonReactiveVariables() {
                this.itemTypeName = "Clause";
                this.itemTypeNamePlural = "Clauses";
                this.itemKey = "standardLanguageID";
                this.readOnlyColumns = ["standardLanguageID", "regionID", "standardLanguageCategoryID", "code", "description"];
                this.regions = this.lookupHelpers.getRegions();
                // this.workflowTasks = this.lookupHelpers.getLookupItems(this.lookupItems.WORKFLOW_TASKS, 1);
                // this.workflowTasks.push({id: null, name:''})
                this.selectionActions = [
                    {
                        name: "edit",
                        text: "Edit",
                        eventName: "edit",
                        requireSelection: true,
                        tooltip: `Edit ${this.itemTypeName}`,
                        disabled: function(e) { return _.isEqual(e.data.isManualLanguage, true);}
                    },
                    {
                        name: "edit-note",
                        text: "Add Note",
                        eventName: "edit-note",
                        requireSelection: true,
                        visible: function(e) { return _.isNullOrEmpty(_.get(e.data, "notes"));}
                    },
                    {
                        name: "edit-note",
                        text: "Edit Note",
                        eventName: "edit-note",
                        requireSelection: true,
                        visible: function(e) { return !_.isNullOrEmpty(_.get(e.data, "notes"));}
                    },
                    { name: "export", text: "Export",
                        children: [
                            { name: "export", text: "Export", eventName: "export", requireSelection: true, allowMultiSelection: true, tooltip: "Export all selected clauses" },
                            { name: "export-zipped", text: "Export (Zipped)", eventName: "export-zipped", requireSelection: true, allowMultiSelection: true, tooltip: "Export all selected clauses to a Zip File" },
                        ]
                    },
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${this.itemTypeName}`,
                        disabled: function(e) { return !_.every(e.data, ["isManualLanguage", false]);}
                    }
                ];
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    onEditorPreparing: self.onEditorPreparing,
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            showInColumnChooser: false
                        },
                        {
                            dataField: "regionID",  caption: "Region", allowSearch: false,
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            lookup: {
                                dataSource: self.regions,
                                displayExpr: "displayName",
                                valueExpr: "regionID"
                            },
                            validationRules: [{ type: "required" }]
                        },
                        {
                            dataField: "standardLanguageCategoryID",  caption: "Category",
                            lookup: {
                                 dataSource:{
                                    loadMode: "raw",
                                    load: () => self.categories
                            },
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            validationRules: [{ type: "required" }]
                        },
                        {
                            dataField: "code",
                            caption: "Code",
                            editorOptions: { maxLength: 25 },
                        },
                        {
                            dataField: "description",
                            editorOptions: { maxLength: 1000 },
                        },
                        {
                            caption: "Notes",
                            dataField: "hasNotes",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolPopoverCellTemplate({
                                idAppend: "std-language-notes-popup-info-",
                                handlers:{
                                    mouseover(cellElement, cellInfo, e) {
                                        self.updateNotesPopover(cellInfo.data.notes, cellInfo.data.description, e.target);
                                    },
                                    mouseout(cellElement, cellInfo, e) {
                                        self.updateNotesPopover();
                                    },
                                    click(cellElement, cellInfo, e) {
                                        e.stopPropagation();
                                    },
                                }
                            }),
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "workflowTaskID",
                                dataType: "number",
                                caption: "Associated Task",
                                editorOptions: { showClearButton: true },
                            },
                            lookupKey: self.lookupItems.WORKFLOW_TASKS
                        }),
                        {
                            dataField: "overrideOption",
                            caption: "Override List Style",
                            lookup: {
                                dataSource: self.overrideBulletOptions,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                        },
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow();
            },

            onClearSearch(e){
                this.searchText = "";
                this.refresh();
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, self.itemKey);

                    let apiPromise = self.$api.StandardLanguageApi.delete(toBeDeletedKeys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(keys => {
                            _.pullAllBy(self.items, items, self.itemKey);
                            let message = keys.length > 1 ? `${keys.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                            if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onEditorPreparing(e){
                if(e.parentType !== "dataRow") return;
                if (e.dataField === "notes") e.editorOptions.disabled = true;
                let isManualLanguage = _.get(e.row.data, "isManualLanguage", false);
                if(_.indexOf(this.readOnlyColumns, e.dataField) >= 0) {e.editorOptions.readOnly = isManualLanguage; return;}
            },

            onEditItemContent(e) {
                if(!e || !e.data) return;
                const self = this;
                self.launchEditorDialog(e.data);
            },

            onEditNote(e) {
                if(!e || !e.data) return;
                this.showNotesDialog(e.data);
            },

            onExportItem(e, zip) {
                if(!e || !e.data) return;
                const self = this;
                let ids = _.map(e.data, self.itemKey);
                self.exportClauses(zip, ids)
                    .then(files => {
                        _.each(files, file => {
                            self.saveToClient(file);
                        });
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error exporting Clauses.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onGridInsert(values) {
                const self = this;
                let newItem = new StandardLanguageShortDto(values);
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.save(newItem, changes)
                    .then(result => {
                        self.$toast.success({ message: `${self.itemTypeName} ${newItem.code} was created.` });
                        self.items.push(new StandardLanguageShortDto(result));
                    }).catch(error => {
                        self.$toast.error({ message: `Error creating ${self.itemTypeName}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, [self.itemKey, key]);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new StandardLanguageShortDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.save(updatedItem, changes)
                    .then(result => {
                        self.$toast.success({ message: `${self.itemTypeName} ${updatedItem.code} was saved.` });
                        _.assign(self.items[itemIndex], new StandardLanguageShortDto(result));
                    }).catch(error => {
                        self.$toast.error({ message: `Error saving ${self.itemTypeName}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onReset(e) {
                this.fetchData();
            },

            refresh() {
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            updateDimensions() {
                this.gridInstance.updateDimensions();
                this.$nextTick(() => {
                    this.gridInstance.repaint();
                });
            },

            updateNotesPopover(notes=null, description=null, target=null) {
                const self = this;
                let newID = _.get(target, "id") || null;
                let lastID = _.get(self.notesPopover, "target") || null;
                let isNewItem = !_.isEqual(newID, lastID);
                let notesPopover = _.clone(self.notesPopover);
                if (isNewItem) {
                    if (!_.isNil(lastID)) {
                        notesPopover.visible = false;
                    }
                    notesPopover.target = newID;
                    notesPopover.notes = notes;
                    notesPopover.description = description;
                    notesPopover.visible = true;
                } else {
                    notesPopover.visible = !notesPopover.visible;
                }
                self.$nextTick(() => {
                    self.notesPopover = notesPopover;
                });
            },

            save(item, changes){
                const self = this;

                if(changes.length === 0) {
                    return Promise.resolve(item);
                }

                let apiPromise = self.$api.StandardLanguageApi.save(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise);
            },

            saveToClient(file) {
                const self = this;
                var blob = _.base64ToBlob(file.content, file.contentType);
                if (window.navigator.msSaveOrOpenBlob) // IE10+
                    window.navigator.msSaveOrOpenBlob(blob, file.commonFileName);
                else { // Others
                    var a = document.createElement("a")
                    var url = URL.createObjectURL(blob);
                    a.href = url;
                    a.download = file.commonFileName;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function() {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 0);
                }
            },

            showNotesDialog(item) {
                const self = this;
                let onOk = (e) => {
                    if (e.value !== item.notes) {
                        self.onGridUpdate(item.standardLanguageID, {notes: e.value});
                    }
                    return true;
                };
                //Adding/editing notes was not working for me for some reason; updating to use "promptInput" corrected the issue
                self.$dialog.promptInput({
                    title: _.isEmpty(item.notes) ? "Add Note" : "Edit Note",
                    label: "",
                    placeholder: "Enter notes here...",
                    width: 600,
                    multiline: true,
                    value: item.notes,
                    onOk
                });
            },

            launchEditorDialog(data) {
                const self = this;
                self.$dialog.open({
                    title: `Standard Language: ${data.description}`,
                    component: StandardLanguageEditor,
                    height: "95%",
                    width: "95%",
                    scrollable: false,
                    resizable: false,
                    props: {
                        itemId: data.standardLanguageID,
                        clauseName: data.code
                    },
                    onOk: e => e.component.save()
                });
            },
        }
    }
</script>

<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="items"
            :export-file-name="elementName('', 'data')"
            @rowDoubleClick="onEditItem"
            @edit="onEditItem"
            @delete="onDeleteItem"
            @reset="onReset"
            @activate="onActivateItem"
            @copy="onCopyWorkflowProcssTemplate"
            :strikethrough-if-true="['isInActive']"
            target-inactive-column="isInActive"
            show-include-inactive
            integrated-search
            rq-filters
        />
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { WorkflowProcessTemplateDto, WorkflowProcessTemplateDataDto }  from "../models";
    import WorkflowProcessTemplateFormAdd from "./WorkflowProcessTemplateFormAdd.vue";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import WorkflowProcessTemplateCopyForm from "./WorkflowProcessTemplateCopyForm";


    export default {
        name:"WorkflowProcessTemplatesList",
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                verifiedItem: {},
                addEventName: ""
            };
        },
        created(){
            this.initGridConfig();
            this.initListeners();
            this.fetchData();
        },
        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "currentSession"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            selectionActions() {
                return [{ name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: `Edit ${this.itemTypeName}` },
                        { name: "copy", text: "Copy", eventName: "copy", requireSelection: true, tooltip: `Copy ${this.itemTypeName}` },
                        { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` },
                        { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['isInActive', true]); } },
                        { name: "inactivate", text: "Inactivate", eventName: "activate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['isInActive', false]); } }];
            },
            regions() { return this.lookupHelpers.getRegions(); }
        },
        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            onAddItem() {
                let regionID = _.get(this.currentSession, "user.regionID", 0);
                let lastModifiedBy = _.get(this.currentSession, "username", "");
                this.selectedItem = new WorkflowProcessTemplateDto({regionID, lastModifiedBy});
                this.clear();
                this.showAddItem(new WorkflowProcessTemplateDto({regionID, lastModifiedBy}));
            },

            onEditItem(e) {
                let workflowProcessTemplateID = _.get(e, "data.workflowProcessTemplateID", 0);
                if (workflowProcessTemplateID == 0)
                {
                    self.$toast.error({ message: `Invalid ${self.itemTypeName} ID.` });
                    return;
                }
                this.clear();
                this.$router.push({ name: 'cfg:workflow:processTemplate',
                    params: {
                        workflowProcessTemplateID: workflowProcessTemplateID,
                        items: this.items
                        }
                    }
                );
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = items.map(item => _.get(item, self.itemKey));

                    let apiPromise = self.$api.WorkflowProcessTemplatesApi.delete(toBeDeletedKeys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(keys => {
                            self.deleteItems(keys);
                            let message = keys.length > 1 ? `${keys.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            self.fetchData();
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onReset(e) {
                this.fetchData();
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    keyExpr: self.itemKey,
                    columns: [
                        { dataField: self.itemKey, visible: false, showInColumnChooser: false},
                        { dataField: "processTemplateName", sortIndex: 0, sortOrder: "asc"},
                        {
                            dataField: "regionID",
                            caption: "Region",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            lookup: {
                                dataSource: self.regions,
                                displayExpr: "displayName",
                                valueExpr: "regionID"
                            }
                        },
                        { dataField: "gfNo", caption: "File#", dataType: "string" },
                        { dataField: "lastModified", cellTemplate: DxGridUtils.dateTimeCellTemplate },
                        {
                            caption: "Inactive",
                            dataField: "isInActive",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            allowEditing: false,

                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.WorkflowProcessTemplatesApi.getAll();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new WorkflowProcessTemplateDto(i));
                        return { data: self.items, totalCount: self.items.length };
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            clear() {
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            },

            refresh() {
                this.clear();
                this.gridInstance.refresh();
            },

            save(item){
                const self = this;
                let original = new WorkflowProcessTemplateDataDto({workflowProcessTemplate: _.clone(self.selectedItem)});
                let changes = self.getAuditChanges(original, item);
                if (changes.length == 0) {
                    self.$toast.info({ message: "No changes detected" });
                    return;
                }
                let apiPromise = self.$api.WorkflowProcessTemplatesApi.save(item, changes);
                self.$rqBusy.wait(apiPromise)
                    .then(data => {
                        self.onEditItem({data: data.workflowProcessTemplate});
                        self.$toast.success({ message: `${self.itemTypeName} ${data.workflowProcessTemplate.processTemplateName} was saved.` });
                    })
                    .catch(error => {
                        self.$toast.error("Error saving workflow process template.")
                        console.error(error);
                        return error;
                    });
            },

            showAddItem(item) {
                const self = this;
                self.$dialog.open({
                    title: `Add ${self.itemTypeName}`,
                    width: 700,
                    adaptive: true,
                    component: WorkflowProcessTemplateFormAdd,
                    props: {
                        item,
                        uniqueValidator: (item) => !self.isDuplicate(item)
                    },
                    okTitle: "Save & Edit",
                    onOk: e => {
                        if (!e.component.isValid()) return false;
                        return self.save(new WorkflowProcessTemplateDataDto({ workflowProcessTemplate: item }));
                    }
                });
            },

            deleteItems(keys) {
                _.each(keys, k => {
                    _.remove(this.items, (i) => {return _.parseNumber(_.get(i, this.itemKey, -1), -1) == k;});
                });
            },

            updateItem(item) {
                let editIem = _.find(this.items, (i) => {
                    return _.parseNumber(_.get(i, this.itemKey, -1), -1) == _.parseNumber(_.get(item, this.itemKey, -1), -1);
                });
                _.assign(editIem, item);
            },

            isDuplicate(item){
                const self = this;
                let dup = {};
                dup = _.find(self.items, (i) => {
                    return _.toLower(_.trim(i.processTemplateName)) === _.toLower(_.trim(item.processTemplateName)) && _.parseNumber(item.regionID, -1) === _.parseNumber(i.regionID, -1) && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(item, self.itemKey, -1), -1);
                });
                if (dup) {
                    self.validationErrors.push(`${self.itemTypeName} Description [${dup.processTemplateName}] for Region [${dup.regionDisplay}] already exists.`);
                    return true;
                }
                return false;
            },
             onActivateItem(e){
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                let verb = e.action.name === "activate" ? "Activate" : "Inactivate";

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.toggleIsInactive(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            toggleIsInactive(keys, verb) {
                const self = this;
                let apiPromise = self.$api.WorkflowProcessTemplatesApi.toggleIsInactive(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.fetchData();
                        self.refresh();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },
            onCopyWorkflowProcssTemplate(e) {
                this.showCopyWorkflowProcessTemplate(e.data);
            },

            showCopyWorkflowProcessTemplate(workflowProcessTemplate) {
                const self = this;
                let onOk = (e) => e.component.save()
                    .then(result => {
                        if(!result.success) return false;
                        self.fetchData();
                        self.refresh();
                        return result.success;
                    });
                let onCancel = e => {
                    self.refresh();
                };
                self.$dialog.open({
                    title: `Copy ${workflowProcessTemplate.processTemplateName}`,
                    width: 650,
                    height: "auto",
                    resizable: false,
                    scrollable: false,
                    adaptive: true,
                    closeOnEsc: true,
                    component: WorkflowProcessTemplateCopyForm,
                    props: {
                        item: workflowProcessTemplate,
                    },
                    okTitle: "Save",
                    onOk,
                    onCancel
                });
            },
        }
    }
</script>

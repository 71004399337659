<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
             :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            :strikethrough-if-true="['inactive']"
            target-inactive-column="inactive"
            focus-after-insert="none"
            v-model:search-value="searchText"
            @activate="onActivateItem"
            integrated-search
            rq-filters
            rq-editable
            show-include-inactive
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { MoneyMovementApprovalRangeDto }  from "../models";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { MoneyMovementNotificationType, MoneyMovementType } from '@config/enums';
    import { h } from "vue";

    export default {
        name:"MoneyMovementApprovalRangeList",
        mixins: [GridSystemLookupMixin],
        data () {
            return {
                items: [],
                selectedItem: {},
                users: [],
                validationErrors: [],
                addEventName: "",
                validationContext: {},
                searchText: ""
            };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                allBranches: state => _.get(state, "system.lookups.branches", [])
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            moneyMovementNotificationType(){ return MoneyMovementNotificationType.lookupItems; },
            moneyMovementType(){ return MoneyMovementType.lookupItems; },
            itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            regions() { return this.lookupHelpers.getRegions(); },
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null); },
            selectionActions() {
                return [
                    //{ name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` },
                    { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, {'inactive': true, 'inactiveUser': false}); } },
                    { name: "inactivate", text: "Inactivate", eventName: "activate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['inactive', false]); }  }
                ];
            },
        },

        watch: {
            validationErrors(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.$events.emit("update-config-error", { message: _.isEmpty(newValue) ? "" : "Please correct the highlighted errors on screen to continue.", hasError: !_.isEmpty(newValue) });
            }
        },

        created(){
            const self = this;
            self.fetchData();
            self.initGridConfig();
            self.initListeners();
        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        methods: {
            onActivateItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                let verb = _.every(items, ['inactive', true]) ? "Activate" : "Inactivate";

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.activate(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            activate(keys, verb) {
                const self = this;
                let apiPromise = self.$api.MoneyMovementApprovalRangesApi.activateMoneyMovementApprovalRanges(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.fetchData();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
            initGridConfig(){
                const self = this;
                let branchDataSource = _.map(self.allBranches, b => {
                    let branchItem = _.clone(b);
                    let branchRegion = self.lookupHelpers.getRegion(b.regionID);
                    branchItem.regionDisplay = _.isNil(branchRegion)
                        ? "Region not found"
                        : branchRegion.description;
                    return branchItem;
                });
                let uniqueRangeRule = {
                    type: "custom",
                    message: "The selected user/type combination already exists.",
                    validationCallback: params => !self.userRangeExists(params.data)
                };
                self.gridConfig = {
                    sorting: { enabled: true },
                    columns: [
                        {
                            dataField: "approverUsersID",
                            dataType: "number",
                            caption: "User",
                            lookup: {
                                displayExpr: "name",
                                valueExpr: "id",
                                dataSource: {
                                    loadMode: "raw",
                                    load: () => self.users
                                }
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxSelectBox({
                                    dataSource: {
                                        loadMode: "raw",
                                        load() {
                                            return _.filter(self.users, i =>
                                                cellInfo.value == i.id || !i.inactive
                                            );
                                        }
                                    },
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: cellInfo.value,
                                    showDropDownButton: true,
                                    onValueChanged: function(e) {
                                        cellInfo.setValue(e.value);
                                    }
                                }).appendTo(cellElement);
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                let displayName = cellInfo.text ? cellInfo.text : _.find(self.users, u => cellInfo.value == u.id).name;
                                let displayTitle = displayName;
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title", displayTitle)
                                    .append(displayName)
                                    .appendTo(cellElement);
                            },
                            setCellValue: function(rowData, value) {
                                rowData.approverUsersID = value;
                            },
                            rqFilter: {
                                valueExpr: "id",
                                displayExpr: "name",
                                dataSource: _.sortBy(self.users, "name"),
                                listOperator: "and",
                                valueOperator: "contains",
                            },
                            validationRules: [{ type: "required" }, uniqueRangeRule],
                            ...DxGridUtils.lookupSortDisplayExpr,
                        },
                        {
                            dataField: "moneyMovementType",
                            dataType: "number",
                            caption: "Type",
                            setCellValue(rowData, value) {
                                rowData.moneyMovementType = value;
                            },
                            lookup: {
                                dataSource: self.moneyMovementType,
                                displayExpr: "name",
                                valueExpr: "id",
                            },
                            validationRules: [{ type: "required" }, uniqueRangeRule]
                        },
                        {
                            dataField: "regionList",
                            dataType: "object",
                            caption: "Region",
                            width: 200,
                            minWidth: 100,
                            calculateSortValue: rowData => {
                                let regionNames = _.sortBy(_.map(_.filter(self.regions, r => _.includes(rowData.regionList, r.regionID)), "description"));
                                return _.join(regionNames, "");
                            },
                            setCellValue(rowData, value) {
                                rowData.regionList = value;
                                rowData.branchList = [];
                                rowData.allBranches = false;
                            },
                            calculateFilterExpression: (filterValue, operator) => {
                                if (_.isString(filterValue)) {
                                    return rowData => {
                                        let regionNames = _.sortBy(_.map(_.filter(self.regions, r => _.includes(rowData.regionList, r.regionID)), "description"));
                                        return _.includes(_.toLower(regionNames), _.toLower(filterValue))
                                    }
                                }

                                if(operator !== "contains") return () => false;
                            
                                return rowData => {
                                        let ids = [rowData.regionID, ...rowData.regionList];
                                        return _.includes(ids, filterValue);
                                }
                            },
                            rqFilter: {
                                valueExpr: "regionID",
                                displayExpr: "description",
                                dataSource: _.sortBy(self.regions, "description"),
                                listOperator: "and",
                                valueOperator: "contains",
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                let regions = self.regions;
                                if(_.isEmpty(cellInfo.value) || _.isEmpty(regions)) return;


                                let itemNames = _.sortBy(_.map(cellInfo.value, id =>
                                                        _.find(regions, r => id == r.regionID).description
                                                        ));
                                let displayText = _.joinParts(itemNames, ", ");
                                let displayTitle = itemNames.length === 1
                                    ? displayText
                                    : `${itemNames.length} Regions Selected`;
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title", displayTitle)
                                    .append(displayText)
                                    .appendTo(cellElement);
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                $("<div />").dxTagBox({
                                    dataSource: {
                                        loadMode: "raw",
                                        load() {
                                            return self.regions;
                                        }
                                    },
                                    displayExpr: "description",
                                    valueExpr: "regionID",
                                    value: cellInfo.value,
                                    showSelectionControls: true,
                                    showDropDownButton: true,
                                    applyValueMode: "useButtons",
                                    maxDisplayedTags: 1,
                                    onValueChanged: function(e) {
                                        cellInfo.setValue(e.value);
                                    }
                                }).appendTo(cellElement);
                            },
                            validationRules: [{ type: "required" }],
                        },
                        {
                            dataField: "branchList",
                            dataType: "object",
                            caption: "Branch",
                            width: 200,
                            minWidth: 100,
                            calculateFilterExpression: (filterValue, operator) => {
                                if(_.isNil(operator)) return _.isString(filterValue)
                                    ? rowData => {
                                        let displayText = self.getBranchDisplayText(rowData);
                                        return _.includes(_.toLower(displayText), _.toLower(filterValue))
                                    }
                                    : () => true;
                                return rowData => {
                                    let ids = rowData.allBranches
                                        ? _.map(_.filter(self.allBranches, b => _.includes(rowData.regionList, b.regionID)), "id")
                                        : rowData.branchList;
                                    return _.includes(ids, filterValue);
                                };
                            },
                            rqFilter: {
                                valueExpr: "branchID",
                                displayExpr: "description",
                                dataSource: branchDataSource,
                                listOperator: "or",
                                valueOperator: "contains",
                                itemTemplate: {
                                    name: "BranchItemTemplate",
                                    props: { item: Object, index: Number },
                                    setup(props) {
                                        return () => h("div", { class: "d-inline-flex align-items-baseline" }, [
                                            h("span", props.item.name),
                                            h("span", { class: "text-muted font-italic font-xs font-weight-light ms-auto" }, props.item.regionDisplay)
                                        ]);
                                    }
                                }
                            },
                            cellTemplate: function(cellElement, cellInfo) {
                                if((_.isEmpty(cellInfo.value) && !cellInfo.data.allBranches) || _.isNil(cellInfo.data.regionList)) return;
                                const searchHighlightTemplate = '<span class="dx-datagrid-search-text">$&</span>';

                                let displayText = self.getBranchDisplayText(cellInfo.data);

                                let searchHighlighted = _.isEmpty(_.trim(self.searchText)) ? displayText : _.replace(displayText, new RegExp(self.searchText, "ig"), searchHighlightTemplate);
                                $("<span />")
                                    .addClass("text-truncate")
                                    .attr("title", displayText)
                                    .append(searchHighlighted)
                                    .appendTo(cellElement);
                            },
                            setCellValue(rowData, value) {
                                rowData.allBranches = value.allBranches;
                                rowData.branchList = value.branchList;
                            },
                            editCellTemplate: function(cellElement, cellInfo) {
                                if(_.isNil(cellInfo.data.regionList)) {
                                    $("<div/>")
                                        .addClass("d-flex align-items-center text-muted ps-1")
                                        .height(cellElement[0].clientHeight)
                                        .text("Select a region first...")
                                        .appendTo(cellElement);
                                    return;
                                }
                                let branchItems = _.filter(branchDataSource, b => _.includes(cellInfo.data.regionList, b.regionID));
                                let initialValue = (cellInfo.data.allBranches) ? _.map(branchItems, "branchID") : cellInfo.value;
                                $("<div />").dxTagBox({
                                    dataSource: branchItems,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    value: initialValue,
                                    showSelectionControls: true,
                                    showDropDownButton: true,
                                    applyValueMode: "useButtons",
                                    maxDisplayedTags: 1,
                                    itemTemplate: itemData => {
                                        if (!itemData) return;
                                        var display = itemData.name;
                                            return $('<span>').addClass('d-flex justify-content-between')
                                            .append($('<span>').append(display))
                                            .append($('<span>').addClass('text-muted font-italic font-xs font-weight-light ms-auto').append(itemData.regionDisplay));
                                    },
                                    onValueChanged(e) {
                                        cellInfo.setValue({
                                            branchList: e.value,
                                            allBranches: e.value.length === branchItems.length
                                        });
                                    }
                                }).appendTo(cellElement);
                            },
                            validationRules: [{
                                type: "custom",
                                message: "Branch is required.",
                                validationCallback: e => _.parseBool(e.data.allBranches) || !_.isEmpty(e.data.branchList)
                            }]
                        },
                        {
                            dataField: "notificationType",
                            dataType: "number",
                            caption: "Notification",
                            setCellValue(rowData, value) {
                                rowData.notificationType = value;
                            },
                            lookup: {
                                dataSource: self.moneyMovementNotificationType,
                                displayExpr: "name",
                                valueExpr: "id",
                            },
                            validationRules: [{ type: "required" }]
                        },
                        {
                            dataField: "fromAmount",
                            caption: "From",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "toAmount",
                            caption: "To",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "inactive",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        }
                    ],
                    onInitNewRow: e => {
                        e.data.inactive = false;
                        e.data.fromAmount = 0;
                        e.data.notificationType = 1;
                    },
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            fetchData() {
                const self = this;

                let apiPromises = [
                    self.$api.UsersApi.getUsersByApplicationAccess("appFinancialApprovals", self.user.usersID),
                    self.$api.MoneyMovementApprovalRangesApi.getMoneyMovementApprovalRanges(),
                ];
                return self.$rqBusy.wait(Promise.all(apiPromises))
                    .then(result => {
                        self.users = _.sortBy(result[0], "name");
                        self.items = _.map(result[1], i => new MoneyMovementApprovalRangeDto(i));
                    })
                    .catch(error => {
                        self.items = [];
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow();
            },

            onGridInsert(values) {
                const self = this;
                let originalItem = new MoneyMovementApprovalRangeDto();
                let newItem = new MoneyMovementApprovalRangeDto(values);
                let changes = self.getAuditChanges(originalItem.toDataObject(), newItem.toDataObject());
                return self.save(newItem, changes)
                    .then(result => {
                        self.fetchData();
                    });
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.moneyMovementApprovalRangeID === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new MoneyMovementApprovalRangeDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                let saveChanges = function() {
                    self.save(updatedItem, changes)
                        .then(result => {
                            self.fetchData();
                        });
                }

                saveChanges();
            },

            // onDeleteItem(e) {
            //     if(!e || !e.data) return;
            //     const self = this;
            //     let items = e.data;
            //     let itemLabel = items.length > 1
            //         ? self.itemTypeNamePlural
            //         : self.itemTypeName;

            //     let okHandler = function (args) {
            //         let keys = _.map(items, self.itemKey);
            //         self.delete(keys);
            //         return true;
            //     }

            //     self.$dialog.confirm(
            //         "Confirm Delete",
            //         `Are you sure you want to delete the selected ${itemLabel}?`,
            //         okHandler,
            //         null, { cancelTitle: 'No', okTitle: 'Yes'});
            // },

            save(item, changes){
                const self = this;
                if (changes.length == 0) {
                    return Promise.resolve(item);
                }
                let apiPromise = self.$api.MoneyMovementApprovalRangesApi.saveMoneyMovementApprovalRange(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success(`${self.itemTypeName} was saved.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error({ message: `Error saving ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            // delete(keys) {
            //     const self = this;
            //     let apiPromise = self.$api.MoneyMovementApprovalRangesApi.deleteMoneyMovementApprovalRanges(keys);
            //     return self.$rqBusy.wait(apiPromise)
            //         .then(() => {
            //             self.removeItems(keys);
            //             let message = keys.length > 1
            //                 ? `${keys.length} ${self.itemTypeNamePlural} were deleted.`
            //                 : `${self.itemTypeName} was deleted.`
            //             self.$toast.success(message);
            //             return true;
            //         })
            //         .catch(error => {
            //             if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
            //                 self.$dialog.messageBox(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
            //             } else {
            //                 self.$toast.error(`Error deleting ${self.itemTypeName}.`);
            //             }
            //             console.error(error);
            //             return error;
            //         });
            // },

            userRangeExists(targetItem) {
                const self = this;
                return _.some(self.items, item => 
                    item.moneyMovementApprovalRangeID !== targetItem.moneyMovementApprovalRangeID
                    && item.approverUsersID === targetItem.approverUsersID
                    && item.moneyMovementType === targetItem.moneyMovementType
                    // && _.intersection(
                    //     _.filter(self.allBranches, b => item.allBranches
                    //         ? _.includes(item.regionList, b.regionID)
                    //         : _.includes(item.branchList, b.id)),
                    //     _.filter(self.allBranches, b => targetItem.allBranches
                    //         ? _.includes(targetItem.regionList, b.regionID)
                    //         : _.includes(targetItem.branchList, b.id))
                    //     ).length > 0
                    );
            },

            getBranchDisplayText(item) {
                const self = this;
                if((_.isEmpty(item.branchList) && !item.allBranches) || _.isNil(item.regionList)) return;
                let selectedBranches = _.filter(self.allBranches, b => item.allBranches
                    ? _.includes(item.regionList, b.regionID)
                    : _.includes(item.branchList, b.id));
                return _.joinParts(_.map(selectedBranches, "name"), ", ");
            },

            refresh() {
                if(_.isNull(this.gridInstance)) return;
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            removeItems(keys) {
                const self = this;
                _.forEach(keys, key => {
                    let itemIndex = _.findIndex(self.items, item => item[self.itemKey] === key);
                    if(itemIndex >= 0) self.items.splice(itemIndex, 1);
                });
                self.refresh();
            }
        }
    }
</script>

<template>
    <div class="content-wrapper">
        <rq-page-section title="Custom Data" headerSize="lg" header-only borderless>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">Custom data tied to file.</div>
            </template>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn automation_id="btn_add" variant="primary" @click="onAddClicked" :disabled="disabledAdd">Add</b-btn>
                    </li>
                    <li class="nav-item">
                        <b-btn automation_id="btn_view_page" variant="primary" @click="onViewPageClicked" :disabled="disabledAdd">View Page</b-btn>
                    </li>
                </ul>
                 <ul class="nav">
                    <li class="nav-item">
                        <dx-select-box
                            :input-attr="$utils.idAttrs('drp_pages')"
                            :items="referenceTable"
                            value-expr="id"
                            display-expr="name"
                            :search-enabled="false"
                            v-model="selectedReferenceTable"
                        />
                    </li>
                </ul>
            </template>
        </rq-page-section>
        <div class="rq-master-detail-container rq-master-detail-50-50">
            <section v-show="showTabGrid" class="rq-container">
                <rqdx-action-data-grid
                    ref="tabDataGrid"
                    automation_id="tbl_customDataTab"
                    :actions="tabSelectionActions"
                    :config="tabGridConfig"
                    :data-source="tabGridDataSource"
                    @edit="onEditTab"
                    @delete="onDeleteTab"
                    @configure="onConfigureTab"
                    @selectionChanged="onSelectedTabChanged"
                    hide-default-actions
                    hide-search
                />
            </section>
            <section v-show="showDefinitionGrid" class="rq-container mt-2">
                <rqdx-action-data-grid
                    :title="definitionGridTitle"
                    ref="definitionDataGrid"
                    automation_id="tbl_customDataDefinition"
                    :actions="definitionSelectionActions"
                    :config="definitionGridConfig"
                    :data-source="definitionGridDataSource"
                    @edit="onEditDefinition"
                    @delete="onDeleteDefinition"
                    :hide-toolbar="!showTabGrid"
                    :force-floating-header="!showTabGrid"
                    hide-default-actions
                    hide-search>
                    <template #toolbar>
                        <ul class="nav">
                            <li class="nav-item">
                                <b-btn
                                    automation_id="btn_execute"
                                    variant="primary"
                                    @click="onAddDefinitionClick">Add
                                </b-btn>
                            </li>
                        </ul>
                    </template>
                </rqdx-action-data-grid>
            </section>
        </div>

    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import GridInvokerMixin from "@/shared/mixins/GridInvokerMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { CustomDataTab, CustomDataDefinition, CustomDataReferenceTable, CustomDataType, CustomDataListValue }  from "@/shared/components/customdata/models";
    import CustomDataDefinitionForm  from "./CustomDataDefinitionForm";
    import CustomDataTabForm  from "./CustomDataTabForm";

    export default {
        name:"CustomData",
        mixins: [GridInvokerMixin({ grid: "definitionDataGrid" })],
        data () {
            return {
                selectedItem: {},
                validationErrors: [],
                verifiedItem: {},
                tabItems: [],
                definitionItems: [],
                disabledAdd: true,
                selectedReferenceTable: null,
                selectedTabID: null,
                definitionGridTitle: "Custom field",
                showDefinitionGridAddButton: false,
            };
        },
        computed: {
            ...mapGetters([
                "lookupHelpers"
            ]),
            referenceTable() { return   _.filter(CustomDataReferenceTable.lookupItems, item => item.name !== '');},
            customDataType() { return   CustomDataType.lookupItems;},
            hideSearch(){ return true;},
            tabKey() { return 'customDataTabID'; },
            tabName() { return 'Custom Tab'},
            tabNamePlural() { return 'Custom Tabs'; },
            tabGridInstance() { return _.get(this, "$refs.tabDataGrid.gridInstance", null) || {}; },
            tabSelectionActions() {
                return [
                        { name: "editTab", text: "Edit Tab", eventName: "edit", requireSelection: true, tooltip: `Edit Tab` },
                         { name: "configure", text: "Configure", eventName: "configure", requireSelection: true, tooltip: `Configure` },
                        { name: "deleteField", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete` }];
            },
            definitionKey() { return 'customDataDefinitionID'; },
            definitionNamePlural() { return 'Custom Fields'; },
            definitionName() { return 'Custom Field'},
            definitionGridInstance() { return _.get(this, "$refs.definitionDataGrid.gridInstance", null) || {}; },
            definitionSelectionActions() {
                return [
                        { name: "editField", text: "Edit Field", eventName: "edit", requireSelection: true, tooltip: `Edit Field` },
                        { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete` }];
            },
            showTabGrid() { return this.selectedReferenceTable === CustomDataReferenceTable.Orders; },
            showDefinitionGrid() { return !_.isNil(this.selectedReferenceTable) && (!this.showTabGrid || !_.isNil(this.selectedTabID)); },
        },
        watch:{
            selectedReferenceTable(newValue, oldValue) {
                if (newValue === oldValue) { return; }
                const self = this;
                self.disabledAdd = _.isNil(self.selectedReferenceTable);
                if (self.selectedReferenceTable === CustomDataReferenceTable.Orders){
                    this.fetchTabData(newValue);
                    self.definitionGridTitle = "Custom Tab";
                    self.clearDefinitionGrid();
                }else{
                    self.selectedTabID = null;
                    self.definitionGridTitle = "";
                    self.fetchDefinitionDataByReferenceTable(newValue);
                }
            }
        },
        created(){
            this.initNonReactiveVariables();
            this.initGridConfig();
        },
        methods: {

            initNonReactiveVariables() {
                const self = this;
                self.regions = self.lookupHelpers.getRegions();
            },

            initGridConfig(){
                const self = this;
                self.tabGridConfig = {
                    columns: [
                        { dataField: "label", caption: "Tab Label" },
                        {
                            dataField: "regionID",
                            caption: "Region",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            lookup: {
                                loadMode: "raw",
                                dataSource: self.regions,
                                displayExpr: "displayName",
                                valueExpr: "regionID"
                            },
                            setCellValue: function(rowData, value) {
                                rowData.regionID = value;
                            },

                        }  ,
                        { dataField: "display" , caption: "Enabled", cellTemplate: DxGridUtils.boolCellTemplate},
                    ],
                    rowDragging: {
                        allowReordering: true,
                        dropFeedbackMode: "push",
                        onReorder(e) {
                            self.repositionTabs(e.fromIndex, e.toIndex);
                        }
                    }
                };
                self.tabGridDataSource = {
                    loadMode: "raw",
                    key: self.tabKey,
                    load () {
                        return Promise.resolve(self.tabItems);
                    },
                };
                self.definitionGridConfig = {
                    columns: [
                        { dataField: "label", caption: "Field Label" },
                        {
                            dataField: "customDataType",
                            caption: "Data Type",
                            lookup: {
                                loadMode: "raw",
                                dataSource: self.customDataType,
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                        },
                        { dataField: "maxLength" , caption: "Max length" },
                        {
                            dataField: "gridListValueRequired",
                            dataType: "boolean",
                            caption: "Required",
                            cellTemplate: DxGridUtils.boolCellTemplate
                        },
                    ],
                    rowDragging: {
                        allowReordering: true,
                        dropFeedbackMode: "push",
                        onReorder(e) {
                            self.repositionDefinitions(e.fromIndex, e.toIndex);
                        }
                    }
                };

                self.definitionGridDataSource = {
                    loadMode: "raw",
                    key: self.definitionKey,
                    load () {
                        return Promise.resolve(self.definitionItems);
                    },
                };
            },

            loadDialog(component, item) {
                const self = this;
                let typeName = component.name === "CustomDataDefinitionForm" ? "Field" : "Tab";
                let onOk = (e, addAnother=false) => {
                    let form = e.component;
                    let item = form.item;
                    let listValues = form.originalListValues;
                    form.v$.$touch();
                    self.validationErrors = [];
                    self.verifiedItem = {};
                    if (form.v$.$error) {
                        form.validationErrors = _.concat(item.validationErrors, self.validationErrors);
                        form.verifiedItem = _.merge(item.verifiedItem, self.verifiedItem);
                        return false;
                    }
                    if (typeName === 'Field'){
                        self.saveDefinition(item, form, listValues);
                    }else{
                        self.saveTab(item, form, addAnother);
                    }
                };

                let onCancel = e => {
                    return true;
                }

                self.$dialog.open({
                    title: `${item.isNew ? "Add " + typeName: "Edit " + typeName}`,
                    width: 450,
                    adaptive: true,
                    resizeable: true,
                    scrollable: false,
                    component: component,
                    closeOnEsc: true,
                    props: { item },
                    buttons: [
                        { title: "Cancel", automationId: "btn_dm_modal_cancel", cssClass: "btn btn-secondary", onClick: onCancel},
                        { title: "Save", automationId: "btn_dm_modal_save", cssClass: "btn btn-primary", onClick: (e) => { return onOk(e, false); } }
                    ]
                });
            },

            saveListValues(listValues, item){
                const self = this;
                let items = [];
                let parentID = null;
                if (item.customDataType === CustomDataType.ChildPickList){
                    parentID = item.parentSelectionID;
                }
                _.forEach(listValues, (i) => {
                    i.parentID = parentID;
                    i.customDataDefinitionID = item.customDataDefinitionID;
                    items.push(i.toDataObject())

                });
                let apiPromise = self.$api.CustomDataApi.saveListValues(items, null);
                self.$rqBusy.wait(apiPromise)
                    .then(item => {
                        //self.$toast.success({ message: `${self.definitionName} was saved.` });
                    })
                    .catch(err => {
                      //  self.$toast.error(err.errorMessage);
                    })
            },

            onAddClicked(){
                const self = this;
                if (self.showTabGrid){
                    self.tabGridInstance.deselectAll();
                    self.selectedItem = new CustomDataTab({referenceTable: self.selectedReferenceTable});
                    self.loadDialog(CustomDataTabForm, new CustomDataTab({referenceTable: self.selectedReferenceTable}) );
                } else {
                    self.onAddDefinitionClick();
                }
            },

            fetchTabData(referenceTable){
                const self = this;
                self.clearDefinitionGrid();
                let apiPromise = self.$api.CustomDataApi.getTabs(referenceTable);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.tabItems = _.map(result, i => new CustomDataTab(i));
                        self.refreshTabGrid();
                        return { data: self.tabItems, totalCount: self.tabItems.length };
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Tab information.` });
                        return error;
                    });

            },

            onEditTab(e){
                const self = this;
                self.selectedItem = new CustomDataTab(e.data);
                self.loadDialog(CustomDataTabForm, new CustomDataTab(e.data));
            },

            onConfigureTab(e){
                const self = this;
                self.clearDefinitionGrid();
                self.definitionGridTitle = "Custom Tab - " + e.data.label;
                self.selectedTabID = e.data.customDataTabID
                self.fetchDefinitionDataByTab(self.selectedTabID);
            },

            onSelectedTabChanged(e) {
                const self = this;
                self.selectedTabID = null;
            },

            onDeleteTab(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let keys = _.map(items, self.tabKey);
                let ok = function (args) {
                    let apiPromise = self.$api.CustomDataApi.deleteTabs(keys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            self.deleteItem(self.tabKey, self.tabItems, keys);
                            let message = key.length > 1 ? `${key.length} ${self.tabNamePlural} were deleted.` : `${self.tabName} was deleted.`
                            self.$toast.success(message);
                            return true;
                        })
                        .catch(error => {
                            if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.tabnNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.tabName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.refreshTabGrid();
                        });
                }

                self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.tabNamePlural : self.tabName}?`, ok, null, { cancelTitle: "No", okTitle: "Yes"});
            },

            saveTab(item, form, addAnother){
                const self = this;
                let original = self.selectedItem;
                let changes = self.getAuditChanges(original.toDataObject(), item.toDataObject());
                if (changes.length == 0) {
                    self.$toast.info({ message: "No changes detected" });
                    return;
                }
                let isNew = item.isNew ? true : false;
                let apiPromise = self.$api.CustomDataApi.saveTab(item.toDataObject(), changes);
                self.$rqBusy.wait(apiPromise)
                    .then(item => {
                        if (isNew) {
                            self.addItem(self.tabItems, new CustomDataTab(item));
                        } else {
                            self.updateItem(self.tabKey, self.tabItems, item);
                        }
                        self.$toast.success({ message: `${self.tabName} ${item.label} was saved.` });
                        self.$dialog.close();
                    })
                    .catch(err => {
                        form.validationErrors = [err.errorMessage];
                    })
                        .finally(() => {
                        self.refreshTabGrid();
                    });
            },

            refreshTabGrid() {
                this.tabGridInstance.clearSelection();
                this.tabGridInstance.refresh();
            },

            addItem(items, item) {
                items.push(item);
            },

            updateItem(key, items, item) {
                let editItem = _.find(items, (i) => {
                    return _.parseNumber(_.get(i, key, -1), -1) == _.parseNumber(_.get(item, key, -1), -1);
                });
                _.assign(editItem, item);
            },

            deleteItem(key, items, keys) {
                _.each(keys, k => {
                    _.remove(items, (i) => {return _.parseNumber(_.get(i, key, -1), -1) == k;});
                });
            },

            fetchDefinitionDataByReferenceTable(referenceTableID){
                const self = this;
                let apiPromise = self.$api.CustomDataApi.getDefinitionsByReferenceTable(referenceTableID);
                return self.fetchDefinitionData(apiPromise);
            },

            fetchDefinitionDataByTab(customDataTabID){
                const self = this;
                let apiPromise = self.$api.CustomDataApi.getDefinitionsByCustomDataTabID(customDataTabID);
                return self.fetchDefinitionData(apiPromise);
            },

            fetchDefinitionData(apiPromise){
                const self = this;
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.definitionItems = _.map(result, i => new CustomDataDefinition(i));
                        self.refreshDefinitionGrid();
                        return { data: self.definitionItems, totalCount: self.definitionItems.length };
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading Field information.` });
                        return error;
                    });
            },

            onAddDefinitionClick(){
                const self = this;
                self.definitionGridInstance.deselectAll();
                self.selectedItem = new CustomDataDefinition({referenceTable: self.selectedReferenceTable,
                                                              customDataTabID: self.selectedTabID});
                self.loadDialog(CustomDataDefinitionForm, new CustomDataDefinition({referenceTable: self.selectedReferenceTable,
                                                              customDataTabID: self.selectedTabID}) );
            },

            onEditDefinition(e){
                const self = this;
                self.selectedItem = new CustomDataDefinition(e.data);
                self.loadDialog(CustomDataDefinitionForm, new CustomDataDefinition(e.data));
            },

            saveDefinition(item, form, listValues){
                const self = this;
                let original = self.selectedItem;
                let changes = self.getAuditChanges(original.toDataObject(), item.toDataObject());
                let isNew = item.isNew ? true : false;
                let apiPromise = self.$api.CustomDataApi.saveDefinition(item.toDataObject(), changes);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let i = new CustomDataDefinition(result)
                        if (isNew) {
                            self.addItem(self.definitionItems, i);
                        } else {
                            self.updateItem(self.definitionKey, self.definitionItems, i);
                        }
                        i.parentSelectionID = item.parentSelectionID;
                        self.saveListValues(listValues, i);
                        self.$toast.success({ message: `${self.definitionName} ${item.label} was saved.` });
                        self.$dialog.close();
                    })
                    .catch(err => {
                        form.validationErrors = [err.errorMessage];
                    })
                        .finally(() => {
                        self.refreshDefinitionGrid();
                    });
            },

            isDeleteValid(keys, definitionItems){
                let result = true;
                 let selectedItems = _.map(keys, function (key) {
                    return _.find(definitionItems, (i) => { return i.customDataDefinitionID === key; })
                });

                _.forEach(keys, (key) => {
                    let definition = _.find(selectedItems, (i) => {
                            return i.customDataDefinitionID === key;
                    });
                    if (definition.customDataType === CustomDataType.ChildPickList){
                        let items = _.filter(selectedItems, (i) => { return i.customDataDefinitionID === definition.parentID;});
                        if (items.length === 0){
                            result = false;
                            return;
                        }
                    }
                });
                return result;
            },

            onDeleteDefinition(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let keys = _.map(items, self.definitionKey);
                let ok = function (args) {

                    if (!self.isDeleteValid(keys, self.definitionItems)){
                        self.$toast.error({ message: `One or more of the selected items can not be deleted.`});
                        self.$dialog.close();
                        return false;
                    }
                    let apiPromise = self.$api.CustomDataApi.deleteDefinitions(keys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(key => {
                            self.deleteItem(self.definitionKey, self.definitionItems, keys);
                            let message = key.length > 1 ? `${key.length} ${self.definitionNamePlural} were deleted.` : `${self.definitionName} was deleted.`
                            self.$toast.success(message);
                            self.refreshDefinitionGrid();
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${self.definitionName}.` });
                            return error;
                        });
                }

                self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.definitionNamePlural : self.definitionName}?`, ok, null, { cancelTitle: "No", okTitle: "Yes"});
            },


            refreshDefinitionGrid() {
                this.definitionGridInstance.clearSelection();
                this.definitionGridInstance.refresh();
            },

            clearDefinitionGrid(){
                const self = this;
                self.definitionItems = [];
                self.definitionGridInstance.refresh();
            },


            onViewPageClicked(e) {
                let routeTo = "";
                switch(this.selectedReferenceTable){
                    case CustomDataReferenceTable.Orders:
                        routeTo = { name: "oe:custom", params: { showButton: true } };
                        break;
                    case CustomDataReferenceTable.FileMain:
                        routeTo = { name: "oe:main", hash: "#custom-data" };
                        break;
                    case CustomDataReferenceTable.Buyer:
                        routeTo = { name: "oe:oc:contacts" };
                        break;
                    case CustomDataReferenceTable.Seller:
                        routeTo = { name: "oe:oc:contacts" };
                        break;
                    case CustomDataReferenceTable.OrderContacts:
                        routeTo = { name: "oe:oc:contacts" };
                        break;
                    case CustomDataReferenceTable.SaleLoan:
                        routeTo = { name: "oe:ol:loans", hash: "#custom-data" };
                        break;
                    case CustomDataReferenceTable.Property:
                        routeTo = { name: "oe:op:properties", hash: "#custom-data" };
                        break;
                    case CustomDataReferenceTable.Invoices:
                        routeTo = { name: "o-inv:invoices", hash: "#custom-data" };
                        break;

                }

                this.$router.push(routeTo);
            },


            repositionDefinitions(fromIndex, toIndex) {
                const self = this;
                let removed = self.definitionItems.splice(fromIndex, 1);
                self.definitionItems.splice(toIndex, 0, removed[0]);
                _.forEach(self.definitionItems, (item, index) => {
                    item.sequence = index+1;
                    item.positionOrdinal = item.sequence;
                });
                self.definitionGridInstance.refresh();
                self.saveRepositionDefinitions();
            },

             saveRepositionDefinitions(){
                const self = this;
                let items = [];
                _.forEach(this.definitionItems, (item) => {
                    items.push(item.toDataObject())
                });
                let apiPromise = self.$api.CustomDataApi.saveDefinitions(items, null);
                self.$rqBusy.wait(apiPromise)
                    .then(item => {
                    })
                    .catch(err => {
                        self.$toast.error(err.errorMessage);
                    })
            },

            repositionTabs(fromIndex, toIndex) {
                const self = this;
                let removed = self.tabItems.splice(fromIndex, 1);
                self.tabItems.splice(toIndex, 0, removed[0]);
                _.forEach(self.tabItems, (item, index) => {
                    item.sequence = index+1;
                    item.positionOrdinal = item.sequence;
                    console.log(item.label + ' = ' + item.sequence)
                });
                self.tabGridInstance.refresh();
                self.saveRepositionTabs();
            },

            saveRepositionTabs(){
                const self = this;
                let apiPromise = self.$api.CustomDataApi.saveTabs(self.tabItems, null);
                self.$rqBusy.wait(apiPromise)
                    .then(item => {
                    })
                    .catch(err => {
                        self.$toast.error(err.errorMessage);
                    })
            },
        }
    }
</script>

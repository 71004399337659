<template>    
    <div class="content-wrapper">        
        <rqdx-action-data-grid 
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions" 
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            target-inactive-column="inactive"
            :strikethrough-if-true="['inactive']"
            @activate="onActivateItem"
            @delete="onDeleteItem"  
            show-include-inactive         
            hide-show-column-chooser
            integrated-search
            :rq-editable="!isReadOnly"
            rq-filters
        />
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { FundTypeDto }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"FundTypeList",
        props: {
            selectedId: { type: Number, default: -1 },
            readOnly: { type: Boolean, default: false } 
        },
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                addEventName: ""
            };
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.initListeners();
            this.fetchData();
        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        computed: {
            ...mapState({
                readOnlyState: state => state.isPageReadOnly,
            }),
            isReadOnly (){ return this.readOnlyState && this.readOnly;  },
            gridInstance() {
                return _.get(this, "$refs.dataGrid.gridInstance", null);
            },
            errorMsg(){ return _.get(this, "validationErrors.length", 0) > 0 ?  "Please correct the highlighted errors on screen to continue." : _.get(this, "validationErrors")[0]; }  
        },
         watch: {
            validationErrors: function () {
                const self = this;  
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });   
            }
        },
        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
            initNonReactiveVariables() {
                const self = this;
                this.itemTypeName = _.get(this.$route.meta, "itemTypeName");
                this.itemTypeNamePlural = _.get(this.$route.meta, "label");
                this.itemKey = _.get(this.$route.meta, "itemKey");
                this.selectionActions = [
                    { 
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${this.itemTypeName}`,
                        disabled: function(e) {
                            return self.isReadOnly;
                        }
                    },
                    { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['inactive', true]); } },
                    { name: "inactivate", text: "Inactivate", eventName: "activate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['inactive', false]); } }

                ];
            },
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    sorting: { mode: 'single' },
                    columns: [
                        { 
                            dataField: self.itemKey, 
                            visible: false,
                            allowEditing: false,
                            showInColumnChooser: false,
                        },                        
                        {
                            dataField: "description",
                            sortOrder: "asc",
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: self.isNotDuplicateDescription,
                                    message: "Description already exists"
                                } 
                            ], 
                            editorOptions: {
                                maxLength: 20
                            },
                        },
                        {
                            caption: "Hidden",
                            dataField: "hidden",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            allowEditing: false,
                            visible: false
                        },
                        { dataField: "isSystem", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate, allowEditing: false},
                        { dataField: "inactive", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate },
                    ],
                    onRowUpdating(e) {
                        if (!_.hasIn(e.newData, "description")) return;
                        if (_.isEqual(_.get(e.newData, "description"), _.get(e.oldData, "description"))) return;
                        e.cancel = new Promise((resolve, reject) => {
                            self.$dialog.confirm(
                                "Confirm Edit", 
                                `Updating ${self.itemTypeName} will change existing files. Do you want to continue?`, 
                                () => resolve(false), //continue with edit
                                () => resolve(true), //cancel edit
                                { cancelTitle: 'No', okTitle: 'Yes'});  
                        });
                    },
                    onInitNewRow: e => {
                        e.data.hidden = false;
                        e.data.inactive = false;
                        e.data.isSystem = false;
                    },
                    onEditorPreparing: e => {
                        if (e.parentType !== "dataRow" || e.dataField !== "description") return;
                        if (e.dataField === "description") e.editorOptions.disabled = e.row.data.isSystem;
                    }
                };

                 self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },
            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },
            fetchData () {
                const self = this;
                let apiPromise = self.$api.TypeFundsApi.getTypeFunds();
                return self.$rqBusy.wait(apiPromise)
                        .then(result => { 
                            self.items = _.map(result, i => new FundTypeDto(i));
                            self.refresh();
                        }).catch(error => {
                            self.items = [];
                            self.$toast.error({ message: `Error Loading ${self.itemTypeNamePlural}.` });
                            return error;
                        });
            },
            isNotDuplicateDescription(item) {
                const self = this;
                let dup = {};
                dup = _.find(self.items, (i) => { 
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(item.data.description))
                            && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(item.data, self.itemKey, -1), -1); 
                });

                return dup ? false : true;
            },
            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow()
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let apiPromises = [];
                    _.forEach(items, (item) => { 
                        apiPromises.push(self.$api.TypeFundsApi.deleteTypeFund(item.typeFundID));
                    });
                    return self.$rqBusy.wait(Promise.all(apiPromises))
                        .then(key => {
                            self.deleteItem([...key]);
                            let message = key.length > 1 ? `${key.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message }); 
                            return true;}
                        )
                        .catch(error => {                            
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {                            
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);                                    
                            } else {                            
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });                          
                            }
                            return error;       
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});    
            },          
            refresh() {
                const self = this;
                self.gridInstance.clearSelection();
                self.gridInstance.refresh();
            },
            deleteItem(keys) {
                const self = this;
                _.each(keys, k => {
                    _.remove(self.items, (i) => {return _.parseNumber(_.get(i, self.itemKey, -2), -2) == k;});
                });                
                self.refresh();
            },
            save(item, changes){
                const self = this;
                if(changes.length === 0) {
                    return Promise.resolve(item);
                }
                let apiPromise = self.$api.TypeFundsApi.saveTypeFund(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success({ message: `${self.itemTypeName} ${item.description} was saved.` });
                        return result;
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.itemTypeName}.`);
                        return error;
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },
            onGridInsert(values) {
                const self = this;
                let newItem = new FundTypeDto(values);
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.save(newItem, changes)
                    .then(result => {
                        self.items.push(new FundTypeDto(result));
                    });
            },
            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.typeFundID === key);                
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new FundTypeDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.save(updatedItem, changes)
                    .then(result => {
                        self.items[itemIndex] = updatedItem;
                    });
            },
             onActivateItem(e){
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                let verb = _.every(items, ['inactive', true]) ? "Activate" : "Inactivate";

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.toggleIsInactive(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            toggleIsInactive(keys, verb) {
                const self = this;
                let apiPromise = self.$api.TypeFundsApi.toggleIsInactive(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.fetchData();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },
        }
    }
</script>

<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            @action="onGridAction"
            fixed-header
            rq-filters
            integrated-search
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { StandardLanguageCategorySecurityUserDto }  from "../../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"StandardLanguageCategoryUserSecurityForm",
        props: {
            standardLanguageCategoryID: { type: Number, default: 0 },
            defaultUserSecurity: { type: Number, default: 0 },
            userGroups: { type: Array, default: () => [] }
        },
        data () {
            return {
                validationErrors: [],
                itemTypeName: "Clause Category Security User",
                itemTypeNamePlural: "Clause Category Security Users" ,
                itemKey: "usersID",
                items: [],
                originalItems: [],
                searchText: "",
                addEventName: ""
            };
        },

        watch: {
            searchText(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.gridSearch(newValue);
            }
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            regions() { return _.map(this.lookupHelpers.getRegions(), r => ({ ...r, displayLabel: `${r.regID} - ${r.description}` })); },
            selectionActions() {
                return [{
                    name: "full-access",
                    text: "Set Full Access",
                    allowMultiSelection: true,
                    tooltip: "Full Access"
                },
                {
                    name: "no-access",
                    text: "Set No Access",
                    allowMultiSelection: true,
                    tooltip: "No Access"
                }];
            },
        },

        created(){
            const self = this;
            self.initGridConfig();
            self.fetchData();
        },

        beforeUnmount() {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.StandardLanguageCategoryApi.getSecurityUsers(self.standardLanguageCategoryID);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new StandardLanguageCategorySecurityUserDto(i, self.defaultUserSecurity));
                        self.originalItems = _.map(self.items, i => new StandardLanguageCategorySecurityUserDto(i, self.defaultUserSecurity));
                        self.refresh();
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        { dataField: "usersID", visible: false, allowEditing: false, allowSearch: false },
                        {
                            dataField: "userFullName", sortIndex: 0, sortOrder: "asc"
                        },
                        {   dataField: "regionDisplay",
                            caption: "User Default Region",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            rqFilter: {
                                displayExpr: "displayLabel",
                                valueExpr: "displayLabel",
                                filterType: "tags",
                                dataSource: {
                                    loadMode: "raw",
                                    load() {
                                        return self.regions;
                                    }
                                }
                            }
                        },
                        {   dataField: "userGroupName",
                            caption: "User Security Role",
                            rqFilter: {
                                displayExpr: "login",
                                valueExpr: "login",
                                filterType: "tags",
                                dataSource: {
                                    loadMode: "raw",
                                    load() {
                                        return self.userGroups;
                                    }
                                }
                            }
                        },
                        {
                            dataField: "securityAccessName",
                            caption: "Security Access",
                            allowSearch: false
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],
                };
                self.gridDataSource = {
                    key: self.itemKey,
                    load: () => Promise.resolve(self.items),
                };
            },

            onGridAction(e) {
                const self = this;
                let items = e.data;
                switch(e.action.name) {
                    case "no-access":
                        self.setAccess(items, 0, 'No Access');
                        break;
                    case "full-access":
                        self.setAccess(items, 2, 'Full Access');
                        break;
                }
            },

            refresh() {
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            setAccess(itemsToUpdate,value,text) {
                const self = this;
                _.forEach(itemsToUpdate, (item) =>  {
                    let listItem = _.find(self.items, li => li.usersID === item.usersID);
                    _.set(listItem, 'securityAccess', value);
                    _.set(listItem, 'securityAccessName', text);
                });
                self.refresh();
            },

            getRecordsToUpdate() {
                const self = this;

                let categoryDiff = _.makeArrayDiffFunc(
                    ['standardLanguageCategoryID', 'usersID'],
                    'securityAccess'
                );

                let changes = categoryDiff(self.items, self.originalItems);

                return _.concat(changes.updated, changes.inserted);
            },

            save() {
                const self = this;

                let recordsToProcess = self.getRecordsToUpdate();

                if (_.isEmpty(recordsToProcess)) return Promise.resolve(true);

                let apiPromise = self.$api.StandardLanguageCategoryApi
                    .saveSecurityUsers(self.standardLanguageCategoryID, recordsToProcess)
                    .then((result) => {
                        self.$toast.success(`Category Security Users saved.`);
                        return result;
                    })
                    .catch((error) => {
                        self.$toast.error({ message: `Error saving Category Security Users.` });
                        return false;
                    })

                return self.$rqBusy.wait(apiPromise);
            }
        }
    }
</script>

<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            v-model:search-value="searchText"
            v-model:validationErrors="validationErrors"
            :strikethrough-if-true="['inactive']"
            target-inactive-column="inactive"
            integrated-search
            rq-filters
            rq-editable
            @delete="onDeleteItem"
            @activate="onActivateItem"
            show-include-inactive
        >
        </rqdx-action-data-grid>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DxGridUtils from "@/shared/utilities/DxGridUtils";
import { EscheatDormancyPeriodDto } from "../models";
import { useGridInvokerMethods } from "@/shared/composables/";

export default {
    name: "EscheatDormancyPeriodList",
    setup() {
        const { dataGrid, invokeGridMethod, invokeGridComponentMethod } =
        useGridInvokerMethods();

        return {
            dataGrid,
            invokeGridMethod,
            invokeGridComponentMethod,
        };
    },
    data() {
        return {
            items: [],
            selectedItem: {},
            validationErrors: [],
            addEventName: "",
            validationContext: {},
            searchText: "",
        };
    },

    computed: {
        ...mapState({
            user: (state) => state.authentication.session.user,
        }),
        ...mapGetters(["lookupHelpers", "lookupItems"]),
        itemKey() {
            return _.get(this.$route.meta, "itemKey") || "";
        },
        itemTypeNamePlural() {
            return _.get(this.$route.meta, "label") || "";
        },
        itemTypeName() {
            return _.get(this.$route.meta, "itemTypeName") || "";
        },
        itemTypeDescription() {
            return _.get(this.$route.meta, "itemTypeDescription") || "";
        },
        gridInstance() {
            return _.get(this.$refs, "dataGrid.gridInstance", null);
        },
        selectionActions() {
            var self = this;
            return [
                {
                    name: "activate",
                    text: "Activate",
                    eventName: "activate",
                    requireSelection: true,
                    tooltip: `Activate ${this.itemTypeName}`,
                    allowMultiSelection: true,
                    disabled: function (e) {
                        return (
                        _.some(e.data, (item) => {
                            return item.isSystem == true;
                        }) || !_.every(e.data, ["inactive", true])
                        );
                    },
                },
                {
                    name: "inactivate",
                    text: "Inactivate",
                    eventName: "activate",
                    requireSelection: true,
                    tooltip: `Inactivate ${this.itemTypeName}`,
                    allowMultiSelection: true,
                    disabled: function (e) {
                        return (
                        _.some(e.data, (item) => {
                            return item.isSystem == true;
                        }) || !_.every(e.data, ["inactive", false])
                        );
                    },
                },
            ];
        },
        states() { return this.lookupHelpers.getStatesWithID() },
    },

    watch: {
        validationErrors(newValue, oldValue) {
            const self = this;
            self.$events.emit("update-config-error", {
                message: "Please correct the highlighted errors on screen to continue.",
                hasError: self.validationErrors.length > 0,
            });
        },
    },

    created() {
        const self = this;
        self.initGridConfig();
        self.initListeners();
        self.fetchData();
    },

    beforeUnmount() {
        this.$events.off(this.addEventName, this.onAddItem);
    },
    methods: {
        elementName(prefix = "", suffix = "") {
            return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`);
        },

        initGridConfig() {
            const self = this;

            let uniqueStateRule = {
                type: "custom",
                message: "State already exists",
                validationCallback: (params) =>
                !self.checkEscheatDormancyPeriodExists(params.data, "usStatesID"),
            };
            let isPositiveRule = {
                type: "custom",
                message: "Positive numbers only.",
                validationCallback: (params) => params.data.monthsAfterCheckDate >= 0,
            };
            self.gridConfig = {
                sorting: { mode: "single" },
                columns: [
                {
                    dataField: self.itemKey,
                    visible: false,
                    allowEditing: false,
                    showInColumnChooser: false,
                },
                {
                    dataField: "usStatesID",
                    caption: "State",
                    width: 150,
                    minWidth: 150,
                    alignment: "left",
                    validationRules: [ { type: "required" }, uniqueStateRule ],
                    setCellValue(rowData, value) {
                        rowData.usStatesID = value;
                    },
                    lookup: {
                        dataSource: self.states,
                        displayExpr: "name",
                        valueExpr: "id"
                    }
                },
                {
                    dataField: "monthsAfterCheckDate",
                    dataType: "number",
                    caption: "Months After Check Date",
                    alignment: "left",
                    validationRules: [{ type: "required" }, isPositiveRule ],
                    editorOptions: { maxLength: 2 }
                },
                {
                    dataField: "inactive",
                    dataType: "boolean",
                    cellTemplate: DxGridUtils.boolCellTemplate,
                },
                ],
                onRowUpdating: self.onRowUpdating,
                onInitNewRow: (e) => {
                    e.data.inactive = false;
                    e.data.escheatDormancyPeriodID = 0;
                },
                onEditorPreparing: (e) => {
                    if (e.parentType !== "dataRow") return;
                    if (e.dataField === "escheatDormancyPeriodID" || e.dataField === "isSystem") e.editorOptions.disabled = true;
                    if (
                        (e.dataField === "name") &&
                        e.row.data.escheatDormancyPeriodID >= 1 &&
                        e.row.data.escheatDormancyPeriodID <= 4
                    )
                        e.editorOptions.disabled = true;
                    if (e.dataField === "description" && e.row.data.escheatDormancyPeriodID == 4)
                        e.editorOptions.disabled = true;
                },
            };

            self.gridDataSource = {
                key: self.itemKey,
                load(loadOptions) {
                    return Promise.resolve(self.items);
                },
                insert: self.onGridInsert,
                update: self.onGridUpdate,
            };
        },

        initListeners() {
            this.addEventName = `add:${this.elementName()}`;
            this.$events.on(this.addEventName, this.onAddItem);
        },

        fetchData() {
            const self = this;
            let apiPromise = self.$api.EscheatDormancyPeriodApi.getEscheatDormancyPeriods();

            return self.$rqBusy
                .wait(apiPromise)
                .then((result) => {
                    self.items = _.map(result, (i) => new EscheatDormancyPeriodDto(i));
                    self.refresh();
                })
                .catch((error) => {
                    console.log(error);
                    self.$toast.error({
                        message: `Error loading ${self.itemTypeNamePlural}.`,
                    });
                    return error;
                });
        },

        onActivateItem(e) {
            if (!e || !e.data) return;
            const self = this;
            let items = e.data;
            let itemLabel = items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName;
            let verb = _.every(items, ["inactive", true]) ? "Activate" : "Inactivate";

            let okHandler = function (args) {
                let keys = _.map(items, self.itemKey);
                self.activate(keys, verb);
                return true;
            };

            self.$dialog.confirm(
                `Confirm ${verb}`,
                `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                okHandler,
                null,
                { cancelTitle: "No", okTitle: "Yes" }
            );
        },

        activate(keys, verb) {
            const self = this;
            let apiPromise = self.$api.EscheatDormancyPeriodApi.toggleIsInactive(keys);
            return self.$rqBusy
                .wait(apiPromise)
                .then(() => {
                    self.fetchData();
                    let message =
                        keys.length > 1
                        ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                        : `${self.itemTypeName} was ${verb}d.`;
                    self.$toast.success(message);
                    return true;
                })
                .catch((error) => {
                    self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                    console.error(error);
                    return error;
                });
        },

        onAddItem() {
            if (!this.gridInstance) return;
            this.gridInstance.addRow();
        },

        onDeleteItem(e) {
            if (!e || !e.data) return;

            const self = this;
            let items = e.data;
            let itemLabel = items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName;

            let okHandler = function (args) {
                let keys = _.map(items, self.itemKey);
                self.delete(keys);
                return true;
            };

            self.$dialog.confirm(
                "Confirm Delete",
                `Are you sure you want to delete the selected ${itemLabel}?`,
                okHandler,
                null,
                { cancelTitle: "No", okTitle: "Yes" }
            );
        },

        onGridInsert(values) {
            const self = this;
            let originalItem = new EscheatDormancyPeriodDto();
            let newItem = new EscheatDormancyPeriodDto(values);
            let changes = self.getAuditChanges(
                originalItem.toDataObject(),
                newItem.toDataObject()
            );
            return self.save(newItem, changes).then((result) => {
                self.items.push(new EscheatDormancyPeriodDto(result));
            });
        },

        onGridUpdate(key, values) {
            const self = this;
            let itemIndex = _.findIndex(
                self.items,
                (item) => item.escheatDormancyPeriodID === key
            );
            if (itemIndex < 0) return self.onGridInsert(values);

            let originalItem = _.cloneDeep(self.items[itemIndex]);
            let updatedItem = new EscheatDormancyPeriodDto(
                _.assign({}, self.items[itemIndex], values)
            );

            let changes = self.getAuditChanges(
                originalItem.toDataObject(),
                updatedItem.toDataObject()
            );

            self.save(updatedItem, changes).then((result) => {
                self.fetchData();
            });
        },

        save(item, changes) {
            const self = this;
            if (changes.length == 0) {
                return Promise.resolve(item);
            }
            let apiPromise = self.$api.EscheatDormancyPeriodApi.saveEscheatDormancyPeriod(
                item.toDataObject(),
                changes
            );
            return self.$rqBusy
                .wait(apiPromise)
                .then((result) => {
                self.$toast.success(`${self.itemTypeName} was saved.`);
                return result;
                })
                .catch((error) => {
                self.$toast.error({
                    message: `Error saving ${self.itemTypeNamePlural}.`,
                });
                return error;
                });
        },

        delete(keys) {
            const self = this;
            let apiPromise = self.$api.EscheatDormancyPeriodApi.deleteEscheatDormancyPeriods(keys);
            return self.$rqBusy
                .wait(apiPromise)
                .then(() => {
                    self.removeItems(keys);
                    let message =
                        keys.length > 1
                        ? `${keys.length} ${self.itemTypeNamePlural} were deleted.`
                        : `${self.itemTypeName} was deleted.`;
                    self.$toast.success(message);
                    return true;
                })
                .catch((error) => {
                    if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                        self.$dialog.messageBox(
                        `Delete Error`,
                        `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`
                        );
                    } else {
                        self.$toast.error(`Error deleting ${self.itemTypeName}.`);
                    }
                    console.error(error);
                    return error;
                });
        },

        removeItems(keys) {
            const self = this;
            _.forEach(keys, (key) => {
                let itemIndex = _.findIndex(
                self.items,
                (item) => item[self.itemKey] === key
                );
                if (itemIndex >= 0) self.items.splice(itemIndex, 1);
            });
            self.refresh();
        },

        refresh() {
            if (_.isNil(this.gridInstance)) return;
            this.gridInstance.clearSelection();
            this.gridInstance.refresh();
        },

        checkEscheatDormancyPeriodExists(targetItem, fieldToValidate) {
            const self = this;
            return _.some(
                self.items,
                (item) =>
                item.escheatDormancyPeriodID != targetItem.escheatDormancyPeriodID &&
                item[fieldToValidate] == targetItem[fieldToValidate]
            );
        },

        disableDeteleOption(e) {
            if (!e || !e.data) return true;

            const self = this;
            let items = e.data;

            return _.some(items, (item) => {
                return item.isSystem == true;
            });
        },
    },
};
</script>
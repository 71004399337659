import { TreeViewItemModel } from "@/shared/models/rq-ui";

export class ListCodesDto {
	constructor(options) {
        options = options || {};
        this.listCodeID = _.parseNumber(options.listCodeID, 0);
        this.description = options.description || null;
    }
    get isNew() { return this.listCodeID === 0; }
    get isValid() { return this.validationErrors.length === 0; }
    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.description)) {
            errorList.push("Name is required.");
        }
        return errorList;
    }
    toDataObject() { return _.toPlainObject(this); }
    toListItem() {
        return { itemID: this.listCodeID, itemName: this.description };
    }
    static fromListItem(listItem) {
        return new ListCodesDto({ listCodeID: listItem.itemID || 0, description: listItem.itemName || "" });
    }
}

export class BiSettingsDto {
	constructor(options) {
		options = options || {};
		this.exagoDashboardReport = options.exagoDashboardReport || null;
	}
}

export class SchedulerDefaultsDto {
	constructor(options) {
        options = options || {};
        this.schedulerDefaultsID = _.parseNumber(options.schedulerDefaultsID, 0);
        this.fileType = options.fileType || null;
        this.fileLocation = options.fileLocation || null;
        this.printer = options.printer || null;
        this.fromEmailAddress = options.fromEmailAddress || null;
        this.fromEmailName = options.fromEmailName || null;
        this.sMTPHost = options.sMTPHost || null;
        this.sMTPUserName = options.sMTPUserName || null;
        this.sMTPPort = options.sMTPPort || null;
        this.reportServerURL = options.reportServerURL || null;
        this.reportURL = options.reportURL || null;
        this.reportServerDomain = options.reportServerDomain || null;
        this.reportServerUserName = options.reportServerUserName || null;
        this.reportServerPassword = options.reportServerPassword || null;
    }
    get isNew() { return this.schedulerDefaultsID === 0; }
    toDataObject() { return _.toPlainObject(this); }
}

export class FileScanCategoryDto {
	constructor(options) {
        options = options || {};
        this.fileScanCategoryID = _.parseNumber(options.fileScanCategoryID, 0);
        this.categoryName = options.categoryName || null;
        this.regionID = _.parseNumber(options.regionID);
        this.regionDisplay = options.regionDisplay || null;
        this.systemCategory = options.systemCategory || null;
        this.defaultUserSecurityAccess = options.defaultUserSecurityAccess || null;
        this.userSecurityStatus = options.userSecurityStatus || null;
    }
    get isNew() { return this.fileScanCategoryID === 0; }
    toDataObject() { return _.toPlainObject(this); }
}

export class FileScanCategorySecurityUserDto {
	constructor(options) {
        options = options || {};
        this.fileScanCategorySecurityUserID = _.parseNumber(options.fileScanCategorySecurityUserID, 0);
        this.fileScanCategoryID = _.parseNumber(options.fileScanCategoryID, 0);
        this.usersID = _.parseNumber(options.usersID, 0);
        this.securityAccess = _.parseNumber(options.securityAccess, 0);
        this.userFullName = options.userFullName || null;
        this.securityAccessName = options.securityAccessName || null;
        this.regionDisplay = options.regionDisplay || null;
        this.userGroupName = options.userGroupName || null;
    }
    get isNew() { return this.fileScanCategorySecurityUserID === 0; }
    toDataObject() { return _.toPlainObject(this); }
}

export class FileScanDescriptionDto {
	constructor(options) {
        options = options || {};
        this.fileScanDescriptionID = _.parseNumber(options.fileScanDescriptionID, 0);
        this.description = options.description || null;
        this.regionID = _.parseNumber(options.regionID, 0);
        this.regionDisplay = options.regionDisplay || null;
        this.systemDescription = _.parseBool(options.systemDescription);
        this.sequence = _.parseNumber(options.sequence, 0);
    }

    get isNew() { return this.fileScanDescriptionID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.regionID == 0) {
            errorList.push("Region is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class FileScanDescriptionTaskDto {
	constructor(options) {
        options = options || {};
        this.fileScanDescriptionTaskID = _.parseNumber(options.fileScanDescriptionTaskID, 0);
        this.fileScanDescriptionID = _.parseNumber(options.fileScanDescriptionID, 0);
        this.fileScanDescription = options.fileScanDescription || null;
        this.actionEnumValue = _.parseNumber(options.actionEnumValue, 0);
        this.workflowTaskID = _.parseNumber(options.workflowTaskID, 0);
        this.workflowTaskName = options.workflowTaskName || "";
        this.addNote = _.parseBool(options.addNote);
        this.markTaskAsEnumValue = _.parseNumber(options.markTaskAsEnumValue, 0);
        this.isDeleted = _.parseBool(options.isDeleted, false);
        this.isDirty = _.parseBool(options.isDirty, false);
    }

    get isNew() { return this.fileScanDescriptionID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.fileScanDescriptionID == 0) {
            errorList.push("Description is required.");
        }
        if (this.actionEnumValue == 0) {
            errorList.push("Action is required.");
        }
        if (this.markTaskAsEnumValue == 0) {
            errorList.push("Mark As Task is required.");
        }
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}


export class DocumentTemplateDto {
	constructor(options) {
        options = options || {};
        this.documentTemplateID = _.parseNumber(options.documentTemplateID,0);
        this.regionID = _.parseNumber(options.regionID,0);
        this.description = options.description || null;
        this.defaultUserSecurityAccess = _.parseNumber(options.defaultUserSecurityAccess,-1);
        this.defaultUserSecurityAccess = options.defaultUserSecurityAccessText || null;
        this.defaultPCloserSecurityAccess = _.parseNumber(options.defaultPCloserSecurityAccess,0);
        this.documentSource = _.parseNumber(options.documentSource,2);
        this.documentType = _.parseNumber(options.documentType,0);
        this.isHidden = _.parseBool(options.isHidden);
        this.userSecurityStatus = _.parseNumber(options.userSecurityStatus,0);
        this.pCCloserSecurityStatus = _.parseNumber(options.pCCloserSecurityStatus,0);
        this.dateCreated = options.dateCreated || null;
        this.dateModified = options.dateModified || null;
        this.name = options.name || null;
        this.regID = _.parseNumber(options.regID,0);
        this.fileScanCategoryID = _.parseNumber(options.fileScanCategoryID, 0);
        this.userSecurityStatusText = options.userSecurityStatusText || null;
        this.sequence =  _.parseNumber(options.sequence,0);
        this.usStatesID = options.usStatesID || null;
        this.documentSourceText = options.documentSourceText || null;
        this.fileScanCategoryText = options.fileScanCategoryText || null;
        this.documentTags = options.documentTags || [];
        this.documentTagDisplay = options.documentTagDisplay || null;

    }

    get isNew() { return this.documentTemplateID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.regionID == 0) {
            errorList.push("Region is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class DocumentPackageDto {
	constructor(options) {
        options = options || {};
        this.documentPackageID = _.parseNumber(options.documentPackageID,0);
        this.regionID = _.parseNumber(options.regionID,0);
        this.regionDisplay = options.regionDisplay || null;
        this.description = options.description || null;
        this.defaultUserSecurityAccess = _.parseNumber(options.defaultUserSecurityAccess,-1);
        this.userSecurityStatus = options.userSecurityStatus || null;
        this.documentTemplates = options.documentTemplates || []
        this.defaultUserSecurityAccessText = options.defaultUserSecurityAccessText || null;
        this.userSecurityStatusText = options.userSecurityStatusText || null;
    }

    get isNew() { return this.documentPackageID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.regionID == 0) {
            errorList.push("Region is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        return errorList;
    }
    get verifiedItem() {
        let errorResult = {};
        if (this.regionID == 0) {
            errorResult.requiredRegion = true;
        }
        if (_.isEmpty(this.description)) {
            errorResult.requiredDesc = true;
        }
        return errorResult;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class DocumentCategoryDto {
	constructor(options) {
        options = options || {};
        this.documentCategoryID = _.parseNumber(options.documentCategoryID,0);
        this.description = options.description || null;
        this.regionID = _.parseNumber(options.regionID,0);
        this.regionDisplay = options.regionDisplay || null;
    }

    get isNew() { return this.documentCategoryID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.regionID == 0) {
            errorList.push("Region is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        return errorList;
    }
    get verifiedItem() {
        let errorObject = {};
        if (this.regionID == 0) {
            errorObject.requiredRegion = true;
        }
        if (_.isEmpty(this.description)) {
            errorObject.requiredDesc = true;
        }
        return errorObject;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class DocumentStateDto {
	constructor(options) {
        options = options || {};
        this.documentStateID = _.parseNumber(options.documentStateID,0);
        this.documentTemplateID = _.parseNumber(options.documentTemplateID,0);
        this.usStatesID = options.usStatesID || null;
        this.usState = options.usState || null;
    }

    get isNew() { return this.documentPackageID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}


export class DocumentSecurityUserDto {
	constructor(options) {
        options = options || {};
        this.documentSecurityUserID = _.parseNumber(options.documentSecurityUserID,0);
        this.documentTemplateID = _.parseNumber(options.documentTemplateID,0);
        this.usersID = _.parseNumber(options.usersID,0);
        this.securityAccess = _.parseNumber(options.securityAccess,0);
        this.userFullName = options.userFullName || null;
        this.securityAccessName = options.securityAccessName || null;
        this.regionDisplay = options.regionDisplay || null;
        this.userGroupName = options.userGroupName || null;
    }

    get isNew() { return this.documentPackageID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}


export class DocumentPackageSecurityUserDto {
	constructor(options) {
        options = options || {};
        this.documentPackageSecurityUser = _.parseNumber(options.documentPackageSecurityUser,0);
        this.documentPackageID = _.parseNumber(options.documentPackageID,0);
        this.usersID = _.parseNumber(options.usersID,0);
        this.securityAccess = _.parseNumber(options.securityAccess,0);
        this.userFullName = options.userFullName || null;
        this.securityAccessName = options.securityAccessName || null;
        this.regionDisplay = options.regionDisplay || null;
        this.userGroupName = options.userGroupName || null;
    }

    get isNew() { return this.documentPackageSecurityUser == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

import { DocumentListFormat } from "@/shared/models/models";
import { TextControlListType, NumberFormat } from "@/shared/models/enums";
export class RegionListFormatDto extends DocumentListFormat {
	constructor(options) {
        options = options || {};
        super(options);
        let id = _.parseNumber(options.regionListFormatID, 0);
        this.regionListFormatID = id || -_.uniqueId();
		this.regionID = options.regionID || null;
        this.listFormatDescription = options.listFormatDescription || null;
        let savedDefault = _.gt(this.regionListFormatID, 0);
        this.isSaved = _.parseBool(options.isSaved, savedDefault);
        this.isDeleted = _.parseBool(options.isDeleted);
    }

    updateDescription() {
        if(this.textControlListType === TextControlListType.None) {
            this.listFormatDescription = "None";
        }
        else if(this.textControlListType === TextControlListType.Bulleted) {
            this.listFormatDescription = "Bullets";
        }
        else {
            this.listFormatDescription = NumberFormat.displayValue(this.numberFormat);
        }
    }

    toDataObject() {
        return _.pickBy(this, (v,k) => k !== "overrideListFormat");
    }
}

export class TagDto {
	constructor(options) {
        options = options || {};
        this.tagID = _.parseNumber(options.tagID, 0);
        this.tagName = options.tagName || null;
        this.canDelete = _.parseBool(options.canDelete, false);
    }

    get isNew() { return this.tagID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.tagName)) {
            errorList.push("Tag Name is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class BiReportTreeItem extends TreeViewItemModel {
    constructor(options) {
        super(options);
        options = options || {};
        this.isFolder = _.parseBool(options.isFolder);
        this.contentId = options.contentId || "";
        this.parentContentId = options.parentContentId || "";
        this.isModified = _.parseBool(options.isModified);
        this.permissions = !_.isEmpty(options.permissions)
            ? _.map(options.permissions, (perm) => new ExagoContentAccessDto(perm))
            : [];
        this.parent = () => !_.isEmpty(options.parent) ? options.parent : null;
        this.mapChildren(options.children);
        this.content = options.content || {};
        this.path = options.path || "";
        this.ext = options.ext || "";
        this.physicalPath = options.physicalPath || "";
        this.isCustomReport = _.parseBool(options.isCustomReport);
    }

    mapChildren(items=[]) {
        this.children = _.isEmpty(items) ? [] : _.map(items, c => new BiReportTreeItem(_.merge({}, c, { parent: this })));
    }
}

// RQO-11600 : https://support.exagoinc.com/hc/en-us/articles/360042587313#h_93d3ca2b-fe03-4168-a361-c68acbdd72bd
// Only allow CanView, CanSave, and CanEdit for now due to Exago limitations.

const exagoPermissionType = {
    // Edit: 1,
    Edit:1,
    // Rename: 4,
    // Share: 8,
    Delete: 16,
    // Execute: 32,
    Copy: 64,
    // Download: 128,
    View: 256,
    //FullAccess: 65535,
};
export class ExagoPermissionType {
    static get Edit () {return exagoPermissionType.Edit;}
    // static get Save () {return exagoPermissionType.Save;}
    //static get Rename () {return exagoPermissionType.Rename;}
    //static get Share () {return exagoPermissionType.Share;}
    static get Delete () {return exagoPermissionType.Delete;}
    //static get Execute () {return exagoPermissionType.Execute;}
    static get Copy () {return exagoPermissionType.Copy;}
    //static get Download () {return exagoPermissionType.Download;}
    static get View () {return exagoPermissionType.View;}
    //static get FullAccess () {return exagoPermissionType.FullAccess;}
    static get lookupItems () {
        return _.map(exagoPermissionType, c => { return { id: c, name: ExagoPermissionType.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(exagoPermissionType, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }

    static toList(flag) {
        let matches = _.cloneDeep(_.filter(ExagoPermissionType.lookupItems, (item) => {
            return (flag & item.id) == item.id;
        }));
        return matches;
    }

    static fromList(flags) {
        if (_.isEmpty(flags)) return 0;

        let mask = 0;

        _.forEach(flags, (flag) => {
            mask |= _.get(flag, 'id', _.parseNumber(flag, 0));
        });

        return mask;
    }
}

const exagoPartyType = {
    // Everyone: 1,
    Group: 2,
    //Company: 3,
    User: 4,
};
export class ExagoPartyType {
    // static get Everyone () {return exagoPartyType.Everyone;}
    static get Group () {return exagoPartyType.Group;}
    static get User () {return exagoPartyType.User;}

    static get lookupItems () {
        return _.map(exagoPartyType, c => { return { id: c, name: ExagoPartyType.displayValue(c) }; });
    }

    static displayValue (id) {
        let enumKey = _.findKey(exagoPartyType, val => val === id);
        return (enumKey && _.startCase(enumKey)) || "";
    }
}

export class ExagoContentAccessDto {
    constructor(options) {
        options = options || {};
        this.key = _.uniqueId("exago-content-access-");
        this.content_id = options.content_id || null;
        this.parent_id = options.parent_id || null;
        this.party_type_id = _.parseNumber(options.party_type_id, null);
        this.party_id = options.party_id || null;
        this.sort_order = _.parseNumber(options.sort_order, 0);
        this.access_flags = _.parseNumber(options.access_flags, 0);
        this.child_inherits = _.parseBool(options.child_inherits, false);
        this.accessBits = !_.isEmpty(options.accessBits) ? _.cloneDeep(options.accessBits) : [];
        this.setPermissionsByAccessFlags(this.access_flags);
    }

    setPermissionsByList(flags) {
        this.access_flags = ExagoPermissionType.fromList(flags);
        this.setPermissionsByAccessFlags(this.access_flags);
    }

    setPermissionsByAccessFlags(flag) {
        this.access_flags = flag;
        this.accessBits = _.map(ExagoPermissionType.toList(flag), (item) => (item.id));
    }

    applyTo(data, excludeKeys=[]) {
        _.forEach(this, (value, key) => {
            if (!_.includes(excludeKeys, key)) _.set(data, key, value);
        });
    }
}

export class RecordingStatus {
	constructor(options) {
        options = options || {};
        this.recordingStatusID = _.parseNumber(options.recordingStatusID, 0);
        this.name = options.name || null;
        this.isSystem = _.parseBool(options.isSystem);
        this.inactive = _.parseBool(options.inactive);
    }

    get isNew() { return this.recordingStatusID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.name)) {
            errorList.push("Name is required.");
        }
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class RecordingRejection {
	constructor(options) {
        options = options || {};
        this.recordingRejectionID = _.parseNumber(options.recordingRejectionID, 0);
        this.name = options.name || null;
        this.description = options.description || null;
        this.isSystem = _.parseBool(options.isSystem);
        this.inactive = _.parseBool(options.inactive);
    }

    get isNew() { return this.recordingStatusID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.name)) {
            errorList.push("Name is required.");
        }
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class RecordingType {
	constructor(options) {
        options = options || {};
        this.recordingTypeID = _.parseNumber(options.recordingTypeID, 0);
        this.name = options.name || null;
        this.description = options.description || null;
        this.isSystem = _.parseBool(options.isSystem);
        this.inactive = _.parseBool(options.inactive);
    }

    get isNew() { return this.recordingTypeID === 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (_.isEmpty(this.name)) {
            errorList.push("Name is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }
        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

export class RecordingDocumentDto {
	constructor(options) {
        options = options || {};
        this.recordingDocumentID = _.parseNumber(options.recordingDocumentID, 0);
        this.accountingCodeID = _.parseNumber(options.accountingCodeID, null);
        this.description = options.description || null;
        this.regionID = _.parseNumber(options.regionID, 0);
        this.regionDisplay = options.regionDisplay || null;
        this.systemRecord = _.parseBool(options.systemRecord);
        this.seedType = _.parseNumber(options.seedType, null);
        this.recordingDocumentTypeID = _.parseNumber(options.recordingDocumentTypeID,null);
    }

    get isNew() { return this.recordingDocumentID == 0; }

    get isValid() { return this.validationErrors.length === 0; }

    get validationErrors() {
        let errorList = [];
        if (this.regionID == 0) {
            errorList.push("Region is required.");
        }
        if (_.isEmpty(this.description)) {
            errorList.push("Description is required.");
        }

        return errorList;
    }

    toDataObject() { return _.toPlainObject(this); }
}

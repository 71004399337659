<template>
    <div class="content-wrapper">
        <rq-page-section :title="itemTypeNamePlural" headerSize="lg" borderless header-only>
            <template #header-actions>
                <ul class="nav">
                    <li class="nav-item">
                        <b-btn
                            automation_id="btn_add_company"
                            variant="theme"
                            v-rq-tooltip.hover.top
                            :title="`Add ${itemTypeName}`"
                            @click="onAddItem">Add
                        </b-btn>
                    </li>
                </ul>
            </template>
            <template #header-secondary>
                <div class="rq-content-description item-type-description">{{itemTypeDescription}}</div>
            </template>
        </rq-page-section>
        <company-grid
            ref="companyGrid"
            :include-role-types="includeRoleTypes"
            :invisible-columns="invisibleColumns"
            :additional-visible-columns="['regionID']"
            :additional-optional-columns="['isSettlementAgent', 'isPlaceOfClosing']"
            :show-inactive="showInactive"
            :show-inactive-column="showInactiveColumn"
            @edit="onEditItem"
            @row-double-click="onEditItem"
            @reset="onGridReset"
            persist-state
            allow-edit
            allow-delete
            allow-activate>
            <template #toolbar>
                <ul class="nav navbar-nav me-3 ms-auto">
                    <li class="nav-item">
                        <b-form-checkbox
                            automation_id="chk_include_inacive"
                            id="chk_include_inacive"
                            v-model="showInactiveColumn"
                        >Include Inactive</b-form-checkbox>
                    </li>
                </ul>
            </template>
        </company-grid>
    </div>
</template>

<script>
    import { CompanyGrid, CompanyEdit } from "@utilities/manage-companies/components/";
    export default {
        name: "CompanyList",
        components: { CompanyGrid },
        data: function() {
            const self = this;
            return {
                showInactiveColumn: false
            }
        },
        props: {
            roleTypeId: { type: Number, default: -1 },
            showInactive: { type: Boolean, default: false },
        },
        computed: {
            includeRoleTypes() { return [this.roleTypeId]; },
            companyGrid() { return _.get(this, "$refs.companyGrid", null); },
            itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            invisibleColumns() { return this.roleTypeId == 1 ? ["roleTypeID", "eMailAddress"] : []}
        },

        watch: {
            roleTypeId(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.refresh();
            }
        },

        methods: {

            onAddItem() {
                this.loadCompany();
            },

            onEditItem(e) {
                this.loadCompany(e.data.companyID, e.data.name);
            },

            onGridReset() {
                this.showInactiveColumn = false;
            },

            refresh() {
                _.invoke(this, "companyGrid.clearSelection");
                _.invoke(this, "companyGrid.refresh");
            },

            loadCompany(companyID=0, companyName="") {
                const self = this;
                let isNew = (companyID === 0);
                let refreshOnExit = false;
                let dialogId = null;
                let onOk = (e, addAnother=false) => {
                    e.component.modalSave()
                        .then(result => {
                            if (!result) return false;
                            if (addAnother) {
                                self.$dialog.reloadComponent({ dialogId, props:{ companyId: 0 } });
                                refreshOnExit = true;
                            } else {
                                self.refresh();
                                self.$dialog.close();
                            }
                        });
                };
                let onCancel = e => {
                    if(!refreshOnExit) return;
                    self.refresh();
                };
                dialogId = self.$dialog.open({
                    title: `${companyID == 0 ? "Add" : "Edit"} ${self.itemTypeName}`,
                    height: "85%",
                    width: "85%",
                    component: CompanyEdit,
                    scrollable: false,
                    props: {
                        configurationMode: true,
                        modalMode: true,
                        companyId: companyID,
                        roleTypeId: self.roleTypeId
                    },
                    buttons: [
                        { title: "Cancel", automationId: "btn_dm_modal_cancel", cssClass: "btn btn-secondary", closeOnComplete: true, onClick: onCancel },
                        { title: "Save & Add Another", automationId: "btn_dm_modal_save_and_another", cssClass: "btn btn-primary", isVisible: isNew, closeOnComplete: false, onClick: (e) => { return onOk(e, true); } },
                        { title: "Save", automationId: "btn_dm_modal_save", cssClass: "btn btn-primary", closeOnComplete: false, onClick: (e) => { return onOk(e, false); } }
                    ]
                });
            }
        }
    };
</script>

<template>
    <div class="rq-container content-wrapper">
        <rq-banner
            variant="error"
            :message="errorMessage"
            icon="fas fa-exclamation-triangle"
            :visible="showErrorBanner"
        />
        <div class="row mt-2">
            <div class="col-auto ms-auto form-group">
                <b-form-checkbox
                    automation_id="chk_multi_factor_auth_enabled"
                    id="chk_multi_factor_auth_enabled"
                    v-model="userData.user.multiFactorAuthEnabled"
                    >Enable multi-factor authentication</b-form-checkbox>
            </div>
            <div v-if="isWorkflowAutomationEnabled" class="col-auto form-group">
                <b-form-checkbox
                    automation_id="chk_has_workflow_automation_role"
                    id="chk_has_workflow_automation_role"
                    v-model="userData.user.hasWorkflowAutomationRole">
                    Enable Workflow Automation Role
                </b-form-checkbox>
            </div>
            <div class="col-auto form-group">
                <b-form-checkbox
                    automation_id="chk_allow_open_file_in_use"
                    id="chk_allow_open_file_in_use"
                    v-model="userData.user.allowOpenFileInUse"
                    >Allow Open File In Use</b-form-checkbox>
            </div>
            <div v-show="false" class="col-auto form-group">
                <b-form-checkbox
                    automation_id="chk_is_paperless_closer_user"
                    id="chk_is_paperless_closer_user"
                    v-model="userData.user.isPaperlessCloserUser"
                    >Paperless Closer User</b-form-checkbox>
            </div>
            <div class="col-auto form-group">
                <b-form-checkbox
                    automation_id="chk_inactive"
                    id="chk_inactive"
                    v-model="userData.user.isInactive"
                    >Inactive</b-form-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col col-9">
                <div class="row">
                    <div class="col col-4 form-group form-required" :class="{ 'has-error': v$.userData.user.displayName.$error }">
                        <label for="txt_display_name">Display Name</label>
                        <input automation_id="txt_display_name" v-model="v$.userData.user.displayName.$model" type="text" class="form-control" placeholder="Display Name" maxlength="50">
                        <rq-validation-feedback>Display Name is required</rq-validation-feedback>
                    </div>
                    <div class="col col-4 form-group form-required" :class="{ 'has-error': v$.userData.user.login.$error }">
                        <label for="login">Login</label>
                        <input automation_id="txt_login" v-model="v$.userData.user.login.$model" type="text" class="form-control" placeholder="Login" maxlength="50">
                        <rq-validation-feedback :messages="{
                            'Login is required': v$.userData.user.login.required.$invalid,
                            'Login is in use by another user': v$.userData.user.login.isUnique.$invalid,
                            'Login must be a valid email address': v$.userData.user.login.email.$invalid
                        }" />
                    </div>
                    <div class="col col-4 form-group form-required" :class="{ 'has-error': v$.userData.user.fullName.$error }">
                        <label for="txt_full_name">Full Name</label>
                        <input automation_id="txt_full_name" v-model="v$.userData.user.fullName.$model" type="text" class="form-control" placeholder="Full Name" maxlength="45">
                        <rq-validation-feedback>Full Name is required</rq-validation-feedback>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-4 form-group form-required" :class="{ 'has-error': v$.userData.user.regionID.$error }">
                        <label for="drp_regions">Default Region</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_regions', id: 'drp_regions' }"
                            :items="regionSelectItems"
                            value-expr="regionID"
                            display-expr="displayName"
                            v-model="v$.userData.user.regionID.$model"
                            @value-changed="onRegionChange"
                            @focus-out="onRegionFocusOut"
                        />
                        <rq-validation-feedback>Default Region is required</rq-validation-feedback>
                    </div>
                    <div class="col col-4 form-group form-required" :class="{'has-error': v$.userData.user.branchID.$error }">
                        <label for="drp_branches">Default Branch</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_branches', id: 'drp_branches' }"
                            :items="branchSelectItems"
                            value-expr="id"
                            display-expr="name"
                            :disabled="!isRegionSelected"
                            :search-enabled="true"
                            v-model="v$.userData.user.branchID.$model"
                            @value-changed="onRegionBranchChange"
                        />
                        <rq-validation-feedback>Default Branch is required</rq-validation-feedback>
                    </div>
                    <div class="col col-4 form-group form-required" :class="{ 'has-error' : v$.userData.user.groupUsersID.$error }">
                        <label for="drp_security_role">Security Role</label>
                        <dx-select-box
                            :input-attr="{ automation_id: 'drp_security_role', id: 'drp_security_role' }"
                            :items="userGroupSelectItems"
                            :disabled="!isRegionSelected"
                            value-expr="groupUsersID"
                            display-expr="login"
                            :search-enabled="true"
                            v-model="v$.userData.user.groupUsersID.$model"
                        />
                        <rq-validation-feedback>Security Role is required</rq-validation-feedback>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-4 form-group">
                        <label for="txt_initials">Initials</label>
                        <input automation_id="txt_initials" v-model="userData.user.initials" type="text" class="form-control" id="txt_initials" placeholder="Initials" maxlength="5">
                    </div>
                    <div class="col col-4 form-group">
                        <label for="txt_title">Title</label>
                        <input automation_id="txt_title" v-model="userData.user.title" type="text" class="form-control" id="txt_title" placeholder="Title" maxlength="50">
                    </div>
                    <div class="col col-4 form-group form-required" :class="{ 'has-error' : v$.userData.user.emailAddress.$error }">
                        <div class="row">
                            <label class="col-auto col-form-label with-info" for="txt_email_address">Internal Notification Email Address</label>
                            <div class="col-auto ms-auto" title="For internal notifications only" v-rq-tooltip.hover.top>
                                <FontAwesomeIcon icon="fas fa-info-circle" class="text-dark" />
                            </div>
                        </div>
                        <input automation_id="txt_email_address" v-model="v$.userData.user.emailAddress.$model" type="text" class="form-control" id="txt_email_address" placeholder="Email Address" maxlength="50">
                        <rq-validation-feedback>Invalid email address format.</rq-validation-feedback>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-4 form-group">
                        <label for="txt_phone">Phone</label>
                        <rq-masked-input id="txt_phone" placeholder="Phone" v-model="userData.user.phone" maskType="phone" isMasked="true" maxlength="25"></rq-masked-input>
                    </div>
                    <div class="col col-4 form-group" :class="{ 'form-required': mobileNumberRequired, 'has-error' : v$.userData.user.cellPhone.$error }">
                        <label for="txt_cell_phone">Mobile Phone</label>
                        <rq-masked-input id="txt_cell_phone" placeholder="Mobile Phone" v-model="userData.user.cellPhone" maskType="phone" isMasked="true" maxlength="25"></rq-masked-input>
                        <rq-validation-feedback>Mobile Phone is required</rq-validation-feedback>
                        <rq-validation-feedback :messages="{
                            'Mobile Phone is required': v$.userData.user.cellPhone.required.$invalid,
                            'Invalid mobile phone format': v$.userData.user.cellPhone.isValid.$invalid
                        }" />
                    </div>
                    <div class="col col-4 form-group">
                        <label for="txt_fax">Fax</label>
                        <rq-masked-input id="txt_fax" placeholder="Fax" v-model="userData.user.fax" maskType="phone" isMasked="true" maxlength="25"></rq-masked-input>
                    </div>
                </div>
            </div>
            <div class="col col-3">
                <rq-image-preview
                    ref="signatureImage"
                    label="Signature"
                    v-model="userData.signature"
                />
            </div>
        </div>
        <div class="row">
            <div class="col col-3 form-group" :class="{ 'has-error': v$.userData.user.titleUnitID.$error }">
                <label for="drp_title_units">Title Unit</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_title_units', id: 'drp_title_units' }"
                    :items="filteredTU"
                    :disabled="!isRegionSelected"
                    value-expr="id"
                    display-expr="name"
                    v-model="v$.userData.user.titleUnitID.$model"
                    :search-enabled="true"
                />
                <rq-validation-feedback>Title Unit and Escrow Unit are using different File Pools</rq-validation-feedback>
            </div>
            <div class="col col-3 form-group" :class="{ 'has-error': v$.userData.user.escrowUnitID.$error }">
                <label for="drp_escrow_units">Escrow Unit</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_escrow_units', id: 'drp_escrow_units' }"
                    :items="filteredEU"
                    :disabled="!isRegionSelected"
                    value-expr="id"
                    display-expr="name"
                    v-model="v$.userData.user.escrowUnitID.$model"
                    :search-enabled="true"
                />
                <rq-validation-feedback>Title Unit and Escrow Unit are using different File Pools</rq-validation-feedback>
            </div>
            <div class="col col-3 form-group">
                <label for="drp_bi_role">Reports Role</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_bi_role', id: 'drp_bi_role' }"
                    :items="biRoles"
                    display-expr="description"
                    value-expr="id"
                    :search-enabled="true"
                    v-model="userData.user.biRoleID"
                />
            </div>
            <!-- <div class="col col-3 form-group">
                <label for="drp_exago_dashboard_report">Dashboard Report</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_exago_dashboard_report', id: 'drp_exago_dashboard_report' }"
                    :items="reportList"
                    display-expr="displayName"
                    value-expr="fullPath"
                    :search-enabled="true"
                    v-model="userData.user.exagoDashboardReport"
                />
            </div> -->
            <div class="col col-3 form-group">
                <label for="checkDisplay">Check Display</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_checkDisplay', id: 'drp_checkDisplay' }"
                    :items="checkPrintingOptions"
                    value-expr="id"
                    display-expr="name"
                    v-model="userData.user.checkDisplay"
                    :search-enabled="true"
                />
            </div>
            <div class="col col-3 form-group">
                <label for="txt_cmUsername">Closing Market User Name</label>
                <input automation_id="txt_cmUsername" v-model="userData.user.cmUsername" type="text" class="form-control" placeholder="Closing Market User Name" maxlength="50">
            </div>
            <div class="col col-3 form-group" :class="{ 'form-required': mfaMethodRequired, 'has-error' : v$.userData.user.multiFactorAuthType.$error }">
                <label for="checkDisplay">MFA Method</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_mfaMethod', id: 'drp_mfaMethod' }"
                    :items="MultiFactorAuthTypes"
                    value-expr="id"
                    display-expr="name"
                    :disabled="!userData.user.multiFactorAuthEnabled"
                    v-model="userData.user.multiFactorAuthType"
                    :search-enabled="true"
                    @valueChanged="mfaTypeChanged"
                />
                <rq-validation-feedback>MFA Method is required</rq-validation-feedback>
            </div>
        </div>
        <rq-section-card title="Additional Regions &amp; Branches" collapsible collapsed>
            <rqdx-action-data-grid
                ref="dataGrid"
                automation_id="tbl_user_region_branch"
                :actions="selectionActions"
                :config="gridConfig"
                :data-source="gridDataSource"
                export-file-name="user-region-branch-data"
                v-model:validation-errors="gridValidationErrors"
                focus-after-insert="none"
                class="p-0"
                @delete="onDeleteRegionBranch"
                fixed-header
                hide-search
                hide-settings
                hide-export
                rq-editable>
                <template #toolbar>
                    <ul class="nav navbar-nav me-auto">
                        <li class="nav-item">
                            <b-btn
                                automation_id="btn_add_user_region_branch"
                                variant="theme"
                                size="sm"
                                :disabled="!canAddRegionBranch"
                                @click="onAddRegionBranch">Add
                            </b-btn>
                        </li>
                    </ul>
                </template>
            </rqdx-action-data-grid>
        </rq-section-card>
    </div>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { required, email } from "validators";
    import { useVuelidate } from "@vuelidate/core";
    import { EXAGO_SESSION_ACTIONS } from "@/store/actions";
    import { UsersDto, UserDataDto, UserRegionBranchDto }  from "../models";
    import { CheckPrintingOptionsForUser, MultiFactorAuthTypes } from '../../enums';
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { requiredIf } from "@vuelidate/validators";
    import { useLicenseStore } from "@/store/modules/license";

    const defaultErrorMessage = "Please correct the highlighted errors on screen to continue.";

    export default {
        name: 'UserForm',
        props: {
            userData: {type: Object, default: null},
            userGroups: {type: Array},
            uniqueValidator: { type: Function, default: (() => true) }
        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                itemKey: "userRegionBranchID",
                regions: [],
                branches: [],
                gridValidationErrors: [],
                showBanner: false,
                regionChanged: false,
                filteredTU: [],
                filteredEU: [],
                isImageValid: true,
                errorMessage: defaultErrorMessage,
                isWorkflowAutomationEnabled: false,
            };
        },
        provide(){
            return {
                imageInfoCallback: {
                    onImageChanged: (data) => {
                        if(data.validImage){
                            //reset to default behavior
                            this.isImageValid = true;
                            this.showBanner = false;
                            this.errorMessage = defaultErrorMessage;
                        }
                        else{
                            this.isImageValid = false;
                            this.showBanner = true;
                            this.errorMessage = data.message;
                        }
                        
                    }
                }
            }
        },
        computed: {
            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
                allBranches: state => state.system.lookups.branches
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "reportList"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            checkPrintingOptions(){ return CheckPrintingOptionsForUser.lookupItems; },
            MultiFactorAuthTypes() { return MultiFactorAuthTypes.lookupItems; },
            selectionActions() { return [{ name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, tooltip: "Delete Region/Branch" }]; },
            isNew() { return _.parseBool(this.userData.isNew, true); },
            userId() { return _.getNumber(this, "userData.user.usersID", 0); },
            userRegionId() { return _.getNumber(this, "userData.user.regionID", 0); },
            isRegionSelected() { return _.gt(this.userRegionId, 0); },
            canAddRegionBranch() { return _.isEqual(this.userData.userRegionBranches.length, 0) || (!_.isEqual(this.userData.user.branchID, 1) && !_.isEqual(this.userData.user.branchID || 0, 0)); },
            defaultRegionBranch() { return _.find(this.userData.userRegionBranches, "defaultRegionBranch"); },
            regionSelectItems() { return this.lookupHelpers.getRegions(); },
            branchSelectItems() { return this.lookupHelpers.getBranches(this.userRegionId); },
            userGroupSelectItems() {
                return _.filter(this.userGroups, r => (this.globalRegionId === this.userRegionId || _.includes([this.userRegionId, this.globalRegionId], _.parseNumber(r.regionID))) && !r.isInactive);
            },
            hasFilePoolMisMatch() {
                let escrowUnitFileNumberPoolID = _.getNumber(this, "userData.user.escrowUnitFileNumberPoolID", 0);
                let titleUnitFileNumberPoolID = _.getNumber(this, "userData.user.titleUnitFileNumberPoolID", 0);
                if (escrowUnitFileNumberPoolID === 0 || titleUnitFileNumberPoolID === 0)
                    return false;
                return !_.isEqual(escrowUnitFileNumberPoolID, titleUnitFileNumberPoolID);
            },
            titleUnits() { return this.lookupHelpers.getLookupItems(this.lookupItems.TITLE_UNITS); },
            escrowUnits() { return this.lookupHelpers.getLookupItems(this.lookupItems.ESCROW_UNITS); },
            biRoles() { return this.lookupHelpers.getBiRoles(); },
            showErrorBanner(){ return this.showBanner && (this.v$.$error || !this.isImageValid);},
            mobileNumberRequired(){ return this.userData.user.multiFactorAuthType == MultiFactorAuthTypes.SMS; },
            mfaMethodRequired(){ return this.userData.user.multiFactorAuthEnabled; }
        },

        watch: {
            "userData.user.titleUnitID" (newValue, oldValue) {
                if(newValue === oldValue) return;
                var tu = _.filter(this.titleUnits, ["id", newValue])[0];
                if (tu) {
                    _.set(this, "userData.user.titleUnitFileNumberPoolID", tu.additionalIdentity);
                } else {
                    _.set(this, "userData.user.titleUnitFileNumberPoolID", null);
                }
                this.filterEUTUDropdown("TU");
            },
            "userData.user.escrowUnitID" (newValue, oldValue) {
                if(newValue === oldValue) return;
                var eu = _.filter(this.escrowUnits, ["id", newValue])[0];
                if (eu) {
                    _.set(this, "userData.user.escrowUnitFileNumberPoolID", eu.additionalIdentity);
                } else {
                    _.set(this, "userData.user.escrowUnitFileNumberPoolID", null);
                }
                this.filterEUTUDropdown("EU");
            },
            "userData.user.regionID" (newValue, oldValue) {
                if(newValue === oldValue) return;
                this.filterEUTUDropdown("TU");
                this.filterEUTUDropdown("EU");
            },
            "userData.user.emailAddress" (newValue, oldValue) {
                if(newValue === oldValue) return;
                this.userData.user.cmUsername = newValue;
            },
            "userData.user.multiFactorAuthEnabled" (newValue, oldValue){
                if(!newValue){
                    this.userData.user.multiFactorAuthType = null;
                }
            }
        },

        validations() {
            const self = this;
            return {
                userData: {
                    user: {
                        displayName: { required },
                        fullName: { required },
                        cellPhone: {
                            isValid(val){
                                return _.isEmpty(val) || val.length === 14;
                            },
                            required: requiredIf(() => self.mobileNumberRequired),
                        },
                        multiFactorAuthType: {
                            required: requiredIf(() => self.mfaMethodRequired),
                        },
                        login: {
                            email,
                            required,
                            isUnique(val) {
                                return this.uniqueValidator(this.userId, val);
                            }
                        },
                        regionID: { required },
                        branchID: { required },
                        escrowUnitID: {
                            isValid(val) {
                                return !this.hasFilePoolMisMatch;
                            }
                        },
                        titleUnitID: {
                            isValid(val) {
                                return !this.hasFilePoolMisMatch;
                            }
                        },
                        groupUsersID: { required },
                        emailAddress: { email, required }
                    },
                    userRegionBranches: {
                        noGridErrors() {
                            return _.isEmpty(this.gridValidationErrors);
                        }
                    }
                }
            }
        },

        created(){
            this.originalData = this.userData.toDataObject();
            this.initLookups();
            this.initGridConfig();
        },

        mounted() {
            const self = this;
            self.filterEUTUDropdown('EU');
            self.filterEUTUDropdown('TU');
            self.showBanner = (!self.v$.userData.user.escrowUnitID.isValid || !self.v$.userData.user.titleUnitID.isValid);
            const licenseStore = useLicenseStore();
            self.isWorkflowAutomationEnabled = licenseStore.checkFeature("workflowAutomation");
        },

        methods: {
            mfaTypeChanged(e){
                const self = this;
                self.v$.$touch();   
            },
            filterEUTUDropdown(valueDependency, fromInit=false){
                const self = this;
                if(valueDependency === 'EU')
                {
                    let escrowUnitFileNumberPoolID = _.getNumber(self, "userData.user.escrowUnitFileNumberPoolID", 0);
                    let titleUnitID = _.getNumber(self, "userData.user.titleUnitID", 0);
                    let filteredOutRegionTU = _.filter(_.cloneDeep(self.titleUnits), ['regionID', this.userRegionId]);
                    if(escrowUnitFileNumberPoolID > 0) {
                        // filter out title unit dropdown
                        self.filteredTU = _.filter(filteredOutRegionTU, x => {
                            let fileNumberPoolID = _.get(x, "additionalIdentity", 0);
                            //if there is an existing mismatch, show the original Title Unit selection, otherwise, just the filtered list
                            return self.hasFilePoolMisMatch ? (fileNumberPoolID === 0 || fileNumberPoolID === escrowUnitFileNumberPoolID || titleUnitID === x.id) : (fileNumberPoolID === 0 || fileNumberPoolID === escrowUnitFileNumberPoolID);
                        });
                    }
                    else {
                        self.filteredTU = filteredOutRegionTU;
                    }
                    self.v$.userData.user.escrowUnitID.$touch();
                }
                if(valueDependency === 'TU')
                {
                    let titleUnitFileNumberPoolID = _.getNumber(self, "userData.user.titleUnitFileNumberPoolID", 0);
                    let escrowUnitID = _.getNumber(self, "userData.user.escrowUnitID", 0);
                    let filteredOutRegionEU = _.filter(_.cloneDeep(self.escrowUnits), ['regionID', this.userRegionId]);
                    if(titleUnitFileNumberPoolID > 0) {
                        // filter out escrow unit dropdown
                        self.filteredEU = _.filter(filteredOutRegionEU, x => {
                            let fileNumberPoolID = _.get(x, "additionalIdentity", 0);
                            //if there is an existing mismatch, show the original Escrow Unit selection, otherwise, just the filtered list
                            return self.hasFilePoolMisMatch ? (fileNumberPoolID === 0 || fileNumberPoolID === titleUnitFileNumberPoolID || escrowUnitID === x.id) : (fileNumberPoolID === 0 || fileNumberPoolID === titleUnitFileNumberPoolID);
                        });
                    }
                    else {
                        self.filteredEU = filteredOutRegionEU;
                    }
                    self.v$.userData.user.titleUnitID.$touch();
                }
            },

            initLookups() {
                const self = this;
                self.regions = _.clone(self.lookupHelpers.getRegions());
                self.branches = _.clone(self.allBranches);
                let storePromise = self.$store.dispatch(EXAGO_SESSION_ACTIONS.GET_REPORTS, { refresh: true });
                self.$rqBusy.wait(storePromise, true);
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        { dataField: "userRegionBranchID", visible: false, allowEditing: false },
                        {
                            dataField: "regionID",
                            caption: "Region",
                            alignment: "left",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            lookup: {
                                dataSource: self.regions,
                                displayExpr: "displayName",
                                valueExpr: "regionID"
                            },
                            setCellValue: function(rowData, value) {
                                rowData.regionID = value;
                                rowData.branchID = null;
                            },
                            validationRules: [
                                { type: "required" },
                            ]
                        },
                        {
                            dataField: "branchID",
                            caption: "Branch",
                            alignment: "left",
                            lookup: {
                                dataSource(dsOptions) {
                                    let rowRegionId = _.getNumber(dsOptions, "data.regionID", 0);
                                    let rowBranchId = _.getNumber(dsOptions, "data.branchID", 0);
                                    let store = rowRegionId > 0
                                        ? self.getBranches(rowRegionId, rowBranchId)
                                        : self.branches;
                                    return { store };
                                },
                                displayExpr: "name",
                                valueExpr: "id"
                            },
                            validationRules: [
                                { type: "required" },
                            ]
                        },
                        { dataField: "defaultRegionBranch", caption: "Default", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate, width: 100 },
                        { dataField: "userID", visible: false, allowEditing: false }
                    ],
                    onEditorPreparing(e) {
                        if(e.parentType !== "dataRow") return;
                        if(e.dataField === "regionID")
                            e.editorOptions.disabled = _.getNumber(self, "defaultRegionBranch.regionID", null) === 1;
                        if(e.dataField === "branchID")
                            e.editorOptions.disabled = _.getNumber(e, "row.data.regionID", 0) === 0;
                        if(e.row.isNewRow) return;
                        if(e.dataField === "defaultRegionBranch")
                            e.editorOptions.disabled = _.getNumber(self, "userData.userRegionBranches.length", 0) === 1;
                    },
                    onInitNewRow(e) {
                        e.data.regionID = _.getNumber(self, "defaultRegionBranch.regionID", null);
                        e.data.defaultRegionBranch = _.isNil(self.defaultRegionBranch);
                    }
                };
                self.gridDataSource = {
                    loadMode: "raw",
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.userData.userRegionBranches);
                    },
                    insert: self.onRegionBranchInsert,
                    update: self.onRegionBranchUpdate
                };
            },

            onAddRegionBranch(e){
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.addRow();
            },

            onAdditionalRegionsShown(e){
                if(!this.gridInstance) return;
                this.clear();
                this.gridInstance.updateDimensions();
            },

            onRegionChange(){
                const self = this;
                self.regionChanged = true;
                self.userData.user.groupUsersID = null;
            },

            onRegionFocusOut(e){
                const self = this;
                if(e.component?.option("opened")) {
                    e.component?.close();
                }
                if (!self.regionChanged) return;
                self.regionChanged = false;
                self.userData.user.branchID = _.find(self.branchSelectItems, b => b.name === 'None').id;
            },

            onRegionBranchChange(e){
                const self = this;
                self.syncUserToGrid();
            },

            onRegionBranchInsert(values){
                const self = this;
                let userRegionBranchID = _.parseNumber(_.uniqueId()) * -1;
                let newUserRegionBranch = new UserRegionBranchDto({ usersID: self.userId, userRegionBranchID, ...values });
                self.checkForSysAdminAndUpdate(newUserRegionBranch);
                if (newUserRegionBranch.defaultRegionBranch) {
                    self.resetDefaultRegionBranches();
                }
                self.userData.userRegionBranches.push(newUserRegionBranch);
                self.syncGridToUser();
                return Promise.resolve(newUserRegionBranch);
            },

            onRegionBranchUpdate(key, values){
                const self = this;
                let itemIndex = _.findIndex(self.userData.userRegionBranches, item => item.userRegionBranchID === key);
                if(itemIndex < 0) return self.onRegionBranchInsert(values);

                let updatedItem = new UserRegionBranchDto(_.assign({}, self.userData.userRegionBranches[itemIndex], values));

                _.invoke(self, "gridInstance.cancelEditData");

                self.checkForSysAdminAndUpdate(updatedItem);

                if (updatedItem.defaultRegionBranch) {
                    self.resetDefaultRegionBranches();
                }
                self.userData.userRegionBranches[itemIndex] = updatedItem;
                self.syncGridToUser();
                return Promise.resolve(updatedItem);
            },

            onDeleteRegionBranch(e){
                if(!e || !e.data) return;
                const self = this;
                let keys = _.map(e.data, "userRegionBranchID");
                _.each(keys, userRegionBranchID => {
                    let itemIndex = _.findIndex(self.userData.userRegionBranches, { userRegionBranchID });
                    if(_.lt(itemIndex, 0)) return;
                    self.userData.userRegionBranches.splice(itemIndex, 1);
                });
                self.clear();
                self.refresh();
            },

            getBranches(regionID, currentBranchID) {
                let regionBranches = _.filter(this.branches, { regionID });
                let selectedBranchIDs = _.map(this.userData.userRegionBranches, "branchID") || [];
                _.pull(selectedBranchIDs, currentBranchID);
                return _.filter(regionBranches, b => !_.includes(selectedBranchIDs, _.getNumber(b, "id", 0)));
            },

            checkForSysAdminAndUpdate(userRegionBranch){
                const self = this;
                var globalNoneBranch = _.find(self.allBranches, {'regionID': self.globalRegionId, 'name':'None'});

                if(userRegionBranch.regionID !== self.globalRegionId || userRegionBranch.branchID !== globalNoneBranch.branchID) return;

                userRegionBranch.defaultRegionBranch = true;
                _.remove(self.userData.userRegionBranches, i => {i.userRegionBranchID !== userRegionBranch.userRegionBranchID});
                self.clear();
                self.refresh();
            },

            save(){
                const self = this;

                _.invoke(self, "gridInstance.saveEditData");
                self.v$.$touch();
                self.showBanner = true;
                if(self.v$.$error) return Promise.resolve({ success: false });

                if(_.isEmpty(self.userData.userRegionBranches)) {
                    self.syncUserToGrid();
                }

                let originalData = self.originalData;
                let currentData = self.userData.toDataObject();
                let changes = self.getAuditChanges(originalData, currentData);

                if (changes.length == 0) return Promise.resolve({ data: currentData, success: true });
                // RQO-12788: Updating Additional Reg/Branch did not update modified date
                changes = _.each(changes, c => {
                    if (_.startsWith(c.name, "userRegionBranches")) {
                        c.new =  JSON.stringify(c.new);
                        c.old =  JSON.stringify(c.old);
                    }
                });

                let apiPromise = self.$api.UsersApi.saveUser(currentData, changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(data => {
                        self.userData.user.usersID = data.user.usersID;
                        self.userData.user.modifiedBy = data.user.modifiedBy;
                        self.$toast.success(`User ${data.user.login} was saved.`);
                        return { data, success: true };
                    }).catch(err => {
                        console.error(err);
                        self.$toast.error(`An issue occurred while saving user, ${currentData.user.login}.`);
                    });
            },

            syncUserToGrid() {
                const self = this;
                let regionID = _.getNumber(self, "userData.user.regionID", 0);
                let branchID = _.getNumber(self, "userData.user.branchID", 0);
                if(regionID === 0 || branchID === 0) return;
                let matchingRegionBranch = _.find(self.userData.userRegionBranches, { regionID, branchID });
                if(_.isNil(matchingRegionBranch)) {
                    if(_.isNil(self.defaultRegionBranch)) {
                        let defaultRegionBranch = new UserRegionBranchDto({ usersID: self.userId, regionID, branchID, defaultRegionBranch: true });
                        self.userData.userRegionBranches.push(defaultRegionBranch);
                    }
                    else {
                        self.defaultRegionBranch.regionID = regionID;
                        self.defaultRegionBranch.branchID = branchID;
                    }
                }
                else {
                    self.resetDefaultRegionBranches();
                    matchingRegionBranch.defaultRegionBranch = true;
                }
                self.$nextTick(() => {
                    if(branchID === 1) {
                        self.userData.userRegionBranches = [self.defaultRegionBranch];
                    }
                    self.gridInstance.refresh();
                });
            },

            syncGridToUser() {
                const self = this;
                let defaultBranchId = _.getNumber(self, "defaultRegionBranch.branchID", null);
                self.v$.userData.user.regionID.$model = _.getNumber(self, "defaultRegionBranch.regionID", null);
                self.v$.userData.user.branchID.$model = defaultBranchId;
                if(defaultBranchId === 1) {
                    self.userData.userRegionBranches = [self.defaultRegionBranch];
                }
            },

            resetDefaultRegionBranches() {
                _.updateAll(this.userData.userRegionBranches, "defaultRegionBranch", false);
            },

            refresh() {
                const self = this;
                self.syncGridToUser();
                self.$nextTick(() => {
                    self.gridInstance.refresh();
                });
            },

            clear() {
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
            }
        }
    }
</script>

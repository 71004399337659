<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        :title="itemTypeNamePlural"
        title-size="sm"
        :automation_id="elementName('tbl')"
        :actions="selectionActions"
        :config="gridConfig"
        :data-source="gridDataSource"
        :export-file-name="elementName('', 'data')"
        v-model:validationErrors="validationErrors"
        @rowValidating="onRowValidating"
        @delete="onDeleteItem"
        integrated-search
        :rq-editable="!readOnly"
        rq-filters>
        <template #toolbar>
            <ul class="nav navbar-nav me-auto">
                <li class="nav-item">
                    <b-btn automation_id="btn_add_line" variant="theme" size="sm" @click="onAddItem" :disabled="readOnly">Add</b-btn>
                </li>
            </ul>
        </template>
    </rqdx-action-data-grid>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { DefaultHUDLine1974Dto }  from "../models";
    import GridCompanyPickerMixin from "@/shared/mixins/GridCompanyPickerMixin";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";

    export default {
        name:"DefaultHUDLine1974List",
        mixins: [GridCompanyPickerMixin, GridSystemLookupMixin],
        props: {
        },
        data () {
            return {
                items: [],
                validationErrors: [],
                addEventName: ""
            };
        },
        computed: {
            ...mapState({
                readOnly: state => state.isPageReadOnly,
                user: state => state.authentication.session.user,
                accountingCodes: state => state.system.lookups.accountingCodes
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null) || {}; },
        },
        created(){
            this.initNonReactiveVariables();
            this.fetchData();
            this.initGridConfig();
            this.initListeners();
        },
        beforeUnmount() {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        methods: {
            onRowValidating(e){
                const self = this;
                let message = "";
                let hasError = false;
                if(e.isValid) {
                    let newItem = new DefaultHUDLine1974Dto(e.oldData);
                    let updatedItem = new DefaultHUDLine1974Dto(_.assign({}, newItem, e.newData));
                    if (!self.isUniqueItem(updatedItem)) {
                        message = `Line ${updatedItem.line} is already in use.`;
                        hasError = true;
                        self.validationErrors = [message]; //set to ensure ActionDataGrid component aborts save since this is an external validation
                     }
                }
                else {
                    message = "Please correct the highlighted errors on screen to continue.";
                    hasError = true;
                }
                self.$events.emit("update-config-error", { message, hasError });
                e.isValid = !hasError;
            },
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.DefaultHUDLinesApi.get(1);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.sortBy(_.map(result, i => new DefaultHUDLine1974Dto(i)), "line");
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            initGridConfig(){
                const self = this;
                let payeePickerInfo = {
                    dialogTitle: "Select Payee",
                    companyIDExpr: "payeeCompanyID",
                    companyNameExpr: "payeeCompanyDisplay",
                    contactIDExpr: "payeeContactID",
                    contactNameExpr: "payeeContactDisplay",
                    showContactPicker: true,
                };

                self.gridConfig = {
                    cacheEnabled: false,
                    onEditorPreparing: self.prepareEditor,
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            showInColumnChooser: false,
                        },
                        {
                            dataField: "hudLine1974Section",
                            caption: "Sections",
                            lookup: {
                                dataSource: self.lineSections,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            validationRules: [ { type: "required" } ],
                        },

                        {
                            dataField: "line", sortIndex: 0, sortOrder: "asc",
                            datatype: "number",
                            allowEditing: false,
                        },
                        {
                            dataField: "description",
                            editorOptions: { maxLength: 80 }
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "accountingCodeID",
                                dataType: "number",
                                caption: "Account Code",
                                editorOptions: { showClearButton: true },
                            },
                            lookupKey: self.lookupItems.ACCOUNTING_CODES,
                            customSort: function(i) { return _.parseNumber(_.get(i, "data")); }
                        }),
                        self.getCompanyContactGridColumn({
                            column: {
                                dataField: "payeeCompanyDisplay",
                                caption: "Payee",
                            },
                            ...payeePickerInfo
                        }),
                        {
                            dataField: "required" , dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate
                        },
                    ],
                    onInitNewRow: function(e) {
                        _.set(e.data, self.itemKey, 0);
                        e.data.required = false;
                        if ( e.data.Field === "line") {
                             e.editorOptions.readOnly =  true;
                        }
                    }
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "2-Page HUD Line";
                self.itemTypeNamePlural = "2-Page HUD Lines";
                self.itemKey = "defaultHUDLineID";
                self.selectionActions = [
                        { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}`, 
                            disabled: function(e) { 
                                if(_.some(e.data, ['required', true]))
                                    return "Access Restricted - Required lines cannot be deleted.";
                                if(self.readOnly){
                                    return true;
                                }
                                return false; 
                            } 
                        }
                    ];
                self.lineSections = [
                    { id: 1, name: "100 - Summary of Borrower's Transactions"},
                    { id: 2, name: "200 - Paid by or in Behalf of Borrower"},
                    { id: 4, name: "400 - Summary of Seller's Transaction"},
                    { id: 5, name: "500 - Reductions in Amount Due to Seller"},
                    { id: 7, name: "700 - Broker's Commission"},
                    { id: 8, name: "800 - Items Payable in Connection with Loan"},
                    { id: 9, name: "900 - Items Required by Lender to be Paid in Advance"},
                    { id: 10, name: "1000 - Reserves Deposited with Lender"},
                    { id: 11, name: "1100 - Title Charges"},
                    { id: 12, name: "1200 - Government Recording and Transfer Charges"},
                    { id: 13, name: "1300 - Additional Settlement Charges"},
                ];
            },

            isUniqueItem(row){
                const self = this;
                let dup = {};
                dup = _.find(self.items, (i) => {
                    return _.parseNumber(i.line, -1) === _.parseNumber(row.line, -1)
                            && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(row, self.itemKey, -1), -1);
                });
                return _.isEmpty(dup);
            },

            onAddItem() {
                this.gridInstance.clearSelection();
                this.gridInstance.addRow();
            },

            onGridInsert(values) {
                const self = this;
                let newItem = new DefaultHUDLine1974Dto(values);
                let newLineNo = self.setLineItem(newItem);
                if (newLineNo == 0){
                    var message = 'There are no available lines in this Section. Please select a different section.';
                    self.validationErrors = [message];
                    self.$events.emit("update-config-error", { message: message, hasError: true });
                    return;
                }
                newItem.line = newLineNo;
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                self.save(newItem, changes)
                    .then(item => {
                        var description = !_.isEmpty(item.description) ? ` ${item.description}` : '';
                        self.$toast.success({ message: `${self.itemTypeName}${description} was saved.` });
                    })
                    .catch(err => {
                        self.validationErrors = [err.errorMessage];
                    })
                    .finally(() => {
                        self.onReset();
                    });
            },

            setLineItem(newItem){
                const self = this;
                var nextLineIdx = -1;
                var increment = 1;
                var nextLineNo = 0;
                var sectionItems =  _.filter(self.items, (i) => { return newItem.hudLine1974Section === i.hudLine1974Section; });
                var tmp = self.getNextMaxLineNo(newItem, increment)
                 switch (sectionItems[0].hudLine1974Section) {
                    case 1:
                        nextLineIdx = _.findIndex(sectionItems, [ 'line', 104 ] );
                        if (nextLineIdx < 0 ){
                            nextLineNo = 104;
                        }else
                        {
                            nextLineIdx = _.findIndex(sectionItems, [ 'line', 105 ] );
                            if (nextLineIdx < 0 ){
                                nextLineNo = 105;
                            }
                        }
                        break;
                    case 2:
                        while(tmp <= 219)
                        {
                           if (tmp <= 209 || tmp >= 215)
                            {
                                nextLineNo = tmp;
                                break;
                            }
                            increment += 1;
                            tmp = self.getNextMaxLineNo(newItem, increment);
                        }
                        break;
                    case 4:
                        while(tmp <= 416)
                        {
                           if (tmp <= 403 || tmp >= 412)
                            {
                                nextLineNo = tmp;
                                break;
                            }
                            increment += 1;
                            tmp = self.getNextMaxLineNo(newItem, increment);
                        }
                        break;
                    case 5:
                        while(tmp <= 519)
                        {
                           if (tmp <= 509 || tmp >= 516)
                            {
                                nextLineNo = tmp;
                                break;
                            }
                            increment += 1;
                            tmp = self.getNextMaxLineNo(newItem, increment);
                        }
                        break;
                    case 7:
                       while(tmp <= 799)
                        {
                           if (tmp <= 799)
                            {
                                nextLineNo = tmp;
                                break;
                            }
                            increment += 1;
                            tmp = self.getNextMaxLineNo(newItem, increment);
                        }
                        break;
                    case 8:
                        while(tmp <= 899)
                        {
                           if (tmp <= 899)
                            {
                                nextLineNo = tmp;
                                break;
                            }
                            increment += 1;
                            tmp = self.getNextMaxLineNo(newItem, increment);
                        }
                        break;
                    case 9:
                        while(tmp <= 999)
                        {
                           if (tmp <= 999)
                            {
                                nextLineNo = tmp;
                                break;
                            }
                            increment += 1;
                            tmp = self.getNextMaxLineNo(newItem, increment);
                        }
                        break;
                    case 10:
                        while(tmp <= 1010)
                        {
                           if (tmp <= 1010)
                            {
                                nextLineNo = tmp;
                                break;
                            }
                            increment += 1;
                            tmp = self.getNextMaxLineNo(newItem, increment);
                        }
                        break;
                    case 11:
                        while(tmp <= 1199)
                        {
                           if (tmp <= 1199)
                            {
                                nextLineNo = tmp;
                                break;
                            }
                            increment += 1;
                            tmp = self.getNextMaxLineNo(newItem, increment);
                        }
                        break;
                    case 12:
                        while(tmp <= 1299)
                        {
                           if (tmp <= 1299)
                            {
                                nextLineNo = tmp;
                                break;
                            }
                            increment += 1;
                            tmp = self.getNextMaxLineNo(newItem, increment);
                        }
                        break;
                    case 13:
                        while(tmp <= 1399)
                        {
                           if (tmp <= 1399)
                            {
                                nextLineNo = tmp;
                                break;
                            }
                            increment += 1;
                            tmp = self.getNextMaxLineNo(newItem, increment);
                        }
                        break;
                }
                return nextLineNo;
            },

            getNextMaxLineNo(item, increment){
                const self = this;
                return  _.maxBy(_.filter(self.items, (i) => { return item.hudLine1974Section === i.hudLine1974Section; }), 'line').line + increment;
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => _.parseNumber(_.get(item, self.itemKey), -1) === key);
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new DefaultHUDLine1974Dto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());
                 if (changes.length == 0) {
                    self.$toast.info({ message: "No changes detected" });
                    return;
                }
                self.save(updatedItem, changes)
                    .then(item => {
                        _.assign(self.items[itemIndex], item);
                        var description = !_.isEmpty(item.description) ? ` ${item.description}` : '';
                        self.$toast.success({ message: `${self.itemTypeName}${description} was saved.` });
                        self.onReset();
                    })
                    .catch(err => {
                        self.validationErrors = [err.errorMessage];
                    })
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, self.itemKey);

                    let apiPromise = self.$api.DefaultHUDLinesApi.delete(toBeDeletedKeys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(keys => {
                            _.pullAllBy(self.items, items, self.itemKey);
                            let message = keys.length > 1 ? `${keys.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            self.refresh();
                            self.fetchData();
                            return true;
                        })
                        .catch(error => {
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            prepareEditor(e){
                if(e.parentType !== "dataRow") return;
                if (e.dataField === "description") {
                    e.editorOptions.readOnly = false;//(_.get(e.row.data, "readOnly", false) || _.get(e.row.data, "descriptionReadOnly", false));
                } 
                else if (e.dataField === "required") {
                    e.editorOptions.disabled = _.get(e.row.data, "readOnly", false) || _.get(e.row.data, "systemRequired", false);
                } 
                else if (e.dataField === "hudLine1974Section" && !e.row.isNewRow) {
                    e.editorOptions.readOnly = true;
                } else {
                    e.editorOptions.readOnly = _.get(e.row.data, "readOnly", false);
                }
            },

            refresh() {
                if (!_.isEmpty(this.gridInstance)) {
                    this.gridInstance.option("focusedRowIndex", -1);
                    this.gridInstance.clearSelection();
                    this.gridInstance.refresh();
                    this.gridInstance.updateDimensions();
                }
            },

            save(item, changes){
                const self = this;
                self.validationErrors = [];
                let apiPromise = self.$api.DefaultHUDLinesApi.save(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
            },
            
            onReset(e) {
                this.fetchData();
            }
        }
    }
</script>

export class ConfigRouteViewModel {
    constructor(options) {
        options = options || {};
        this.name = options.name || null;
        this.itemTypeNamePlural  = options.itemTypeNamePlural  || null;
        this.itemTypeDescription = options.itemTypeDescription || null;
        this.topic = options.topic || null;
        this.category = options.category || null;
    }
}

export class ConfigTopicViewModel {
    constructor(options) {
        options = options || {};
        this.name = options.name || null;
        this.expanded = _.parseBool(options.expanded);
        this.display = _.parseBool(options.display, true);
    }
}

const SYSTEM_DEFAULT_DOCUMENTS = [
    "BorrowerHUDDocumentName",
    "SellerHUDDocumentName",
    "HUD1DocumentName",
    "HUD1ADocumentName",
    "PurchaserCSSDocumentName",
    "SellerCSSDocumentName",
    "BorrowerCSSDocumentName",
    "HUD1974_BorrowerDocumentName",
    "HUD1974_SellerDocumentName",
    "HUD1974_1DocumentName",
    "HUD1974_1ADocumentName",
    "PurchaserSellerCSSDocumentName"
 ];

 const CDF_DEFAULT_DOCUMENTS = [
    "ALTASSBorrowerDocument",
    "ALTASSCashDocument",
    "ALTASSCombinedDocument",
    "ALTASSSellerDocument",
    "BorrowerCDFDocumentName",
    "BorrowerCDFDocumentName_P2ab",
    "CDFDocumentName",
    "CDFDocumentName_P2ab",
    "SellerCDFDocumentName",
    "SellerCDFDocumentName_P2ab",
    "TOSCDFDocumentName",
    "TOSCDFDocumentName_P2ab"
  ];

export class SettlementStatementDocumentDto {
    constructor(options) {
        options = options || {};
        this.settlementStatementDocumentID = _.parseNumber(options.settlementStatementDocumentID, 0);
        this.documentTemplateID = _.parseNumber(options.documentTemplateID, null);
        this.name = options.name || "";
        this.description = options.description || "";
        this.systemDefaultColumn = options.systemDefaultColumn || "";
        this.dateModified = options.dateModified || "";
    }
    toDataObject() { return _.toPlainObject(this); }
}

export class SettlementStatementDocumentDefaults {
    constructor(docs, names) {
        let filteredDocs = _.isEmpty(docs) ? [] : _.isEmpty(names) ? docs : _.filter(docs, d => _.includes(names, d.systemDefaultColumn));
        this.ssDocuments = _.isEmpty(docs) ? [] : _.map(filteredDocs, d => new SettlementStatementDocumentDto(d));
    }

    getDocId(systemDefaultColumn) {
        if(_.isEmpty(this.ssDocuments)) return null;
        let doc = _.find(this.ssDocuments, { systemDefaultColumn });
        return _.isNil(doc) ? null : doc.documentTemplateID;
    }

    setDocId(systemDefaultColumn, val) {
        if(_.isEmpty(this.ssDocuments)) return;
        let doc = _.find(this.ssDocuments, { systemDefaultColumn });
        if(_.isNil(doc)) return;
        doc.documentTemplateID = _.parseNumber(val, null);
    }

    getSsDocData() { return _.map(this.ssDocuments, d => _.toPlainObject(d)); }
	toDataObject() { return _.pickBy(this, (v,k) => !_.includes(["ssDocuments"], k)); }
}

export class SystemDefaultDto extends SettlementStatementDocumentDefaults {
	constructor(options, docs=[]) {
        options = options || {};

        super(docs, SYSTEM_DEFAULT_DOCUMENTS);

        this.systemDefaultID = _.parseNumber(options.systemDefaultID, null);
        this.buyerSellerHusbandRelationship = options.buyerSellerHusbandRelationship || null;
        this.buyerSellerOtherRelationship = options.buyerSellerOtherRelationship || null;
        this.buyerSellerPartnerRelationship = options.buyerSellerPartnerRelationship || null;
        this.buyerSellerWifeRelationship = options.buyerSellerWifeRelationship || null;
        this.cssCharges1Description = options.cssCharges1Description || null;
        this.cssCharges2Description = options.cssCharges2Description || null;
        this.cssCharges3Description = options.cssCharges3Description || null;
        this.cssCharges4Description = options.cssCharges4Description || null;
        this.cssCreditsDescription = options.cssCreditsDescription || null;
        this.cssMainTabDescription = options.cssMainTabDescription || null;
        this.interestInLand = options.interestInLand || null;
        this.invoiceAfterTransmission = options.invoiceAfterTransmission || null;
        this.invoiceBeforeTransmission = options.invoiceBeforeTransmission || null;
        this.invoiceExportMessage = options.invoiceExportMessage || null;
        this.overrideDepositSlipClassName = options.overrideDepositSlipClassName || null;
        this.overridePremiumCalcsClassName = options.overridePremiumCalcsClassName || null;
        this.invoiceTaxRate = options.invoiceTaxRate || null;
        this.allowCommitmentPolicy = _.parseBool(options.allowCommitmentPolicy);
        this.allowDeleteReceiptsAndDisbursements = _.parseBool(options.allowDeleteReceiptsAndDisbursements);
        this.allowEscrowAccountingBrowserChange = _.parseBool(options.allowEscrowAccountingBrowserChange);
        this.allowNegatibeDepositsOnDepositsSlips = _.parseBool(options.allowNegatibeDepositsOnDepositsSlips);
        this.appendYearToFileNumber = _.parseBool(options.appendYearToFileNumber);
        this.apportionTaxProrationCreditMultipleSellers = _.parseBool(options.apportionTaxProrationCreditMultipleSellers);
        this.overrideAutoCalcCommission = _.parseBool(options.overrideAutoCalcCommission);
        this.autoEmailChangeNotificationsForClosings = _.parseBool(options.autoEmailChangeNotificationsForClosings);
        this.autoEmailChangeNotificationsForEvents = _.parseBool(options.autoEmailChangeNotificationsForEvents);
        this.baseUnderwriterSplitsOnPremiumInfo = _.parseBool(options.baseUnderwriterSplitsOnPremiumInfo);
        this.closeFileOnZeroBalance = _.parseBool(options.closeFileOnZeroBalance);
        this.closeFileOnZeroBalanceAndDate = _.parseBool(options.closeFileOnZeroBalanceAndDate);
        this.commitmentsInUnderwriterReport = _.parseBool(options.commitmentsInUnderwriterReport);
        this.constraintFileLookups = _.parseBool(options.constraintFileLookups);
        this.contextSensitiveAgentLookups = _.parseBool(options.contextSensitiveAgentLookups);
        this.csS360ProrateMonthlyMethod = _.parseBool(options.csS360ProrateMonthlyMethod);
        this.customAttributesCreatingFileFromExisting = _.parseBool(options.customAttributesCreatingFileFromExisting);
        this.daysInYearType = _.parseBool(options.daysInYearType);
        this.defaultCMT = _.parseBool(options.defaultCMT);
        this.defaultSellersForNo1099Reporting = _.parseBool(options.defaultSellersForNo1099Reporting);
        this.displayStreetNumber = _.parseBool(options.displayStreetNumber);
        this.enforceDateValidationsOfDepositSlips = _.parseBool(options.enforceDateValidationsOfDepositSlips);
        this.fileTrackingLargeVolume = _.parseBool(options.fileTrackingLargeVolume);
        this.forceFileStatusName = _.parseBool(options.forceFileStatusName);
        this.forceSelectionOfTypeOfFunds = _.parseBool(options.forceSelectionOfTypeOfFunds);
        this.geographyRequired = _.parseBool(options.geographyRequired);
        this.hideHUD1200Calc = _.parseBool(options.hideHUD1200Calc);
        this.hideLiabilityOverrides = _.parseBool(options.hideLiabilityOverrides);
        this.hideMemberFields = _.parseBool(options.hideMemberFields);
        this.hudSplitRealtorCommissions = _.parseBool(options.hudSplitRealtorCommissions);
        this.largeAgentLookups = _.parseBool(options.largeAgentLookups);
        this.largeBuyerSellerLookups = _.parseBool(options.largeBuyerSellerLookups);
        this.limitMultipleUserFileAccess = _.parseBool(options.limitMultipleUserFileAccess);
        this.logCommitmentPolicyChanges = _.parseBool(options.logCommitmentPolicyChanges);
        this.onlySystemAdminsCanChangeEscrowAccounting = _.parseBool(options.onlySystemAdminsCanChangeEscrowAccounting);
        this.onlySystemAdminsCanEditAccountingCodes = _.parseBool(options.onlySystemAdminsCanEditAccountingCodes);
        this.onlySystemAdminsCanRunEscrowProblemFix = _.parseBool(options.onlySystemAdminsCanRunEscrowProblemFix);
        this.permissibleUseDocumentRequired = _.parseBool(options.permissibleUseDocumentRequired);
        this.premiumTotalsOnlyNonBreakOuts = _.parseBool(options.premiumTotalsOnlyNonBreakOuts);
        this.receiptsAndDisbursementsSortedByDate = _.parseBool(options.receiptsAndDisbursementsSortedByDate);
        this.requireBankNameAndAccountNumberOnInvestmentAccounts = _.parseBool(options.requireBankNameAndAccountNumberOnInvestmentAccounts);
        this.sendRemoteDistributionList = _.parseBool(options.sendRemoteDistributionList);
        this.showExpectedWireDates = _.parseBool(options.showExpectedWireDates);
        this.showInvoiceExportButton = _.parseBool(options.showInvoiceExportButton);
        this.showPremiumSplitsOnHUDCSS = _.parseBool(options.showPremiumSplitsOnHUDCSS);
        this.suggestNextFileNumber = _.parseBool(options.suggestNextFileNumber);
        this.transferMipAmountToHud1000 = _.parseBool(options.transferMipAmountToHud1000);
        this.useCheckPrinterForPrintingDepositSlips = _.parseBool(options.useCheckPrinterForPrintingDepositSlips);
        this.useMultiplePolicyDates = _.parseBool(options.useMultiplePolicyDates);
        this.useWhiteTextTagging = _.parseBool(options.useWhiteTextTagging);
        this.hudSplitAmount = options.hudSplitAmount || null;
        this.agentBuyerSellerLockDown = _.parseNumber(options.agentBuyerSellerLockDown, null);
        this.buyerPaysPercentageMortgagePremium = _.parseNumber(options.buyerPaysPercentageMortgagePremium, null);
        this.constraintFileLookupNumberCharacterMatch = _.parseNumber(options.constraintFileLookupNumberCharacterMatch, null);
        this.daysInWorkWeek = _.parseNumber(options.daysInWorkWeek, null);
        this.duplicateOrderSearchSetupID = _.parseNumber(options.duplicateOrderSearchSetupID, null);
        this.fileNumberPadding = _.parseNumber(options.fileNumberPadding, null);
        this.nextFileNumber = _.parseNumber(options.nextFileNumber, null);
        this.numberOfCharactersToMatchWhenNotFound = _.parseNumber(options.numberOfCharactersToMatchWhenNotFound, null);
        this.orderSeparation = _.parseNumber(options.orderSeparation, null);
        this.schedBLevel = _.parseNumber(options.schedBLevel, null);
        this.scheduleBRequirementLevel = _.parseNumber(options.scheduleBRequirementLevel, null);
        this.sellerPaysPercentageOwnersPremium = _.parseNumber(options.sellerPaysPercentageOwnersPremium, null);
        this.exceptionsRTF = options.exceptionsRTF || null;
        this.legalDescriptionAlta = options.legalDescriptionAlta || null;
        this.legalDescriptionRTF = options.legalDescriptionRTF || null;
        this.restrictiveCovenants = options.restrictiveCovenants || null;
        this.schedileBRequirementsRTF = options.schedileBRequirementsRTF || null;
        this.scheduleBAlta = options.scheduleBAlta || null;
        this.scheduleBRTF = options.scheduleBRTF || null;
        this.scheduleC = options.scheduleC || null;
        this.subLiens = options.subLiens || null;
        this.buyerSellerCaption = _.parseNumber(options.buyerSellerCaption, null);
        this.checkDisplay = _.parseNumber(options.checkDisplay, null);
        this.useBuyerRealtorAsSellingAgent = _.parseBool(options.useBuyerRealtorAsSellingAgent, null);
        this.useSellerRealtorAsListingAgent = _.parseBool(options.useSellerRealtorAsListingAgent, null);
        this.rateEngineUseRegiionIDBranchIDForTransactCode = _.parseBool(options.rateEngineUseRegiionIDBranchIDForTransactCode, null);
        this.rateEngineUseWebService = _.parseBool(options.rateEngineUseWebService, null);
        this.rateEngineWebServiceUserID = options.rateEngineWebServiceUserID || null;
        this.rateEngineWebServicePassword = options.rateEngineWebServicePassword || null;
        this.rate_DefaultLoanAccountingCode = _.parseNumber(options.rate_DefaultLoanAccountingCode, null);
        this.rate_DefaultOwnerAccountingCode = _.parseNumber(options.rate_DefaultOwnerAccountingCode, null);
        this.showOnOwnerInsured = _.parseBool(options.showOnOwnerInsured, null);
        this.showOnMortgageBorrower = _.parseBool(options.showOnMortgageBorrower, null);
        this.showOnCurrentTitleHoldersBuyer = _.parseBool(options.showOnCurrentTitleHoldersBuyer, null);
        this.showOnCurrentTitleHoldersSeller = _.parseBool(options.showOnCurrentTitleHoldersSeller, null);
        this.showOnTitleVestedInBuyer = _.parseBool(options.showOnTitleVestedInBuyer, null);
        this.showTasksForClosedOrdersInDashboard = _.parseBool(options.showTasksForClosedOrdersInDashboard, null);
        this.workflowDashboardRefreshInterval = _.parseNumber(options.workflowDashboardRefreshInterval, null);
        this.internetAlertsRefreshInterval = _.parseNumber(options.internetAlertsRefreshInterval, null);
        this.cssCharges2Hidden = _.parseBool(options.cssCharges2Hidden, null);
        this.cssCharges3Hidden = _.parseBool(options.cssCharges3Hidden, null);
        this.cssCharges4Hidden = _.parseBool(options.cssCharges4Hidden, null);
        this.cssCreditsHidden = _.parseBool(options.cssCreditsHidden, null);
        this.cmPluginURL = options.cmPluginURL || null;
        this.defaultAppliesToType = _.parseNumber(options.defaultAppliesToType, null);
        this.showExpectedWireOutDates = _.parseBool(options.showExpectedWireOutDates, null);
        this.cmActionServiceURL = options.cmActionServiceURL || null;
        this.checkReportsPath = options.checkReportsPath || null;
        this.expectedWiresOutRequireApproval = _.parseBool(options.expectedWiresOutRequireApproval);
        this.useAutoGeneratedWireNumbers = _.parseBool(options.useAutoGeneratedWireNumbers);
        this.wireOutWireNumberRequired = _.parseBool(options.wireOutWireNumberRequired);
        this.wireOutDateOfWireRequired = _.parseBool(options.wireOutDateOfWireRequired);
        this.wireOutExpectedWireOutDateRequired = _.parseBool(options.wireOutExpectedWireOutDateRequired);
        this.wireOutReferenceRequired = _.parseBool(options.wireOutReferenceRequired);
        this.wireOutReceivingBankNameRequired = _.parseBool(options.wireOutReceivingBankNameRequired);
        this.wireOutReceivingBankCityStateZipRequired = _.parseBool(options.wireOutReceivingBankCityStateZipRequired);
        this.wireOutReceivingBankInstructionsRequired = _.parseBool(options.wireOutReceivingBankInstructionsRequired);
        this.wireOutReceivingBankABARequired = _.parseBool(options.wireOutReceivingBankABARequired);
        this.wireOutReceivingBankAccountNumberRequired = _.parseBool(options.wireOutReceivingBankAccountNumberRequired);
        this.wireOutReceivingBankAddressRequired = _.parseBool(options.wireOutReceivingBankAddressRequired);
        this.wireOutReceivingBankCustomerNameRequired = _.parseBool(options.wireOutReceivingBankCustomerNameRequired);
        this.wireOutOfflineBankNameRequired = _.parseBool(options.wireOutOfflineBankNameRequired);
        this.wireOutOfflineBankCityStateZipRequired = _.parseBool(options.wireOutOfflineBankCityStateZipRequired);
        this.wireOutOfflineBankNotesRequired = _.parseBool(options.wireOutOfflineBankNotesRequired);
        this.wireOutOfflineBankABARequired = _.parseBool(options.wireOutOfflineBankABARequired);
        this.wireOutOfflineBankAccountNumberRequired = _.parseBool(options.wireOutOfflineBankAccountNumberRequired);
        this.wireOutOfflineBankAddressRequired = _.parseBool(options.wireOutOfflineBankAddressRequired);
        this.wireOutOfflineBankFurtherCreditToRequired = _.parseBool(options.wireOutOfflineBankFurtherCreditToRequired);
        this.hudAutocalcPerdiem = _.parseBool(options.hudAutocalcPerdiem);
        this.rate_DefaultUnderwriterAccountingCode = _.parseNumber(options.rate_DefaultUnderwriterAccountingCode, null);
        this.allowSearchCCEOrder = _.parseBool(options.allowSearchCCEOrder);
        this.dashboardRecordPerPage = _.parseNumber(options.dashboardRecordPerPage, null);
        this.showTasksWithNoDueDateInDashboard = _.parseBool(options.showTasksWithNoDueDateInDashboard);
        this.listTypeRestrictiveCovenants = options.listTypeRestrictiveCovenants || null;
        this.listTypeRequirements = options.listTypeRequirements || null;
        this.listTypeExceptions = options.listTypeExceptions || null;
        this.listTypeSubordinateLiens = options.listTypeSubordinateLiens || null;
        this.listTypeOwnerSchedB = options.listTypeOwnerSchedB || null;
        this.listTypeMortSchedBResCov = options.listTypeMortSchedBResCov || null;
        this.listTypeMortSchedBExceptions = options.listTypeMortSchedBExceptions || null;
        this.listTypeMortSchedBSubLiens = options.listTypeMortSchedBSubLiens || null;
        this.listTypeInsuredMortgage = options.listTypeInsuredMortgage || null;
        this.listTypeRequiredDocuments = options.listTypeRequiredDocuments || null;
        this.defaultStartTime = options.defaultStartTime || null;
        this.defaultEndTime = options.defaultEndTime || null;
        this.customDate = options.customDate || null;
        this.fileOpenDate = _.parseNumber(options.fileOpenDate, null);
        this.dbVersion = options.dbVersion || null;
        this.logChangesToHUD = _.parseBool(options.logChangesToHUD);
        this.displayCFPBRateRalculationsOnCDF = _.parseBool(options.displayCFPBRateRalculationsOnCDF);
        this.allowAnonymousPCHyperlinks = _.parseBool(options.allowAnonymousPCHyperlinks);
        this.defaultEffectiveTime = options.defaultEffectiveTime || null;
        this.allowPreviewStandardLanguage = _.parseBool(options.allowPreviewStandardLanguage);
        this.combinePrompt = _.parseBool(options.combinePrompt);
        this.goodFundDefinition = _.parseNumber(options.goodFundDefinition, null);
        this.heartbeatFrequency = _.parseNumber(options.heartbeatFrequency, null);
        this.allowCreateChecksForPremiumSplits = _.parseBool(options.allowCreateChecksForPremiumSplits);
        this.isCreateAutoDebitCreditOnCDFForSellerPaidOTP = _.parseBool(options.isCreateAutoDebitCreditOnCDFForSellerPaidOTP);
        this.logChangesToCDF = _.parseBool(options.logChangesToCDF);
        this.showReissueInMemoField = _.parseBool(options.showReissueInMemoField);
        this.rQ1099FilingServiceURL = options.rQ1099FilingServiceURL || null;
        this.displayMarylandTaxes = _.parseBool(options.displayMarylandTaxes);
        this.mergeApiUrlInternal = options.mergeApiUrlInternal || null;
        this.mergeApiUrlExternal = options.mergeApiUrlExternal || null;
        this.mergeApiUrl = options.mergeApiUrl || null;
        this.enablePDFAFormat = _.parseBool(options.enablePDFAFormat);
        this.usePrefVendor = _.parseBool(options.usePrefVendor);
        this.stateExemptionPercentage = _.parseNumber(options.stateExemptionPercentage, null);
        this.cityExemptionPercentage = _.parseNumber(options.cityExemptionPercentage, null);
        this.autoCalcDownPayment = _.parseBool(options.autoCalcDownPayment);
        this.showTaxesAndFeesImportExport = _.parseBool(options.showTaxesAndFeesImportExport);
        this.liveChatURL = options.liveChatURL || null;
        this.nonNestedPromptOnly = _.parseBool(options.nonNestedPromptOnly);
        this.daylightSavingsEnabled = _.parseBool(options.daylightSavingsEnabled);
        this.ready2closeTracking = _.parseBool(options.ready2closeTracking);
        this.exagoDashboardReport = options.exagoDashboardReport || null;
        this.fileInUseTimeoutInMinutes = _.parseNumber(options.fileInUseTimeoutInMinutes, 15);
        this.wireOutRequestDocumentTemplateID = _.parseNumber(options.wireOutRequestDocumentTemplateID, null);
        this.wireOutRequestDocumentTemplate = options.wireOutRequestDocumentTemplate || null;
        this.enterpriseID = options.enterpriseID || null;
        this.partyRoleID = options.partyRoleID || null;
        this.timeZone = options.timeZone || null;
        this.daylightSavingsEnabled = _.parseBool(options.daylightSavingsEnabled);
        this.portfolioManagerAccessInMonths = _.parseNumber(options.portfolioManagerAccessInMonths, 24);
        this.ezJacketUnderwriterCompanyID = _.parseNumber(options.ezJacketUnderwriterCompanyID, null);
        this.ezJacketAllowEndorsementOnlyToggle = _.parseBool(options.ezJacketAllowEndorsementOnlyToggle);
        this.ezJacketShowAllFieldsToggle = _.parseBool(options.ezJacketShowAllFieldsToggle);
        this.cplUnderwriterCompanyID = _.parseNumber(options.cplUnderwriterCompanyID, null);
        this.cmApiEnterpriseGuid = options.cmApiEnterpriseGuid || null;

        //Settlement Statement Settings documents
        this.borrowerHUDDocumentName = options.borrowerHUDDocumentName || null;
        this.sellerHUDDocumentName = options.sellerHUDDocumentName || null;
        this.huD1DocumentName = options.huD1DocumentName || null;
        this.huD1ADocumentName = options.huD1ADocumentName || null;
        this.purchaserCSSDocumentName = options.purchaserCSSDocumentName || null;
        this.sellerCSSDocumentName = options.sellerCSSDocumentName || null;
        this.borrowerCSSDocumentName = options.borrowerCSSDocumentName || null;
        this.huD1974_BorrowerDocumentName = options.huD1974_BorrowerDocumentName || null;
        this.huD1974_SellerDocumentName = options.huD1974_SellerDocumentName || null;
        this.huD1974_1DocumentName = options.huD1974_1DocumentName || null;
        this.huD1974_1ADocumentName = options.huD1974_1ADocumentName || null;
        this.purchaserSellerCSSDocumentName = options.purchaserSellerCSSDocumentName || null;
    }

    get borrowerHUDDocumentNameID() { return this.getDocId("BorrowerHUDDocumentName"); }
    set borrowerHUDDocumentNameID(val) { this.setDocId("BorrowerHUDDocumentName", val); }

    get sellerHUDDocumentNameID() { return this.getDocId("SellerHUDDocumentName"); }
    set sellerHUDDocumentNameID(val) { this.setDocId("SellerHUDDocumentName", val); }

    get huD1DocumentNameID() { return this.getDocId("HUD1DocumentName"); }
    set huD1DocumentNameID(val) { this.setDocId("HUD1DocumentName", val); }

    get huD1ADocumentNameID() { return this.getDocId("HUD1ADocumentName"); }
    set huD1ADocumentNameID(val) { this.setDocId("HUD1ADocumentName", val); }

    get purchaserCSSDocumentNameID() { return this.getDocId("PurchaserCSSDocumentName"); }
    set purchaserCSSDocumentNameID(val) { this.setDocId("PurchaserCSSDocumentName", val); }

    get sellerCSSDocumentNameID() { return this.getDocId("SellerCSSDocumentName"); }
    set sellerCSSDocumentNameID(val) { this.setDocId("SellerCSSDocumentName", val); }

    get borrowerCSSDocumentNameID() { return this.getDocId("BorrowerCSSDocumentName"); }
    set borrowerCSSDocumentNameID(val) { this.setDocId("BorrowerCSSDocumentName", val); }

    get huD1974_BorrowerDocumentNameID() { return this.getDocId("HUD1974_BorrowerDocumentName"); }
    set huD1974_BorrowerDocumentNameID(val) { this.setDocId("HUD1974_BorrowerDocumentName", val); }

    get huD1974_SellerDocumentNameID() { return this.getDocId("HUD1974_SellerDocumentName"); }
    set huD1974_SellerDocumentNameID(val) { this.setDocId("HUD1974_SellerDocumentName", val); }

    get huD1974_1DocumentNameID() { return this.getDocId("HUD1974_1DocumentName"); }
    set huD1974_1DocumentNameID(val) { this.setDocId("HUD1974_1DocumentName", val); }

    get huD1974_1ADocumentNameID() { return this.getDocId("HUD1974_1ADocumentName"); }
    set huD1974_1ADocumentNameID(val) { this.setDocId("HUD1974_1ADocumentName", val); }

    get purchaserSellerCSSDocumentNameID() { return this.getDocId("PurchaserSellerCSSDocumentName"); }
    set purchaserSellerCSSDocumentNameID(val) { this.setDocId("PurchaserSellerCSSDocumentName", val); }

    static get documentNames() { return SYSTEM_DEFAULT_DOCUMENTS; }
}

export class CDFSystemDefaultDto extends SettlementStatementDocumentDefaults {
	constructor(options, docs=[]) {
        options = options || {};

        super(docs, CDF_DEFAULT_DOCUMENTS);

		this.systemDefaultID = _.parseNumber(options.systemDefaultID, null);
		this.sectionAMaxLine = _.parseNumber(options.sectionAMaxLine, null);
		this.sectionBMaxLine = _.parseNumber(options.sectionBMaxLine, null);
		this.sectionCMaxLine = _.parseNumber(options.sectionCMaxLine, null);
		this.sectionDMaxLine = _.parseNumber(options.sectionDMaxLine, null);
		this.sectionEMaxLine = _.parseNumber(options.sectionEMaxLine, null);
		this.sectionGMaxLine = _.parseNumber(options.sectionGMaxLine, null);
		this.sectionHMaxLine = _.parseNumber(options.sectionHMaxLine, null);
		this.prorationMaxLine = _.parseNumber(options.prorationMaxLine, null);
		this.lastChangedBy = options.lastChangedBy || null;
		this.lastChanged = options.lastChanged || null;
		this.lastChangedByUserID = _.parseNumber(options.lastChangedByUserID, 0);
		this.isPCUser = _.parseBool(options.isPCUser, null);
		this.cdfVersion = options.cdfVersion || null;
		this.useTemplatePage2A = options.useTemplatePage2A || null;
		this.transProTaxFromPrepaidsToEscrows = _.parseBool(options.transProTaxFromPrepaidsToEscrows);
        this.promptforCDFTransactionWithoutSeller = _.parseBool(options.promptforCDFTransactionWithoutSeller);

        //Settlement Statement Settings Documents
		this.borrowerCDFDocumentName = options.borrowerCDFDocumentName || null;
		this.sellerCDFDocumentName = options.sellerCDFDocumentName || null;
		this.cdfDocumentName = options.cdfDocumentName || null;
		this.cdfDocumentName_P2ab = options.cdfDocumentName_P2ab || null;
		this.borrowerCDFDocumentName_P2ab = options.borrowerCDFDocumentName_P2ab || null;
		this.sellerCDFDocumentName_P2ab = options.sellerCDFDocumentName_P2ab || null;
		this.toscdfDocumentName = options.toscdfDocumentName || null;
		this.toscdfDocumentName_P2ab = options.toscdfDocumentName_P2ab || null;
		this.altassBorrowerDocument = options.altassBorrowerDocument || null;
		this.altassCombinedDocument = options.altassCombinedDocument || null;
		this.altassSellerDocument = options.altassSellerDocument || null;
        this.altassCashDocument = options.altassCashDocument || null;

        //no longer in use
		this.cdfSystemName = options.cdfSystemName || null;
		this.cdfUrl = options.cdfUrl || null;
	}

    get altassBorrowerDocumentID() { return this.getDocId("ALTASSBorrowerDocument"); }
    set altassBorrowerDocumentID(val) { this.setDocId("ALTASSBorrowerDocument", val); }

    get altassCashDocumentID() { return this.getDocId("ALTASSCashDocument"); }
    set altassCashDocumentID(val) { this.setDocId("ALTASSCashDocument", val); }

    get altassCombinedDocumentID() { return this.getDocId("ALTASSCombinedDocument"); }
    set altassCombinedDocumentID(val) { this.setDocId("ALTASSCombinedDocument", val); }

    get altassSellerDocumentID() { return this.getDocId("ALTASSSellerDocument"); }
    set altassSellerDocumentID(val) { this.setDocId("ALTASSSellerDocument", val); }

    get borrowerCDFDocumentNameID() { return this.getDocId("BorrowerCDFDocumentName"); }
    set borrowerCDFDocumentNameID(val) { this.setDocId("BorrowerCDFDocumentName", val); }

    get borrowerCDFDocumentName_P2abID() { return this.getDocId("BorrowerCDFDocumentName_P2ab"); }
    set borrowerCDFDocumentName_P2abID(val) { this.setDocId("BorrowerCDFDocumentName_P2ab", val); }

    get cdfDocumentNameID() { return this.getDocId("CDFDocumentName"); }
    set cdfDocumentNameID(val) { this.setDocId("CDFDocumentName", val); }

    get cdfDocumentName_P2abID() { return this.getDocId("CDFDocumentName_P2ab"); }
    set cdfDocumentName_P2abID(val) { this.setDocId("CDFDocumentName_P2ab", val); }

    get sellerCDFDocumentNameID() { return this.getDocId("SellerCDFDocumentName"); }
    set sellerCDFDocumentNameID(val) { this.setDocId("SellerCDFDocumentName", val); }

    get sellerCDFDocumentName_P2abID() { return this.getDocId("SellerCDFDocumentName_P2ab"); }
    set sellerCDFDocumentName_P2abID(val) { this.setDocId("SellerCDFDocumentName_P2ab", val); }

    get toscdfDocumentNameID() { return this.getDocId("TOSCDFDocumentName"); }
    set toscdfDocumentNameID(val) { this.setDocId("TOSCDFDocumentName", val); }

    get toscdfDocumentName_P2abID() { return this.getDocId("TOSCDFDocumentName_P2ab"); }
    set toscdfDocumentName_P2abID(val) { this.setDocId("TOSCDFDocumentName_P2ab", val); }

    static get documentNames() { return CDF_DEFAULT_DOCUMENTS; }
}
export class WebDefaultsDto {
	constructor(options) {
        options = options || {};
        this.webDefaultsID = _.parseNumber(options.webDefaultsID, 0);
        this.publishAllOrders = _.parseBool(options.publishAllOrders, false);
        this.restrictToDocumentOwner = _.parseBool(options.restrictToDocumentOwner, false);
        this.autoEmailEvents = _.parseBool(options.autoEmailEvents, false);
        this.autoEmailDocumentNotifications = _.parseBool(options.autoEmailDocumentNotifications, false);
        this.publishOrderNotes = _.parseBool(options.publishOrderNotes, false);
        this.publishWorkflow = _.parseBool(options.publishWorkflow, false);
        this.publishReceiptsDisbursements = _.parseBool(options.publishReceiptsDisbursements, false);
        this.publishPropertyInformation = _.parseBool(options.publishPropertyInformation, false);
        this.postOrderSubmissionRedirectURL = options.postOrderSubmissionRedirectURL || null;
        this.postLogoffRedirectURL = options.postLogoffRedirectURL || null;
        this.smtpServer = options.smtpServer || null;
        this.smtpPort = options.smtpPort || null;
        this.defaultEmailName = options.defaultEmailName || null;
        this.defaultEmailAddress = options.defaultEmailAddress || null;
        this.paperlessCloserBaseURL = options.paperlessCloserBaseURL || null;
        this.pCAccessDaysInactiveThreshold = options.pCAccessDaysInactiveThreshold || null;
        this.paperlessCloserAdminEmail = options.paperlessCloserAdminEmail || null;
        this.includeUserOnDraftEmailCCLine = options.includeUserOnDraftEmailCCLine || null;
        this.allowBCConAutoEmails = options.allowBCConAutoEmails || null;
        this.publishCDF = options.publishCDF || null;
        this.applicationUrl = options.applicationUrl || null;
        this.tenantClientId = options.tenantClientId || null;
        this.cmServiceUsersID = _.parseNumber(options.cmServiceUsersID, null);
    }

    toDataObject() { return _.toPlainObject(this); }
}

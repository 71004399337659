<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        :automation_id="elementName('tbl')"
        :actions="selectionActions"
        :config="gridConfig"
        :data-source="gridDataSource"
        :export-file-name="elementName('', 'data')"
        @full-access="onFullAccess"
        @read-only="onReadOnly"
        @no-access="onNoAccess"
        @reset="onReset"
        fixed-header
        rq-filters
        integrated-search
    />
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { DocumentPackageSecurityUserDto }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { DefaultUserSecurity } from "@config/enums";

    export default {
        name:"DocumentPackageUserSecurityForm",
        props: {
            item: {type: Object, default: null},
            itemTypeName: {type: String, default: null},
            userGroups: { type: Array, default: () => [] }
        },
        data () {
            return {
                validationErrors: [],
                itemTypeNamePlural: "Document Package Security Users" ,
                itemKey: "usersID",
                items: [],
                selectedItems: {},
                addEventName: ""
            };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            regions() { return _.map(this.lookupHelpers.getRegions(), r => ({ ...r, displayLabel: `${r.regID} - ${r.description}` })); },
            selectionActions() {
                return [{
                    name: "full-access",
                    eventName: "full-access",
                    text: `Set Full Access`,
                    allowMultiSelection: false,
                    tooltip: `Full Access`
                },
                {
                    name: "read-only",
                    eventName: "read-only",
                    text: `Set Read Only`,
                    allowMultiSelection: true,
                    tooltip: `Read Only`
                },
                {
                    name: "noAccess",
                    eventName: "no-access",
                    text: `Set No Access`,
                    allowMultiSelection: true,
                    tooltip: `No Access`
                }];
            },
            parentRoute() {
                let lastMatched = _.findLast(this.$route.matched);
                return {path: lastMatched.parent.path || "", category: lastMatched.meta.category || "(Unknown)"};
            },
            securityAccess() {
                return DefaultUserSecurity.lookupItems;
            }
        },

        created(){
            const self = this;
            self.initGridConfig();
            self.initListeners();
            self.fetchData();
        },

        beforeUnmount() {
            this.$events.off(this.addEventName, this.onAddItem);
        },
        methods: {

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        { dataField: "usersID", visible: false, allowEditing: false },
                        {
                            dataField: "userFullName"
                        },
                        {   dataField: "regionDisplay",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            caption: "User Default Region",
                            rqFilter: {
                                displayExpr: "displayLabel",
                                valueExpr: "displayLabel",
                                filterType: "tags",
                                dataSource: {
                                    loadMode: "raw",
                                    load() {
                                        return self.regions;
                                    }
                                }
                            }
                        },
                        {   dataField: "userGroupName",
                            caption: "User Security Role",
                            rqFilter: {
                                displayExpr: "login",
                                valueExpr: "login",
                                filterType: "tags",
                                dataSource: {
                                    loadMode: "raw",
                                    load() {
                                        return self.userGroups;
                                    }
                                }
                            }
                        },
                        {
                            dataField: "securityAccessName",
                            caption: "Security Access",
                            rqFilter: {
                                displayExpr: "name",
                                valueExpr: "name",
                                dataSource: {
                                    loadMode: "raw",
                                    load() {
                                        return self.securityAccess;
                                    }
                                }
                            }
                        },
                        { type: "buttons", visible: false, showInColumnChooser: false }
                    ],
                    height: 490,
                };
                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },

                };
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.DocumentPackageSecurityUsersApi.getDocumentPackageSecurityUsers(self.item.documentPackageID);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new DocumentPackageSecurityUserDto(i));
                        self.gridInstance.refresh();
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            onReset(e) {
                this.fetchData();
            },

            onNoAccess(e){
                const self = this;
                var items = e.data;
                self.clearCurrent(items);
                self.setAccess(items, 0, 'No Access');
                self.setChangedItems(items);
            },

            onFullAccess(e){
                const self = this;
                var items = e.data;
                self.clearCurrent(items);
                self.setAccess(items, 2, 'Full Access');
                self.setChangedItems(items);
            },

            onReadOnly(e){
                const self = this;
                var items = e.data;
                self.clearCurrent(items);
                self.setAccess(items, 1, 'Read Only');
                self.setChangedItems(items);
            },

            setChangedItems(items){
                const self = this;
                _.each(items, k => {
                    _.remove(self.selectedItems, (i) => {return _.parseNumber(i.usersID) == _.parseNumber(k.usersID);});
                });
                self.selectedItems = _.concat(self.selectedItems,items);
                self.gridInstance.clearSelection();
                self.gridInstance.refresh();
            },

            setAccess(items,value,text)
            {
                const self = this;
                self.clearCurrent(items);
                _.forEach(items, (item) =>  {
                    _.set(item, 'securityAccess', value);
                    _.set(item, 'securityAccessName', text);
                });
            },

            clearCurrent(items){
                _.forEach(items, (item) =>  {
                    _.set(item, 'securityAccess', null);
                });
            },
        }
    }
</script>

<template>
    <div class="content-wrapper">
        <rqdx-action-data-grid
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            @edit="onEditItem"
            @delete="onDeleteItem"
            @rowDoubleClick="onEditItem"
            integrated-search
            rq-filters
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { TitleUnitDto }  from "../models";
    import TitleUnitsForm  from "./TitleUnitsForm.vue";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"TitleUnitsList",
        props: { },
        data () {
            return {
                items: [],
                selectedItem: {},
                validationErrors: [],
                verifiedItem: {},
                addEventName: ""
            };
        },
        created(){
            this.initGridConfig();
            this.initListeners();
        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null) || {}; },
            itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            selectionActions() {
                return [{ name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: `Edit ${this.itemTypeName}` },
                        { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` }];
            },
        },
        methods: {
            addNewItem() {
                const self = this;
                self.gridInstance.deselectAll();
                self.selectedItem = new TitleUnitDto({regionID: self.user.regionID});
                self.loadItem(new TitleUnitDto({regionID: self.user.regionID}));
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            onAddItem() {
                this.addNewItem();
            },

            onEditItem(e) {
                const self = this;
                self.selectedItem = new TitleUnitDto(e.data);
                self.loadItem(new TitleUnitDto(e.data));
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let apiPromises = [];
                    _.forEach(items, (item) => {
                        let key = _.get(item, self.itemKey);
                        apiPromises.push(self.$api.TitleUnitsApi.deleteTitleUnit(key));
                    });
                    return self.$rqBusy.wait(Promise.all(apiPromises))
                        .then(key => {
                            self.deleteItem([...key]);
                            let message = key.length > 1 ? `${key.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;}
                        )
                        .catch(error => {
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        { dataField: "regionDisplay", caption: "Region", calculateSortValue: DxGridUtils.regionDisplaySortValue },
                        { dataField: "shortDescription", caption: "Name" },
                        { dataField: "description" },
                        { dataField: "filePoolDisplay", caption: "File Pool" },
                        { dataField: "prefix" },
                        { dataField: "suffix" },
                        { dataField: "leaderDisplay" , caption: "Leader" }
                    ],
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load: self.fetchData,
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.TitleUnitsApi.getTitleUnits();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let items = _.map(result, i => new TitleUnitDto(i));
                        self.items = items;
                        return { data: items, totalCount: items.length };
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },

            loadItem(item) {
                const self = this;
                let dialogId = null;
                let refreshOnExit = false;
                let onOk = (e, addAnother=false) => {
                    return e.component.save()
                        .then(success => {
                            if(!success) return false;
                            self.refresh();
                            if (addAnother) {
                                self.$dialog.reloadComponent({ dialogId, props: { item: new TitleUnitDto({regionID: self.user.regionID}) } });
                                return false;
                            }
                            return success;
                        });
                };

                let onCancel = () => true;

                dialogId = self.$dialog.open({
                    title: `${item.isNew ? "Add": "Edit"} ${self.itemTypeName}${(item.isNew ? "" : `: ${item.description}`)}`,
                    width: "1200",
                    adaptive: true,
                    selectable: false,
                    component: TitleUnitsForm,
                    props: {
                        item,
                        uniqueValidator: (item,field) => !self.isDuplicate(item, field),
                    },
                    buttons: [
                        { title: "Cancel", automationId: "btn_dm_modal_cancel", cssClass: "btn btn-secondary", onClick: onCancel },
                        { title: "Save and Add Another", automationId: "btn_dm_modal_save_and_another", cssClass: "btn btn-primary", isVisible: item.isNew, onClick: (e) => { return onOk(e, true); } },
                        { title: "Save", automationId: "btn_dm_modal_save", cssClass: "btn btn-primary", onClick: (e) => { return onOk(e, false); } }
                    ]
                });
            },

            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            addItem(item) {
                this.items.push(new TitleUnitDto(item));
            },

            deleteItem(keys) {
                _.each(keys, k => {
                    _.remove(this.items, (i) => {return _.parseNumber(_.get(i, this.itemKey, -1), -1) == k;});
                });
            },

            isDuplicate(item, fieldName){
                const self = this;
                let trimLower = val => _.toLower(_.trim(val));
                return _.some(self.items, (i) =>
                    trimLower(i[fieldName]) === trimLower(item[fieldName])
                    && _.parseNumber(item.regionID, -1) === _.parseNumber(i.regionID, -1)
                    && _.parseNumber(i[self.itemKey], -1) !== _.parseNumber(item[self.itemKey], -1)
                );
            }
        }
    }
</script>

<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        :automation_id="elementName('tbl')"
        :actions="selectionActions"
        :config="gridConfig"
        :data-source="gridDataSource"
        :export-file-name="elementName('', 'data')"
        :strikethrough-if-true="['inactive']"
        focus-after-insert="none"
        @delete="onDeleteItem"
        @edit-users="onEditUsers"
        @edit-sections="onEditSections"
        v-model:validation-errors="validationErrors"
        @export-category="onExportCategory($event, 0)"
        @export-category-zipped="onExportCategory($event, 1)"
        @import-file="onImportToCategory($event, false)"
        @import-folder="onImportToCategory($event, true)"
        integrated-search
        rq-editable
        rq-filters
        hide-show-column-chooser>
        <form ref="fileForm" class="d-none" enctype="multipart/form-data" novalidate>
            <input
                id="fileInput"
                ref="fileInput"
                type="file"
                automation_id="txt_upload_file"
                name="fileInput"
                @change="onUploadFileChange"
                accept=".rtf"
                multiple
            >
            <input
                id="folderInput"
                ref="folderInput"
                type="file"
                automation_id="txt_upload_folder"
                name="folderInput"
                @change="onUploadFolderChange"
                accept=".rtf"
                webkitdirectory
            >
        </form>
    </rqdx-action-data-grid>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { StandardLanguageShortDto, StandardLanguageCategoryDto }  from "../../models";
    import { UserGroupDto }  from "../../../enterprise/models";
    import { UserSecuritySettings } from "@/shared/models/models";
    import { DefaultUserSecurity } from "@config/enums";
    import StandardLanguageCategoryUserSecurityForm from "./StandardLanguageCategoryUserSecurityForm";
    import StandardLanguageCategorySectionList from "./StandardLanguageCategorySectionList";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"StandardLanguageCategoryList",
        props: {
            items: { type: Array, default: () => [] },
            categorySections: { type: Array, default: () => [] },
            validationList: { type: Array, default: () => []}
        },
        data () {
            return {
                acceptedFileTypes: [".rtf"],
                addEventName: "",
                files: [],
                fileData: [],
                importRegionID: 0,
                importStandardLanguageCategoryID: 0,
                totalUploadSize: 0,
                maxUploadSize: 25*1024*1024,//iis is set to 26Mb, so i peel off 1 Mb for the data
                validationErrors: []
            };
        },

        watch: {
             validationErrors: function () {
                const self = this;
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });
                this.$emit("update:validationList", this.validationErrors);
            },
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user,
                authSettings: state => state.authentication.session.settings,
                userRegions: state => state.authentication.session.regions
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            fileForm() { return _.get(this.$refs, "fileForm", null) || {}; },
            fileInput() { return _.get(this.$refs, "fileInput", null) || {}; },
            folderInput() { return _.get(this.$refs, "folderInput", null) || {}; },
            gridInstance() { return _.get(this.$refs, "dataGrid.gridInstance", null); },
            isSysAdmin(){
                const self = this;
                let settings = new UserSecuritySettings(self.authSettings);
                let value = settings.findValue("IsSysAdmin");
                return value;
            }
        },

        created() {
            this.fetchData();
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.initListeners();
        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        methods: {
            base64ToArrayBuffer(base64) {
                var binary_string = window.atob(base64);
                var len = binary_string.length;
                var bytes = new Uint8Array(len);
                for (var i = 0; i < len; i++) {
                    bytes[i] = binary_string.charCodeAt(i);
                }
                return bytes.buffer;
            },

            clearForm() {
                this.files = [];
                this.fileData = [];
                this.totalUploadSize = 0;
                if (!this.fileForm) return;
                this.fileForm.reset();
                if (!this.fileInput) return;
                this.fileInput.value = null;
                if (!this.folderInput) return;
                this.folderInput.value = null;
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            exportClauses(id, zip){
                const self = this;
                let apiPromise = self.$api.StandardLanguageCategoryApi.exportClauses(id, zip);
                self.$rqBusy.wait(apiPromise)
                    .then(files => {
                        _.each(files, file => {
                            self.saveToClient(file);
                        });
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error Exporting Clauses.` });
                    });
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.UsersApi.getGroups();
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.userGroups = _.map(result, i => new UserGroupDto(i));
                    })
                    .catch(error => {
                        console.error(error);
                    });
            },

            initNonReactiveVariables() {
                const self = this;
                this.itemTypeName = "Clause Category";
                this.itemTypeNamePlural = "Clause Categories";
                this.itemKey = "standardLanguageCategoryID";
                this.securityLevels = _.pullAllBy(DefaultUserSecurity.lookupItems, [{id: DefaultUserSecurity.ReadOnly}], "id");
                this.selectionActions = [
                    {
                        name: "edit",
                        text: "Edit User Security",
                        eventName: "edit-users",
                        requireSelection: true,
                        tooltip: "Edit User Security"
                    },
                    {
                        name: "edit",
                        text: "View Sections",
                        eventName: "edit-sections",
                        requireSelection: true,
                        tooltip: "View Assigned Title Production Sections"
                    },
                    { name: "export", text: "Export", tooltip: "Export all Clauses assigned to this category",
                        children: [
                            { name: "export-category", text: "Export", eventName: "export-category", requireSelection: true, tooltip: "Export all Clauses assigned to this category" },
                            { name: "export-category-zipped", text: "Export (Zipped)", eventName: "export-category-zipped", requireSelection: true, tooltip: "Export all Clauses assigned to this category to a Zip File" },
                        ]
                    },
                    { name: "import", text: "Import", tooltip: "Import Clauses into this category",
                        children: [
                            { name: "import-file", text: "File(s)", eventName: "import-file", requireSelection: true, tooltip: "Import individual .rtf files to this category" },
                            { name: "import-folder", text: "Folder", eventName: "import-folder", requireSelection: true, tooltip: "Import all .rtf files in a folder to this category" },
                        ]
                    },
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${this.itemTypeName}`,
                        disabled: function(e) {  return !self.isSysAdmin; }
                    }
                ];
            },

            isValidFileExt(fileExt){
                return _.includes(this.acceptedFileTypes, _.toLower(fileExt));
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    columns: [
                        {
                            dataField: "standardLanguageCategoryID",
                            visible: false,
                            showInColumnChooser: false
                        },
                        {
                            dataField: "category",
                            editorOptions: { maxLength: 15 },
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "custom",
                                    validationCallback: item => self.isUniqueItem(item.data),
                                    message: "Category already exists"
                                } 
                            ]
                        },
                        {
                            dataField: "description",
                            editorOptions: { maxLength: 70 },
                            validationRules: [{ type: "required" }]
                        },
                        {
                            dataField: "defaultUserSecurityAccess",
                            caption: "Default User Sec",
                            lookup: {
                                dataSource: self.securityLevels,
                                valueExpr: "id",
                                displayExpr: "name"
                            },
                            width: 150,
                            allowEditing: self.isSysAdmin
                        },
                        {
                            dataField: "hasSecurityExceptions",
                            caption: "User Sec Exceptions",
                            alignment: "center",
                            dataType: "boolean",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            allowEditing: false,
                            width: 170
                        },
                        {
                            dataField: "sectionsApplied",
                            caption: "Sections Applied",
                            alignment: "center",
                            cellTemplate: DxGridUtils.boolCellTemplate,
                            allowEditing: false,
                            width: 150
                        },
                    ],
                };


                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            isUniqueItem(row){
                const self = this;
                let dup = {};
                dup = _.find(self.items, (i) => {
                    return _.toLower(_.trim(i.category)) === _.toLower(_.trim(row.category))
                           && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(row, self.itemKey, -1), -1);
                });
                return _.isEmpty(dup);
            },

            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow();
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let toBeDeletedKeys = _.map(items, self.itemKey);

                    let apiPromise = self.$api.StandardLanguageCategoryApi.delete(toBeDeletedKeys);
                    return self.$rqBusy.wait(apiPromise)
                        .then(keys => {
                            self.$emit("remove", { keys });
                            let message = keys.length > 1 ? `${keys.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                            if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onEditUsers(e){
                this.showUserSecurityDialog(e.data);
            },

            onEditSections(e){
                this.showSectionsDialog(e.data);
            },

            onExportCategory(e, zip) {
                if(!e || !e.data) return;
                const self = this;
                let key = _.get(e.data, self.itemKey);
                self.exportClauses(key, zip);              
            },

            onUploadFileChange(e) {
                if (!e.target.files.length) return;
                this.processFiles(e.target.files);
            },

            onUploadFolderChange(e) {
                if (!e.target.files.length) return;
                this.processFiles(e.target.files);
            },

            onGridInsert(values) {
                const self = this;
                let newItem = new StandardLanguageCategoryDto(values);
                self.errorMessage = self.validationErrors;
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                self.save(newItem, changes)
                    .then(result => {
                        self.$toast.success({ message: `${self.itemTypeName} ${newItem.category} was created.` });
                        self.$emit("add", result);
                    }).catch(error => {
                        self.$toast.error({ message: `Error creating ${self.itemTypeName}.` });
                        console.error(error);
                        return error;
                    });
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, [self.itemKey, key]);
                if(itemIndex < 0) return;

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new StandardLanguageCategoryDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                self.save(updatedItem, changes)
                    .then(result => {
                        if (!result) return;
                        self.$toast.success({ message: `${self.itemTypeName} ${updatedItem.category} was saved.` });
                        self.$emit("update", result);
                    })
                    .catch(error => {
                        self.$toast.error(`Error saving ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },

            onImportToCategory(e, byFolder) {
                const self = this;
                if(!e || !e.data || !self.fileInput || !self.folderInput) return;
                self.importStandardLanguageCategoryID = _.get(e.data, "standardLanguageCategoryID", 0);
                self.$dialog.promptSelect({
                    title: "Select Region",
                    label: "Region",
                    items: self.lookupHelpers.getRegions(),
                    valueExpr: "regionID",
                    displayExpr: "displayName",
                    value: self.user.regionID,
                    requiredMessage: "Please select a Region",
                    onOk: e => {
                        self.importRegionID = e.selectedValue ?? self.user.regionID;
                        if (byFolder) {
                            self.folderInput.click();
                        } else {
                            self.fileInput.click();                           
                        }
                        return true;
                    }
                });

            },

            processFiles (files = []) {
                if (files.length == 0) return;
                const self = this;

                let standardLanguageID = 0;
                _.each(files, f => {
                    standardLanguageID--;
                    let fileExt = f.name.substr(f.name.lastIndexOf('.'));
                    let description = f.name.substr(0, f.name.lastIndexOf('.'));
                    let standardLanguage = new StandardLanguageShortDto(
                        {
                            standardLanguageID: standardLanguageID,
                            standardLanguageCategoryID: self.importStandardLanguageCategoryID,
                            description: description,
                            regionID: self.importRegionID,
                        });
                    if (self.isValidFileExt(fileExt)) {
                        self.totalUploadSize += f.size;
                        self.files.push(f);
                        self.fileData.push(standardLanguage);
                    }
                });
                if (self.totalUploadSize > self.maxUploadSize) {
                    self.errorMessage = "You have gone over the maximum upload size, please select fewer files.";
                    return;
                }
                if (self.files.length == 0) {
                    self.errorMessage = "No files detected of the required type.";
                    return;
                }
                self.saveClauses()
                    .then(result => {
                        self.$emit("refresh-clauses");
                        self.$toast.success(`${self.files.length} Clause${self.files.length > 0 ? "s" : ""} Imported.`);
                    }).catch(error => {
                        self.$toast.error({ message: `Error Importing Clause${self.files.length > 0 ? "s" : ""}.` });
                        console.error(error);
                    })
                    .finally(() => {
                        self.clearForm();
                        self.refresh();
                    });
            },

            refresh() {
                this.gridInstance.option("focusedRowIndex", -1);
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            updateDimensions() {
                this.$nextTick(() => {
                    this.gridInstance.updateDimensions();
                    this.gridInstance.repaint();
                });
            },

            save(item, changes){
                const self = this;
                if(changes.length === 0) {
                    self.$toast.info({ message: `No changes detected.` });
                    return Promise.resolve(false);
                }
                if (!self.isUniqueItem(item)) {
                    self.errorMessage = "That Category Name already exists.";
                    return Promise.reject(self.errorMessage);
                }

                let apiPromise = self.$api.StandardLanguageCategoryApi.save({data: item.toDataObject(), changes});
                return self.$rqBusy.wait(apiPromise);
            },

            saveClauses(){
                const self = this;
                const formData = new FormData();
                _.each(self.files, f => {
                    formData.append(`file-${f.name}`, f);
                });
                formData.append('data-', JSON.stringify(self.fileData));
                let apiPromise = self.$api.StandardLanguageCategoryApi.importClauses(formData);
                return self.$rqBusy.wait(apiPromise);
            },

            saveToClient(file) {
                const self = this;
                var binary = self.base64ToArrayBuffer(file.content);
                var blob = new Blob([binary], {type: file.contentType});
                if (window.navigator.msSaveOrOpenBlob) // IE10+
                    window.navigator.msSaveOrOpenBlob(blob, file.commonFileName);
                else { // Others
                    var a = document.createElement("a")
                    var url = URL.createObjectURL(blob);
                    a.href = url;
                    a.download = file.commonFileName;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function() {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 0);
                }
            },

            showSectionsDialog(item) {
                const self = this;
                let key = _.get(item, self.itemKey);
                let categorySections = _.filter(self.categorySections, { [self.itemKey]: key });
                self.$dialog.open({
                    title: `Clause Sections: ${item.description}`,
                    height: 700,
                    width: 700,
                    resizable: true,
                    scrollable: false,
                    component: StandardLanguageCategorySectionList,
                    props: { categorySections },
                    okOnly: true
                });
            },

            showUserSecurityDialog(item) {
                const self = this;
                let key = _.get(item, self.itemKey);
                let itemIndex = _.findIndex(self.items, [self.itemKey, key]);
                let onOk = (e) => e.component.save()
                    .then((result) => {
                        if (_.isBoolean(result)) return result;
                        self.$emit("update", result);
                        return true;
                    });
                self.$dialog.open({
                    title: `Edit Clause User Security : ${item.description}`,
                    height: "700",
                    width: "850",
                    resizable: true,
                    scrollable: true,
                    adaptive: false,
                    closeOnEsc: true,
                    component: StandardLanguageCategoryUserSecurityForm,
                    props: {
                        standardLanguageCategoryID: key,
                        defaultUserSecurity: item.defaultUserSecurityAccess,
                        userGroups: self.userGroups
                    },
                    onOk
                });
            },
        }
    }
</script>
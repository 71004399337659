<template>
    <div class="content-wrapper" v-if="hasTaxConfiguration">
        <rq-page-section :title="itemTypeName" headerSize="md" :id="elementName('rq-page')" borderless>
            <div class="row">
                <div class="col col-3 form-group">
                    <label for="drp_coverage_rounding_id">Coverage Rounding Method</label>
                    <dx-select-box
                        :input-attr="{ automation_id: 'drp_coverage_rounding_id', id: 'drp_coverage_rounding_id' }"
                         @valueChanged="refreshGrid()"
                        :items="coverageRoundingMethods"
                        v-model="transferTaxConfiguration.coverageRoundingID"
                        value-expr="id"
                        display-expr="name"
                        :disabled="readOnly"
                        :search-enabled="true"
                    />
                </div>
            </div>
            <rqdx-action-data-grid
                v-if="transferTaxConfiguration.coverageRoundingID === 4"
                ref="dataGrid"
                :automation_id="elementName('tbl')"
                :actions="selectionActions"
                :config="gridConfig"
                :data-source="gridDataSource"
                :export-file-name="elementName('', 'data')"
                v-model:validation-errors="validationErrors"
                :persist-state="false"
                :rq-editable="!readOnly"
                @delete="onGridDelete"
                hide-show-column-chooser
                hide-search>
                <template #toolbar>
                    <ul class="nav">
                        <li class="nav-item">
                            <b-btn
                                :automation_id="elementName('btn_add')"
                                variant="theme"
                                :disabled="readOnly"
                                v-rq-tooltip.hover.top="{ title: `Add ${itemTypeName}` }"
                                @click="onGridAdd">Add
                            </b-btn>
                        </li>
                    </ul>
                </template>
            </rqdx-action-data-grid>
        </rq-page-section>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { TransferTaxCoverageRoundingDto }  from "../models";
import DxGridUtils from "@/shared/utilities/DxGridUtils";
import { CoverageRoundingMethod, RoundingRule } from '../../enums';

export default {
    name: 'TransferTaxConfigurationCoverageRoundingForm',
    props: {
        transferTaxConfiguration: { type: Object, required: true, default: () => {} },
        items: {type: Array, default: () => []},
        validationList: {type: Array, default: () => []}
    },
    data() {
        return {
            validationErrors: [],
        }
    },

    computed: {
        ...mapState({
            readOnly: state => state.isPageReadOnly,
        }),
        ...mapGetters([
            "lookupHelpers",
            "lookupItems"
        ]),
        hasTaxConfiguration() { return !_.isEmpty(_.get(this, "transferTaxConfiguration")); },
        transferTaxConfigurationID() { return _.parseNumber(_.get(this, "transferTaxConfiguration.transferTaxConfigurationID", 0), 0); },
    },

    watch: {
        validationErrors(newValue) {
            this.$emit("update:validationList", this.validationErrors);
        }
    },

    created() {
        this.initNonReactiveVariables();
        this.initGridConfig();
        this.initListeners();
    },

    beforeUnmount () {
        this.$events.off(this.addEventName, this.onAddItem);
    },

    methods: {
        elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
        getGrid() {
            return _.get(this.$refs, "dataGrid.gridInstance", {});
        },
        getNextFromAmount(){
            let maxAmount = _.maxBy(this.items, 'toAmount');
            return maxAmount ? maxAmount.toAmount + .01 : 0;
        },
        isToAmountLessThanFromAmount(item) {
            return _.gt(item.data.toAmount, item.data.fromAmount);
        },
        initNonReactiveVariables() {
            const self = this;
            self.addEventName = `add:transfer-tax-coverage-rounding`;
            self.itemKey = "transferTaxCoverageRoundingID";
            self.itemTypeName = "Coverage Rounding";
            self.itemTypeNamePlural = "Coverage Roundings";
            self.selectionActions = [{ name: "delete", text: `Delete`, eventName: "delete", requireSelection: true, allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` }];
            self.coverageRoundingMethods = CoverageRoundingMethod.lookupItems;
            self.roundingRules = RoundingRule.lookupItems;
        },
        initGridConfig(){
            const self = this;
            self.gridConfig = {
                paging: { enabled: false },
                remoteOperations: { sorting: false, paging: false },
                columns: [
                    {
                        dataField: self.itemKey,
                        visible: false,
                        allowEditing: false
                    },
                    {
                        dataField: "fromAmount",
                        caption: "From",
                        dataType: "number",
                        allowEditing: false,
                        cellTemplate: DxGridUtils.moneyCellTemplate,
                    },
                    {
                        dataField: "toAmount",
                        caption: "To",
                        dataType: "number",
                        format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                        cellTemplate: DxGridUtils.moneyCellTemplate,
                        validationRules: [
                            { type: "required" },
                            // {
                            //     type: "custom",
                            //     validationCallback: self.isToAmountLessThanFromAmount,
                            //     message: "To Amount must ge greater than From Amount"
                            // }
                        ]
                    },
                    {
                        dataField: "coverageRoundingRuleID",
                        caption: "Rounding",
                        lookup: {
                            dataSource: self.roundingRules,
                            displayExpr: "name",
                            valueExpr: "id"
                        }, validationRules: [{ type: "required" }]
                    },
                    {
                        dataField: "toNearest",
                        caption: "Nearest",
                        dataType: "number",
                        format: {
                                type: "currency",
                                precision: 2
                            },
                            editorOptions: {
                                format: "$ #,##0.##"
                            },
                        cellTemplate: DxGridUtils.moneyCellTemplate,
                    },


                ],
                onInitNewRow: function(e) {
                    e.data.fromAmount = self.getNextFromAmount();
                }
            };

            self.gridDataSource = {
                key: self.itemKey,
                loadMode: "raw",
                load () {
                    return Promise.resolve(self.items);
                },
                insert: self.onGridInsert,
                update: self.onGridUpdate
             };
        },
        initListeners(){
            this.$events.on(this.addEventName, this.onGridAdd);
        },
        onGridAdd() {
            let grid = this.getGrid();
            if(_.isEmpty(grid)) return;
            grid.addRow();
        },
        onGridDelete(e) {
            if(!e || !e.data) return;
            const self = this;
            let items = e.data;
            let ok = function (args) {
                let toBeDeletedKeys = _.map(items, self.itemKey);
                let apiPromise = self.$api.TransferTaxConfigurationApi.deleteCalculationRounding(toBeDeletedKeys);
                self.$rqBusy.wait(apiPromise)
                    .then(key => {
                        self.removeGridItems(items);
                        let message = key.length > 1 ? `${key.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                        self.$toast.success({ message: message });
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                    })
                    .finally(() => {
                        self.refreshGrid();
                    });
            }

                self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
        },
        onGridInsert(values) {
            const self = this;
            let newItem = new TransferTaxCoverageRoundingDto(values);
            newItem.transferTaxConfigurationID = self.transferTaxConfigurationID;
            let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
            return self.saveItem(newItem, changes)
                .then(result => {
                    self.items.push(new TransferTaxCoverageRoundingDto(_.clone(result)));
                }).catch(error => {
                    self.validationErrors.push(error.errorMessage);
                    self.$toast.error(`Error saving ${self.itemTypeName}.`);
                    console.error(error.errorMessage);
                }).finally(() => {
                    self.refreshGrid();
                });
        },
        onGridUpdate(key, values) {
            const self = this;
            let itemIndex = _.findIndex(self.items, item => _.parseNumber(_.get(item, self.itemKey), -1) === key);
            if(itemIndex < 0) return self.onGridInsert(values);

            let originalItem = _.cloneDeep(self.items[itemIndex]);
            let updatedItem = new TransferTaxCoverageRoundingDto(_.assign({}, self.items[itemIndex], values));
            let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

            return self.saveItem(updatedItem, changes)
                .then(result => {
                    self.items[itemIndex] = new TransferTaxCoverageRoundingDto(_.clone(result));
                }).catch(error => {
                    self.validationErrors.push(error.errorMessage);
                    self.$toast.error(`Error saving ${self.itemTypeName}.`);
                    console.error(error.errorMessage);
                }).finally(() => {
                    self.refreshGrid();
                });
        },
        refreshGrid() {
            let grid = this.getGrid();

            this.validationErrors = [];

            if (!_.isEmpty(grid)) {
                grid.clearSelection();
                grid.option("focusedRowIndex", -1);
                grid.refresh();
                grid.updateDimensions();
            }
        },
        removeGridItems(items) {
            _.pullAllBy(this.items, items, this.itemKey);
        },
        saveItem(item, changes) {
            const self = this;

            if(changes.length === 0) {
                self.$toast.error("No changes detected.");
                return Promise.resolve(true);
            }
            self.validationErrors = [];
            let apiPromise = self.$api.TransferTaxConfigurationApi.saveCoverageRounding(item.toDataObject(), changes);
            return self.$rqBusy.wait(apiPromise)
                .then(result => {
                    return Promise.resolve(result);
                }).catch(error => {
                    return Promise.reject(error);
                });
        },
    }
}
</script>
<style lang="scss" scoped>
</style>

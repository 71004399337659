<template>    
    <div class="content-wrapper">        
        <rqdx-action-data-grid 
            ref="dataGrid"
            :automation_id="elementName('tbl')"
            :actions="selectionActions" 
            :config="gridConfig"
            :data-source="gridDataSource"
            :export-file-name="elementName('', 'data')"
            v-model:validation-errors="validationErrors"
            target-inactive-column="inactive"
            :strikethrough-if-true="['inactive']"
            @activate="onActivateItem"
            show-include-inactive
            @delete="onDeleteItem"
            integrated-search
            hide-show-column-chooser
            :rq-editable="!readOnly"
             rq-filters
        />
    </div>
</template>

<script>
    import { PropertyTypeDto }  from "../models";
    import { mapState, mapGetters } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"PropertyTypeList",
        props: {
            selectedId: { type: Number, default: -1 },
        },
        data () {
            return {
                selectedItem: null,
                items: [],
                types: [],
                validationErrors: [],
                addEventName: ""
            };
        },

        created() {
            //this.initNonReactiveVariables();
            this.initTypes();
            this.initGridConfig();
            this.initListeners();
            this.fetchData();
        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        computed: {
            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
                readOnly: state => state.isPageReadOnly,
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            itemKey() { return _.get(this.$route.meta, "itemKey") || ""; },
            itemTypeNamePlural() { return _.get(this.$route.meta, "label") || ""; },
            itemTypeName() { return _.get(this.$route.meta, "itemTypeName") || ""; },
            itemTypeDescription() { return _.get(this.$route.meta, "itemTypeDescription") || ""; },
            selectionActions() {
                return [
                    //{ name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}`, disabled: function(e) {return _.some(e.data, ["isSystem", true]) ? "One or more items is a system record and cannot be deleted." : false; } },
                    { name: "activate", text: "Activate", eventName: "activate", requireSelection: true, tooltip: `Activate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['inactive', true]); } },
                    { name: "inactivate", text: "Inactivate", eventName: "activate", requireSelection: true, tooltip: `Inactivate ${this.itemTypeName}`, allowMultiSelection: true, disabled: function(e) { return !_.every(e.data, ['inactive', false]); } }                
                ];
            },
            errorMsg(){ return _.get(this, "validationErrors.length", 0) > 0 ?  "Please correct the highlighted errors on screen to continue." : _.get(this, "validationErrors")[0]; }  
        },
        watch: {
            validationErrors: function () {
                const self = this;  
                self.$events.emit("update-config-error", { message: "Please correct the highlighted errors on screen to continue.", hasError: self.validationErrors.length > 0 });                
            }
        },
        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },
            // initNonReactiveVariables() {
            //     const self = this;
            //     this.itemTypeName = _.get(this.$route.meta, "itemTypeName");
            //     this.itemTypeNamePlural = _.get(this.$route.meta, "label");
            //     this.itemKey = _.get(this.$route.meta, "itemKey");
            //     this.selectionActions = [
            //         { 
            //             name: "delete",
            //             text: "Delete",
            //             eventName: "delete",
            //             requireSelection: true,
            //             allowMultiSelection: true,
            //             tooltip: `Delete ${this.itemTypeName}`,
            //             disabled: function(e) {
            //                 return self.readOnly;
            //             }
            //         }
            //     ];
            // },
            initGridConfig(){
                const self = this;
                let validateExistingValue = (dataField, e) => {
                    let keyValue = _.getNumber(e, `data.${self.itemKey}`, 0);
                    return _.gt(keyValue, 0)
                        ? !self.itemExists(dataField, e.value, self.itemKey, keyValue)
                        : !self.itemExists(dataField, e.value);
                };
                self.gridConfig = {
                    columns: [
                        //{ dataField: self.itemKey, visible: false, allowEditing: false,showInColumnChooser: false, },
                        { dataField: "name", sortIndex: 0, sortOrder: "asc",
                            validationRules: [
                                { 
                                    type: "required"
                                },
                                {
                                    type: "custom",
                                    message: "An item with that name already exists.",
                                    validationCallback(e){ return validateExistingValue("name", e); }
                                } 
                            ],
                            editorOptions: {
                                maxLength: 50
                            },
                        },
                        { dataField: "description",
                            validationRules: [
                                { type: "required" },
                                // {
                                //     type: "custom",
                                //     validationCallback: self.isNotDuplicateDescription,
                                //     message: "Description already exists"
                                // } 
                            ],
                            editorOptions: {
                                maxLength: 150
                            },
                        },
                        {
                            dataField: "type",
                            lookup: {
                                dataSource: self.types,
                                displayExpr: "displayType",
                                valueExpr: "type",
                            },
                        },
                        // { type: "buttons", visible: false, showInColumnChooser: false }
                        { dataField: "isSystem", visible: false, dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate },
                        { dataField: "inactive", dataType: "boolean", cellTemplate: DxGridUtils.boolCellTemplate },
                    ],
                    onRowUpdating(e) {
                        e.cancel = new Promise((resolve, reject) => {
                            self.$dialog.confirm(
                                "Confirm Edit", 
                                `Updating ${self.itemTypeNamePlural} will change existing files. Do you want to continue?`, 
                                () => resolve(false), //continue with edit
                                () => resolve(true), //cancel edit
                                { cancelTitle: 'No', okTitle: 'Yes'});  
                        });
                    },
                    onInitNewRow: e => {
                        e.data.inactive = false;
                        e.data.isSystem = false;
                    },
                    onEditorPreparing: e => {
                        if (e.parentType !== "dataRow" || e.dataField !== "name") return;
                        if (e.dataField === "name") e.editorOptions.disabled = e.row.data.isSystem;
                    }
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },
            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },
            initTypes() {
                this.types = [
                    { type: 0, displayType: '' },
                    { type: 1, displayType: 'Residential' },
                    { type: 2, displayType: 'Commercial' }
                ]
            },
            fetchData () {
                const self = this;
                let apiPromise = self.$api.PropertyTypesApi.getPropertyTypes();
                self.$rqBusy.wait(apiPromise)
                    .then(result => { 
                        self.items = _.map(result, i => new PropertyTypeDto(i));
                        self.gridInstance.refresh();
                    }).catch(error => {
                        self.items = [];
                        self.$toast.error({ message: `Error Loading ${self.itemTypeNamePlural}.` });
                        return error;
                    });
            },
            itemExists(matchProp, matchValue, excludeProp=null, excludeValue=null) {
                const self = this;
                return _.some(self.items, item => {
                    if(_.isNil(excludeProp)) return item[matchProp] === matchValue;
                    return item[excludeProp] !== excludeValue && item[matchProp] === matchValue;
                });
            },
            isNotDuplicateCode(item) {
                const self = this;
                let dup = {};
                dup = _.find(self.items, (i) => { 
                    return _.toLower(_.trim(i.code)) === _.toLower(_.trim(item.data.code)) 
                            && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(item.data, self.itemKey, -1), -1); 
                });
                return dup ? false : true;
            },
            isNotDuplicateDescription(item) {
                const self = this;
                let dup = {};
                dup = _.find(self.items, (i) => { 
                    return _.toLower(_.trim(i.description)) === _.toLower(_.trim(item.data.description))
                            && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(item.data, self.itemKey, -1), -1); 
                });

                return dup ? false : true;
            },
            onAddItem() {
                if(!this.gridInstance) return;
                this.gridInstance.addRow();
            },
            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                    let apiPromises = [];
                    _.forEach(items, (item) => { 
                        apiPromises.push(self.$api.PropertyTypesApi.deletePropertyType(item.propertyTypeID));
                    });
                    return self.$rqBusy.wait(Promise.all(apiPromises))
                        .then(key => {
                            self.deleteItem([...key]);
                            let message = key.length > 1 ? `${key.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message }); 
                            return true;}
                        )
                        .catch(error => {                            
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {                            
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);                                    
                            } else {                            
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });                          
                            }
                            return error;       
                        });
                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});    
            },
            deleteItem(keys) {
                const self = this;
                _.each(keys, k => {
                    _.remove(self.items, (i) => {return _.parseNumber(_.get(i, self.itemKey, -1), -1) == k;});
                });                
                self.refresh();
            },
            onActivateItem(e){
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let itemLabel = items.length > 1
                    ? self.itemTypeNamePlural
                    : self.itemTypeName;
                let verb = _.every(items, ['inactive', true]) ? "Activate" : "Inactivate";

                let okHandler = function (args) {
                    let keys = _.map(items, self.itemKey);
                    self.toggleIsInactive(keys, verb);
                    return true;
                }

                self.$dialog.confirm(
                    `Confirm ${verb}`,
                    `Are you sure you want to ${verb} the selected ${itemLabel}?`,
                    okHandler,
                    null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            toggleIsInactive(keys, verb) {
                const self = this;
                let apiPromise = self.$api.PropertyTypesApi.toggleIsInactive(keys);
                return self.$rqBusy.wait(apiPromise)
                    .then(() => {
                        self.fetchData();
                        self.gridInstance.clearSelection();
                        let message = keys.length > 1
                            ? `${keys.length} ${self.itemTypeNamePlural} were ${verb}d.`
                            : `${self.itemTypeName} was ${verb}d.`
                        self.$toast.success(message);                       
                        return true;
                    })
                    .catch(error => {
                        self.$toast.error(`Error trying to ${verb} ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });
            },
            // refresh() {
            //     const self = this;
            //     self.gridInstance.clearSelection();
            //     self.gridInstance.refresh();
            // },
            save(item, changes){
                const self = this;
                
                if(changes.length === 0) {
                    return Promise.resolve(item);
                }
                
                let apiPromise = self.$api.PropertyTypesApi.savePropertyType(item.toDataObject(), changes);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        //self.refresh();
                        self.$toast.success({ message: `${self.itemTypeName} ${item.description} was saved.` });
                        return result;
                    }).catch(error => {
                        self.$toast.error(`Error saving ${self.itemTypeName}.`);
                        console.error(error);
                        return error;
                    });

            },
            onGridInsert(values) {
                const self = this;
                let newItem = new PropertyTypeDto(values);
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.save(newItem, changes)
                    .then(result => {
                        self.items.push(new PropertyTypeDto(result));
                    });
            },
            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, item => item.propertyTypeID === key);                
                if(itemIndex < 0) return self.onGridInsert(values);

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new PropertyTypeDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.save(updatedItem, changes)
                    .then(result => {
                        self.items[itemIndex] = updatedItem;
                    });
            }
        }
    }
</script>

<template>
    <rqdx-action-data-grid
        ref="categoryDataGrid"
        :automation_id="elementName('tbl')"
        :actions="selectionActions"
        :config="gridConfig"
        :data-source="gridDataSource"
        :export-file-name="elementName('', 'data')"
        @delete="onDeleteItem"
        @edit="onEditItem"
        hide-search
        rq-filters
    />
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { DocumentCategoryDto, DocumentTemplateDto }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import DocumentCategoryForm  from "./DocumentCategoryForm.vue";

    export default {
        name:"DocumentCategoryList",
        data () {
            return {
                itemKey: "documentCategoryID",
                itemTypeName: "Document Category",
                itemTypeNamePlural: "Document Categories",
                items: [],
                selectedItem: {},
                addEventName: ""
            };
        },

        computed: {
            ...mapState({
                user: state => state.authentication.session.user
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            gridInstance() { return _.get(this, "$refs.categoryDataGrid.gridInstance", null) || {}; },
            selectionActions() {
                return [{ name: "edit", text: "Edit", eventName: "edit", requireSelection: true, tooltip: "Edit Document Template" },
                        { name: "delete", text: "Delete", eventName: "delete", allowMultiSelection: true, tooltip: `Delete ${this.itemTypeName}` }];
            }
        },

        created(){
            const self = this;
            self.initGridConfig();
            self.initListeners();
            self.fetchData();
        },

        beforeUnmount () {
            this.$events.off(this.addEventName, this.onAddItem);
        },

        methods: {
            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            initListeners(){
                this.addEventName = `add:${this.elementName()}`;
                this.$events.on(this.addEventName, this.onAddItem);
            },

            initGridConfig(){
                const self = this;

                self.gridConfig = {
                    columns: [
                        {
                            dataField: "regionID",
                            caption: "Region",
                            calculateSortValue: DxGridUtils.regionDisplaySortValue,
                            lookup: {
                                dataSource: self.lookupHelpers.getRegions(),
                                displayExpr: "displayName",
                                valueExpr: "regionID"
                            }
                        },
                        { dataField: "description", caption: "Name" }
                    ]
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load: () => Promise.resolve(self.items),
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
            },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.DocumentCategoriesApi.getList();
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result, i => new DocumentCategoryDto(i));
                        self.refresh();
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error(`Error loading ${self.itemTypeNamePlural}.`);
                        return error;
                    });
            },

            onAddItem() {
                const self = this;
                self.loadItem();
            },

            onEditItem(e) {
                const self = this;
                self.loadItem(e.data);
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let items = e.data;
                let ok = function (args) {
                     let itemsToDelete = _.map(items, r => ({ ...r, isDeleted: true }));
                     let apiPromise = self.$api.DocumentCategoriesApi.save(itemsToDelete);
                     return self.$rqBusy.wait(apiPromise)
                        .then(results => {
                            self.items = _.map(results, i => new DocumentCategoryDto(i));
                            let message = items.length > 1 ? `${items.length} ${self.itemTypeNamePlural} were deleted.` : `${self.itemTypeName} was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                          if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.itemTypeNamePlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            }
                            return error;
                        })
                        .finally(() => {
                            self.refresh();
                        });

                }

                 self.$dialog.confirm(`Confirm Delete`, `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            refresh() {
                this.gridInstance.clearSelection();
                this.gridInstance.refresh();
            },

            updateDimensions() {
                this.gridInstance.updateDimensions();
                this.$nextTick(() => {
                    this.gridInstance.repaint();
                });
            },

            loadItem(itemData=null) {
                const self = this;

                let onOk = (e, addAnother=false) => e.component.save()
                    .then(result => {
                        if(result.noChange) return true;
                        if(!result.isValid) return false;

                        //all categories are returned when saving an individual category for some reason
                        self.items = _.map(result.data, o => new DocumentCategoryDto(o));
                        self.refresh();

                        if(!addAnother) return true;

                        e.component.reset();
                        return false;
                    });

                let isNew = _.isNil(itemData);
                let buttons = _.dialogButtons({ showAddAnother: isNew, onOk });
                self.$dialog.open({                    
                    title: `${isNew ? "Add": "Edit"} ${self.itemTypeName}${(isNew ? "" : `: ${itemData.description}`)}`,
                    height: "85%",
                    width: "1050",
                    resizable: false,
                    component: DocumentCategoryForm,
                    props: {
                        item: new DocumentCategoryDto(itemData),
                        uniqueValidator: (item,field) => !self.isDuplicate(item, field)
                    },
                    buttons,
                });
            },

            isDuplicate(item, fieldName){
                const self = this;
                let trimLower = val => _.toLower(_.trim(val));
                return _.some(self.items, (i) =>
                    trimLower(i[fieldName]) === trimLower(item[fieldName])
                    && _.parseNumber(item.regionID, -1) === _.parseNumber(i.regionID, -1)
                    && _.parseNumber(i[self.itemKey], -1) !== _.parseNumber(item[self.itemKey], -1)
                );
            }

        }
    }
</script>
